import { useCallback, useEffect, useState } from "react";
import HeaderDesktop from "../components/HeaderDesktop";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "./Categorie_Details.css"
import { useNavigate } from "react-router-dom";
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';
import { apiNodeService } from "../provider/apiNodeService";
import moment from 'moment';
import FooterDesktop from "../components/FooterDesktop";

const Categorie_Details = () => {

  const navigate = useNavigate();
  const [categori, setCategori] = useState('')
  const [projects, setProjects] = useState([])

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const categorie = searchParams.get('cat');
    setCategori(categorie)
    console.log(categorie)
    apiNodeService.getAllProjects().then(res => {
      if (res && res.status === 200) {
        console.log(res.data)
        let filteredProjects = res.data.filter(element => element.type_projet === categorie)
        setProjects(filteredProjects)
      }
    })

  }, []);

  const onChoixProjetTreeporteur = useCallback((project) => {
    navigate("/hotspot-wifi-solaire?project=" + project);
  }, [navigate]);

  return (
    <div style={{ width: "100%" }}>
      <HeaderDesktop />
      <div className="categorie-body p-4">
        <div className="d-flex justify-content-start align-items-center mb-4" onClick={(e) => { navigate("/web-invest") }} style={{ gap: 20, cursor: 'pointer', maxWidth: 300 }}>
          <ArrowBackIcon />
          <span className="span-back">Revenir à la liste des projets</span>
        </div>
        <div className="p-3">
          <span className="cat-title">Nos projets sur '{categori}'</span>
        </div>
        <div className="row p-2 px-3">
          <div className="col-md-6">
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-end align-items-start image-categorie-bg p-2">
                {categori === "Transport & Logistique" ? <img src="/transport_et_logistique.png" /> : categori === "Sylviculture" ? <img src="/sylviculture.png" /> : categori === "Tourisme" ? <img src="/tourisme.png" /> : categori === "Apiculture" ? <img src="/apiculture.png" /> : categori === "L’économie bleu" ? <img src="/economie_bleu.png" /> : categori === "Batîments écologiques" ? <img src="/batiments_ecologiques.png" /> : categori === "Énergies renouvelables" ? <img src="/energies_renouvelables.png" /> : <img src="/alimentation.png" />}

              </div>
              <span className="p-under-img">Investir présente un risque de perte en capital et de liquidité. N’investissez que l’argent dont vous n’avez pas besoin immédiatement et diversifiez votre épargne.</span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex flex-column">
              <span className="title-cat-1">Pilote 01 - Transformer les systèmes alimentaires</span>
              <div className="d-flex align-items-center mt-4" style={{ gap: 20 }}>
                <HomeRepairServiceOutlinedIcon style={{ color: "#1EB2A9" }} />
                <span>Secteurs</span>
                <div className="d-flex flex-column">
                  <span className="secteur-title">Agro-écologie </span>
                  <span className="secteur-title">Agriculture régénératrice</span>
                </div>

              </div>

              <div className="d-flex align-items-center mt-4" style={{ gap: 20 }}>
                <MapOutlinedIcon style={{ color: "#1EB2A9" }} />
                <span>Région locale</span>
                <div className="d-flex flex-column">
                  <span className="secteur-title">Flandre</span>
                </div>
              </div>

              <div className="d-flex align-items-center mt-4" style={{ gap: 20 }}>
                <MapOutlinedIcon style={{ color: "#1EB2A9" }} />
                <span>Pays</span>
                <div className="d-flex flex-column">
                  <span className="secteur-title">Belgique</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row p-3 mt-3 align-items-center">
          <div className="col-md-6">
            <div className="d-flex flex-column">
              <span className="cat-title">Portée du projet</span>
              <span>Locale : Flandre</span>
              <span>Nationale : Belgique</span>
              <span className="desc-cat">Extérieur : l’objectif est de se dépasser en contrecarrant les réseaux aux Pays-Bas et en Wallonie (Belgique), ainsi que dans les pays partenaires d’Asie de l’Est, d’Afrique et d’Amérique du Sud.</span>
            </div>
          </div>
          <div className="col-md-6">
            <img src="/cat-loc.png" />
          </div>

        </div>
        <div className="d-flex flex-column mt-5 p-3">
          <span className="cat-title">Le challenge</span>
          <span className="desc-cat">Le système alimentaire mondial est le principal moteur de la perte de biodiversité alimentée par le « paradigme d’une alimentation moins chère » qui a conduit à une intensification du système alimentaire mondial. Les systèmes agricoles actuels ont été identifiés comme une menace pour 86 % des espèces menacées d’extinction. L’UE a investi de manière significative dans l’agroécologie en tant qu’« approche holistique qui s’appuie sur et maximise l’utilisation de processus écologiques pour soutenir la production agricole », alignée à l’intersection des stratégies de la ferme à la table, de la PAC et du Green Deal pour assurer la transition vers une agriculture durable. secteur en Europe. Cependant, depuis plusieurs décennies, cette transition reste un sujet profondément controversé, où les agriculteurs craignent un impact négatif sur leurs revenus et leurs moyens de subsistance, et les environnementalistes craignent que la nature continue de décliner.</span>
        </div>
        <div className="d-flex flex-column mt-5 p-3">
          <span className="cat-title">Résultats attendus</span>
          <span className="desc-cat">Sensibilisation accrue à l’impact NP des entreprises agro-écologiques et intégration des partenaires (Asie du Sud-Est, Afrique et Amérique du Sud) dans les feuilles de route. Renforcement des capacités des PME agro-écologiques Contribution à la transformation des agro-industries conventionnelles flamandes vers l'agroécologie.</span>
        </div>
        <div className="mt-5 mb-3 p-3">
          <span className="all-projet-span">Tous nos projet en relation avec '{categori}'</span>
        </div>
        {projects.length > 0 ?
          <div className="projet-section p-3 d-flex flex-wrap justify-content-start py-2" style={{ gap: 20 }}>
            {projects.map((item, key) => (
              <div key={key} onClick={(e) => { onChoixProjetTreeporteur(item.id) }} className="card-projet-desktop mt-3" style={{ marginLeft: key > 0 ? 25 : 5 }}>
                <div className="projet-description-top1 d-flex align-items-start flex-column">

                </div>
                <div className="projet-description-bottom d-flex align-items-start flex-column py-2">
                  <div className="d-flex align-items-start flex-column" style={{ height: 155 }}>
                    <span className="span-card-project-d2 mt-1 px-3">{item.title ? item.title : "Hot spot mobile alimenté au solaire"}</span>
                    <span className="span-card-project-3 mt-2 px-3">par : <b>{item.porteur ? item.porteur : "Treeporteur"}</b> </span>
                  </div>
                  <div className="d-flex justify-content-between px-3" style={{ width: "100%" }}>
                    <div className="d-flex flex-column">
                      <span style={{ fontWeight: 600 }}>{parseInt(item.investors.length)}</span>
                      <span style={{ fontSize: 14 }}>investisseurs</span>
                    </div>
                    <div className="d-flex flex-column">
                      <span style={{ fontWeight: 600 }}>{item.date_fin ? moment(item.date_fin).diff(moment(), 'days') : 213} jours</span>
                      <span style={{ fontSize: 14 }}>restants</span>
                    </div>
                    <div className="d-flex flex-column">
                      <span style={{ fontWeight: 600 }}>{parseInt(item.nb_watt_used)} €</span>
                      <span style={{ fontSize: 14, fontWeight: 500 }}>sur {parseInt(item.nb_cellule_sol * 4)} €</span>
                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-between mt-2 px-3" style={{ width: "100%" }}>
                      <span style={{ color: "#188F87", fontSize: 20, fontWeight: 500 }}>{((parseInt(item.nb_watt_used) / parseInt(item.nb_cellule_sol * 4)) * 100).toFixed(2)} %</span>
                      {creativeProject && <span style={{ fontSize: 20, fontWeight: 500 }}>{parseInt(item.nb_cellule_sol * 4)} €</span>}
                    </div> */}
                  <div className="d-flex align-items-center mt-2 mb-2" style={{ width: "100%" }}>
                    <div className="progress-bar1 px-2" style={{ width: "82%" }}>
                      <div className="progress" style={{ border: "1px solid #254952", height: 7 }}>
                        <div className="progress-bar" role="progressbar" style={{ width: `${(parseInt(item.nb_watt_used) / parseInt(item.nb_cellule_sol * 4)) * 100}%`, backgroundColor: "#1EB2A9" }} aria-value={(parseInt(item.nb_watt_used) / parseInt(item.nb_cellule_sol * 4)) * 100} aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <span style={{ color: "#616161", fontSize: 14, fontWeight: 600 }}>{((parseInt(item.nb_watt_used) / parseInt(item.nb_cellule_sol * 4)) * 100).toFixed(2)} %</span>
                  </div>
                </div>
                {/* <div className="d-flex justify-content-center mb-2">
                    <button onClick={(e) => { onChoixProjetTreeporteur(item.id) }} className="btn-choix-projet-mobile">
                      Voir le projet
                    </button>
                  </div> */}
              </div>
            ))
            }
          </div>
          :
          <span>Aucun projet</span>
        }
      </div>
      <FooterDesktop />
    </div>
  )
}
export default Categorie_Details;