import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import './Setting.css'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import FooterMobile from "../components/FooterMobile";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';
import HeartBrokenOutlinedIcon from '@mui/icons-material/HeartBrokenOutlined';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import AlarmOutlinedIcon from '@mui/icons-material/AlarmOutlined';

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#5C2DA8',
                borderRadius: 16
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 16,
        height: 16,
        borderRadius: 16,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 30 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));


const SettingDetails = () => {

    const navigate = useNavigate();
    const [sectionDisplayed, setSectionDisplayed] = React.useState("")

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const section = searchParams.get('section');
        setSectionDisplayed(section)
        console.log(section)
    }, []);


    return (
        <>
            <div className="setting-setails-page">
                <header className="headerheader-mobile">
                    <div style={{ width: "100%" }} className="d-flex align-items-center justify-content-between">
                        <KeyboardBackspaceIcon style={{ cursor: 'pointer' }} fontSize="large" onClick={(e) => { navigate(-1) }} />
                        <span className="span-list-projet-mobile" style={{ fontSize: 20, fontWeight: 500 }}>{sectionDisplayed}</span>
                        <span></span>
                    </div>
                </header>

                {sectionDisplayed === "Aide" &&
                    <div className="section-aide px-3 py-5">
                        <div className='d-flex flex-column px-2 py-3 mt-2 first-setting'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='title-setting d-flex align-items-center' style={{ gap: 10 }}>
                                    <QuestionMarkIcon fontSize='large' style={{ color: "#477520" }} />
                                    <span>F.A.Q</span>
                                </div>
                                <ArrowForwardIosIcon fontSize='large' style={{ color: "#477520" }} />
                            </div>
                            <hr />
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='title-setting d-flex align-items-center' style={{ gap: 10 }}>
                                    <img width={35} src="/chatbot.svg" />
                                    <span>Watti bot</span>
                                </div>
                                <ArrowForwardIosIcon fontSize='large' style={{ color: "#477520" }} />
                            </div>
                            <hr />
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='title-setting d-flex align-items-center' style={{ gap: 10 }}>
                                    <PersonOutlineIcon fontSize='large' style={{ color: "#477520" }} />
                                    <span>Contacter un agent de E+C-</span>
                                </div>
                                <ArrowForwardIosIcon fontSize='large' style={{ color: "#477520" }} />
                            </div>
                        </div>
                    </div>
                }

                {sectionDisplayed === "Compte" &&
                    <div className="section-aide px-3 py-5">
                        <div className='d-flex flex-column px-2 py-3 mt-2 first-setting'>

                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='title-setting d-flex align-items-center' style={{ gap: 10 }}>
                                    <PersonOutlineIcon fontSize='large' style={{ color: "#477520" }} />
                                    <span>Informations personnelles</span>
                                </div>
                                <ArrowForwardIosIcon fontSize='large' style={{ color: "#477520" }} />
                            </div>
                            <hr />
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='title-setting d-flex align-items-center' style={{ gap: 10 }}>
                                    <ReceiptOutlinedIcon fontSize='large' style={{ color: "#477520" }} />
                                    <span>Documents et factures</span>
                                </div>
                                <ArrowForwardIosIcon fontSize='large' style={{ color: "#477520" }} />
                            </div>
                            <hr />
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='title-setting d-flex align-items-center' style={{ gap: 10 }}>
                                    <LockOutlinedIcon fontSize='large' style={{ color: "#477520" }} />
                                    <span>Politique de confidentialité</span>
                                </div>
                                <ArrowForwardIosIcon fontSize='large' style={{ color: "#477520" }} />
                            </div>
                            <hr />
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='title-setting d-flex align-items-center' style={{ gap: 10 }}>
                                    <PlagiarismOutlinedIcon fontSize='large' style={{ color: "#477520" }} />
                                    <span>Conditions générales</span>
                                </div>
                                <ArrowForwardIosIcon fontSize='large' style={{ color: "#477520" }} />
                            </div>
                        </div>
                        <div className='final-section-setting  mt-5'>
                            <div className='d-flex deconet-setting p-3 align-items-center' style={{ gap: 10 }}>
                                <HeartBrokenOutlinedIcon fontSize='large' style={{ color: "#8F1818" }} />
                                <span>Fermer mon compte</span>
                            </div>
                        </div>
                    </div>
                }

                {sectionDisplayed === "Sécurité et confidentialité" &&
                    <div className="section-aide px-3 py-5">
                        <div className='d-flex flex-column px-2 py-3 mt-2 first-setting'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='title-setting d-flex align-items-center' style={{ gap: 10 }}>
                                    <LockOutlinedIcon fontSize='large' style={{ color: "#477520" }} />
                                    <span>Modifier le code pin</span>
                                </div>
                                <ArrowForwardIosIcon fontSize='large' style={{ color: "#477520" }} />
                            </div>
                            <hr />
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='title-setting d-flex align-items-center' style={{ gap: 10 }}>
                                    <VisibilityOutlinedIcon fontSize='large' style={{ color: "#477520" }} />
                                    <span>Masquer les soldes</span>
                                </div>
                                <div className='d-flex mt-1 flex-column align-items-center'>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <AntSwitch size='large' defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                    </Stack>
                                    <span className="mt-1" style={{ fontSize: 14 }}>Visibles</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {sectionDisplayed === "Communication" &&
                    <div className="section-aide px-3 py-5">
                        <div className='d-flex flex-column px-2 py-3 mt-2 first-setting'>

                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='title-setting d-flex align-items-center' style={{ gap: 10 }}>
                                    <NotificationsNoneOutlinedIcon fontSize='large' style={{ color: "#477520" }} />
                                    <span>Notifications push</span>
                                </div>
                                <div className='d-flex mt-1 flex-column align-items-center'>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <AntSwitch size='large' defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                    </Stack>
                                    <span className="mt-1" style={{ fontSize: 13 }}>Activés</span>
                                </div>
                            </div>
                            <hr />
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='title-setting d-flex align-items-center' style={{ gap: 10 }}>
                                    <EmailOutlinedIcon fontSize='large' style={{ color: "#477520" }} />
                                    <span>E-mails personnalisés</span>
                                </div>
                                <div className='d-flex mt-1 flex-column align-items-center'>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <AntSwitch size='large' defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                    </Stack>
                                    <span className="mt-1" style={{ fontSize: 13 }}>Activés</span>
                                </div>
                            </div>
                            <hr />
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='title-setting d-flex align-items-center' style={{ gap: 10 }}>
                                    <AlarmOutlinedIcon fontSize='large' style={{ color: "#477520" }} />
                                    <span>Alertes relatives aux projets</span>
                                </div>
                                <div className='d-flex mt-1 flex-column align-items-center'>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <AntSwitch size='large' defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                    </Stack>
                                    <span className="mt-1" style={{ fontSize: 13 }}>Activés</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <FooterMobile />
            </div>
        </>
    )

}
export default SettingDetails;