import React, { useEffect } from "react";
import FooterMobile from "../components/FooterMobile";
import "./Login.css"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { apiNodeService } from "../provider/apiNodeService";
import MuiBackdrop from "../components/Backdrop/MuiBackdrop";
import { useNavigate, useParams } from "react-router-dom";
import Divider from '@mui/material/Divider';
import { useToast } from "@chakra-ui/react";

const Update_Password = () => {

    const toast = useToast();
    const navigate = useNavigate();

    const [password,setPassword]= React.useState('')
    const [passwordVerif, setPasswordVerif] = React.useState('')
    const { table, token } = useParams();

    const [loading, setLoading] = React.useState(false)

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const page = searchParams.get('page');
        console.log(page)
    }, []);

    const updatePassword = async () => {
        setLoading(true)
            if (password !== passwordVerif) {
                console.log(password)
                console.log(passwordVerif)
          toast({
            title: 'Erreur !',
            description: "Veuillez vérifier les mots de passe",
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          setLoading(false)
        } else {
          let data = {resetToken:token,table:table,password:password}
          console.log(data)
          apiNodeService.updatePasswordGreen(data).then(res=>{
            console.log(res)
            if (res && res.status === 200) {
              navigate('/signin')
              setLoading(false)
            } else if(res && res.status === 402){
              toast({
                title: 'Erreur !',
                description: "Token expiré, Veuillez réessayer ultérieurement! ",
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
              setLoading(false)
            } else {
              toast({
                title: 'Erreur !',
                description: "Veuillez réessayer ultérieurement! ",
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
              setLoading(false)
            }
          })
        
    }
      }

    return (
        <>
            <div className="login-page">
                <MuiBackdrop
                    open={loading}
                />
                <header className="headerheader-mobile">
                    <div style={{ width: "100%",gap:20 }} className="d-flex align-items-center">
                        <KeyboardBackspaceIcon style={{ cursor: 'pointer' }} fontSize="large" onClick={(e) => { navigate(-1) }} />
                        <span className="span-list-projet-mobile">Modifier mot de passe</span>
                        <span></span>
                    </div>
                </header>
                <div className="p-3 mt-5">
                    <div className="card-etape d-flex flex-column align-self-center p-3 mt-5">
                    <span>Mot de passe*</span>
                        <div className="form-group mt-1">
                            <input onChange={(e) => { setPassword(e.target.value) }} type="password" className="form-control is-valid"  aria-describedby="emailHelp" />
                        </div>
                        <span>Confirmez mot de passe*</span>
                        <div className="form-group mt-1">
                            <input onChange={(e) => { setPasswordVerif(e.target.value) }} type="password" className="form-control is-valid" aria-describedby="emailHelp" />
                        </div>
                       
                        <div className="d-flex flex-column mt-4">
                            
                            <button onClick={updatePassword} className="login-button mb-3">
                                Valider
                            </button>
                            
                        </div>
                        
                    </div>
                </div>
                <FooterMobile />
            </div>
        </>
    )
}
export default Update_Password;