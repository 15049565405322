import "./MinicentraleAvecSupportAlu.css";
import { useState, useCallback } from "react";
import DrawerMenuDrawerItem from "../components/DrawerMenuDrawerItem";
import PortalPopup from "../components/PortalPopup";

const MinicentraleAvecSupportAlu = () => {

  const [isDrawerMenuDrawerItemPopupOpen, setDrawerMenuDrawerItemPopupOpen] =
    useState(false);

  const openDrawerMenuDrawerItemPopup = useCallback(() => {
    setDrawerMenuDrawerItemPopupOpen(true);
  }, []);

  const closeDrawerMenuDrawerItemPopup = useCallback(() => {
    setDrawerMenuDrawerItemPopupOpen(false);
  }, []);

  return (
    <div className="minicentrale-avec-support-alu">
      <header className="headerheader2">
          <div className="logo3">
            <b className="creative-minds3">E+C-</b>
          </div>
          <div className="menuitems3">
            <div className="links2">
              <div className="summer-school-20232">PILOTE summer 2023</div>
              <div className="links-child3" />
              <div className="summer-school-20232">
                Investir à partir de 1 €
              </div>
              <div className="links-child3" />
              <div className="summer-school-20232">Nous rejoindre</div>
              <div className="links-child3" />
              <div className="summer-school-20232">Se connecter</div>
            </div>
            <div
              className="hamburgeritem2"
              onClick={openDrawerMenuDrawerItemPopup}
            >
              <div className="iconcontainer2">
                <img className="icon2" alt="" src="/icon16.svg" />
              </div>
            </div>
          </div>
        </header>
      <div className="divinvestment-item1 px-2">
        <b className="h1title2">
          Mini-centrale 4,8 KWc avec support aluminium reconverti
        </b>
        <div className="divcompartive-tab2">
          <div className="divinvestment-render1">
            <div className="divtags1">
              <img
                className="imgpv-on-roof-icon1"
                alt=""
                src="/imgpv-on-roof1@2x.png"
              />
              <img
                className="imgordinary-actions-icon1"
                alt=""
                src="/imgordinary-actions1@2x.png"
              />
              <img
                className="imgbuilding-in-progress-icon1"
                alt=""
                src="/imgbuilding-in-progress1@2x.png"
              />
            </div>
            <div className="h1title2">
              <p className="investissez-votre-pargne1">{`Investissez votre épargne dans une solution de création `}</p>
              <p className="investissez-votre-pargne1">{`d’énergie dont la partie support est fabriqué à base `}</p>
              <p className="investissez-votre-pargne1">
                de canettes de boissons.
              </p>
            </div>
            <img
              className="imgsolar-panel-support-system-icon1"
              alt=""
              src="/imgsolar-panel-support-system1@2x.png"
            />
          </div>
          <div className="divinvestment-details1">
            <div className="divinvestment-state1">
              <div className="divtitle7">
                <img
                  className="imgdouble-right-arrow-icon2"
                  alt=""
                  src="/imgdouble-right-arrow1@2x.png"
                />
                <b className="h3open-investment2">Investissement ouvert</b>
              </div>
              <div className="divcollect-info1">
                <div className="divcurrent-money-collected1">
                  <div className="h3open-investment2">400 € collecté</div>
                  <div className="pmoney-wanted1">
                    4800 € seuil de résussite
                  </div>
                </div>
                <b className="ppercentage1">8,3%</b>
                <div className="divdisplay-bar1">
                  <div className="imgrectangle-background1" />
                  <div className="imgvalue1" />
                </div>
                <div className="h1title2">4800 € Objectif</div>
                <div className="ppercentage1">{`6000  € Plafond `}</div>
              </div>
              <div className="divinvestor-on-it1">
                <img
                  className="imginvestor-avatar-icon1"
                  alt=""
                  src="/imginvestor-avatar1@2x.png"
                />
                <b className="h3open-investment2">{`Investisseurs `}</b>
              </div>
            </div>
            <div className="divproject-hoster2">
              <img
                className="imgproject-hoster-icon1"
                alt=""
                src="/imgproject-hoster1@2x.png"
              />
              <div className="divproject-hoster-info1">
                <div className="pproject-hosted-default1">Projet porté par</div>
                <b className="pproject-hosted-default1">Treeporteur</b>
              </div>
            </div>
            <div className="divinterest-rate-and-time6">
              <div className="divtitle7">
                <img
                  className="imgdouble-right-arrow-icon2"
                  alt=""
                  src="/imgdouble-right-arrow1@2x.png"
                />
                <b className="h3open-investment2">Taux d’intérêt et durée *</b>
              </div>
              <div className="divinterest-rate-and-time7">
                <img className="imglock-icon1" alt="" src="/imglock1@2x.png" />
                <b className="h3open-investment2">
                  Consulter le taux et la durée
                </b>
              </div>
            </div>
            <div className="divinvest-controls1">
              <button className="buttonshow-risk1" id="btn_voir_les_risques">
                <b className="prisk-button-text1">{`Voir les risques `}</b>
              </button>
              <button className="buttoninvest1" id="btn_investir">
                <b className="pinvest-button-text1">{`Investir `}</b>
              </button>
            </div>
            <a className="tri-taux-container1">
              <ul className="tri-taux-de-rentabilit-inte1">
                <span>{`TRI ( Taux de rentabilité interne ) contractuel ( `}</span>
                <span className="voir-fiscalit1">voir fiscalité</span>
                <span className="span2">{` ) `}</span>
              </ul>
            </a>
          </div>
        </div>
        <div className="contentshare1">
          <div className="pproject-hosted-default1">Partager</div>
          <div className="groupshare-icons1">
            <img
              className="capture-decran-2023-07-27-a3"
              alt=""
              src="/capture-decran-20230727-a-19343@2x.png"
            />
            <img
              className="capture-decran-2023-07-27-a4"
              alt=""
              src="/capture-decran-20230727-a-19344@2x.png"
            />
            <img
              className="capture-decran-2023-07-27-a4"
              alt=""
              src="/capture-decran-20230727-a-19345@2x.png"
            />
          </div>
        </div>
      </div>
      <div className="investment-details1">
        <div className="pinvestment-warning1">
          Investir présente un risque de perte en capital et de liquidité.
          N’investissez que l’argent dont vous n’avez pas besoin immédiatement
          et diversifiez votre épargne.
        </div>
        <b className="h1title3">
          <p className="investissez-votre-pargne1">
            Détails de l'investissement
          </p>
        </b>
        <div className="divcompartive-tab3">
          <div className="column1caracteristics1">
            <div className="divtitle9">
              <b className="pcolumn-title5">Caractéristiques</b>
            </div>
            <div className="divaccess-field3">
              <div className="paccessible4">Accessible</div>
            </div>
            <div className="divaccess-field3">
              <div className="paccessible4">Taux d’intérêt et durée *</div>
            </div>
            <div className="divaccess-field3">
              <div className="paccessible4">Remaining</div>
            </div>
            <div className="divaccess-field3">
              <div className="paccessible4">Investissement min</div>
            </div>
            <div className="divaccess-field3">
              <div className="paccessible4">Investissement max</div>
            </div>
            <div className="divaccess-field3">
              <div className="paccessible4">Valeur unitaire</div>
            </div>
            <div className="divaccess-field3">
              <div className="paccessible4">Participant</div>
            </div>
          </div>
          <div className="column1caracteristics1">
            <div className="divtitle9">
              <b className="pcolumn-title5">Simulateur</b>
            </div>
            <div className="divaccess-field4">
              <div className="paccessible4">Accessible</div>
            </div>
            <div className="divaccess-field4">
              <div className="paccessible4">Taux d’intérêt et durée *</div>
            </div>
            <div className="divaccess-field4">
              <div className="paccessible4">Remaining</div>
            </div>
            <div className="divaccess-field4">
              <div className="paccessible4">Investissement min</div>
            </div>
            <div className="divaccess-field4">
              <div className="paccessible4">Investissement max</div>
            </div>
            <div className="divaccess-field4">
              <div className="paccessible4">Valeur unitaire</div>
            </div>
            <div className="divaccess-field4">
              <div className="paccessible4">Participant</div>
            </div>
          </div>
          <div className="column3investment-details1">
            <div className="row0who-can-invest1">
              <div className="divtitle9">
                <b className="pcolumn-title5">Qui peut investir</b>
              </div>
              <div className="divdate-and-place-info1">
                <div className="pdate-and-place-info2">
                  <p className="investissez-votre-pargne1">
                    À partir du jeudi 16 février 2023 à 12h30
                  </p>
                  <ul className="tri-taux-de-rentabilit-inte1">
                    <li>
                      Investissement ouvert aux habitant·es et collectivités des
                      départements 16 - 17 - 24 - 33 - 79 - 85
                    </li>
                  </ul>
                </div>
              </div>
              <div className="divaccess-field4">
                <div className="paccessible4">Taux d’intérêt et durée *</div>
              </div>
              <div className="divaccess-field4">
                <div className="paccessible4">Remaining</div>
              </div>
              <div className="divaccess-field4">
                <div className="paccessible4">Investissement min</div>
              </div>
              <div className="divaccess-field4">
                <div className="paccessible4">Investissement max</div>
              </div>
              <div className="divaccess-field4">
                <div className="paccessible4">Valeur unitaire</div>
              </div>
              <div className="divaccess-field4">
                <div className="paccessible4">Participant</div>
              </div>
            </div>
            <div className="row1end-of-investment1">
              <div className="divtitle9">
                <b className="pcolumn-title5">Date de fin</b>
              </div>
              <div className="divaccess-field3">
                <b className="pdate-and-place-info2">31 juillet 2023 à 23h59</b>
              </div>
              <div className="divend-date-info1">
                <div className="pend-date-info1">
                  La date de fin de collecte pourra être prolongée à la demande
                  du porteur de projet.Le rendement de votre investissement sera
                  calculé à partir de la date de génération des certificats de
                  souscription, c'est-à-dire une fois l'intégralité des fonds
                  reçus et la documentation juridique signée par le porteur de
                  projet.
                </div>
              </div>
              <div className="divsecondary-house-info1">
                <div className="pend-date-info1">
                  Si vous avez une résidence secondaire ou principale dans l'une
                  de ces zones, vous pouvez investir en renseignant un
                  justificatif de domicile.
                </div>
              </div>
              <div className="divaccess-field4">
                <div className="paccessible4">Investissement min</div>
              </div>
              <div className="divaccess-field4">
                <div className="paccessible4">Investissement max</div>
              </div>
              <div className="divaccess-field4">
                <div className="paccessible4">Valeur unitaire</div>
              </div>
              <div className="divaccess-field4">
                <div className="paccessible4">Participant</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="divproject-hoster-details1">
        <div className="navbarproject-nav-bar1">
          <div className="buttonbutton-nav-bar-the-proj1">
            <b className="pproject-hosted-default1">{`Le projet `}</b>
            <div className="divider1" />
          </div>
          <div className="buttonbutton-nav-bar-project-1">
            <b className="pproject-hosted-default1">Le porteur de projet</b>
          </div>
          <div className="buttonbutton-nav-bar-project-1">
            <b className="pproject-hosted-default1">Actualités</b>
          </div>
          <div className="buttonbutton-nav-bar-project-1">
            <b className="pproject-hosted-default1">Commentaires</b>
          </div>
          <button className="buttonbutton-be-call-back1" id="btn_etre_rappele">
            <b className="pbe-call-back1">{`Etre rappelé `}</b>
          </button>
          <button className="buttonbutton-doc1" id="btn_documentation">
            <img
              className="imgicon-download1"
              alt=""
              src="/imgicon-download1@2x.png"
            />
            <b className="pbe-call-back1">Documentation</b>
          </button>
          <button className="buttonbutton-invest1" id="btn_investir">
            <b className="p-invest1">Investir</b>
          </button>
        </div>
        <div className="divessential-info1">
          <div className="divessential-description1">
            <div className="l-essentiel1">L ‘ESSENTIEL</div>
            <div className="un-centre-de1">{`Un centre de formation , basé sur toulon, sur les nouvelles technologie pour les jeunes de 10-14 ans ( Collège et 15-18 ans  (lycée ) souhaite produire l’énergie électrique qu’elle consomme. Pour ce faire, une mini-centrale de production via des panneaux solaires serait mis en place grâce à un financement participatif. Au lieu, d’un classique emprunt, l’idée est de proposer à chacun des apprenants de devenir actionnaire de cette mini-centrale électrique. Chaque action, d’un euro, correspondra à un actif d’une Unité de compte solaire (UCS ).  `}</div>
            <div className="un-centre-de1">La mini</div>
          </div>
          <div className="divproject-hoster3">
            <div className="divfield-simulateur1">
              <div className="psimulator1">Simulateur</div>
            </div>
            <div className="divfield-simulateur1">
              <div className="psimulator1">{`Consulter le taux et la durée `}</div>
            </div>
            <div className="div-project-hoster-details1">
              <div className="pproject-hoster-name3">
                <p className="le-porteur1">{`LE PORTEUR `}</p>
                <p className="investissez-votre-pargne1">DE PROJET</p>
              </div>
              <img
                className="imgproject-hoster-img-icon1"
                alt=""
                src="/imgproject-hoster-img1@2x.png"
              />
            </div>
          </div>
        </div>
      </div>
      <footer className="footerfooter1">
        <div className="container1">
          <div className="col11">
            <b className="summer-school-20231">
              <p className="investissez-votre-pargne1">E+C- :</p>
              <p className="investissez-votre-pargne1">Investisser</p>
              <p className="investissez-votre-pargne1">Autrement</p>
            </b>
          </div>
          <div className="col21">
            <b className="investissement-solaire1">Investissement solaire</b>
            <div className="microproduction-pour-un-container1">
              <p className="investissez-votre-pargne1">
                Microproduction pour un centre de formation : Cible, 590 € ,
                réalisé à 83%
              </p>
              <p className="investissez-votre-pargne1">&nbsp;</p>
              <p className="investissez-votre-pargne1">
                Créer un hot spot wifi partout alimenté via le solaire: cible
                4900 € réalisé à 58%
              </p>
              <p className="investissez-votre-pargne1">&nbsp;</p>
              <p className="investissez-votre-pargne1">
                MInicentrale 4,8 KWc avec support aluminium reconverti: cible
                4800 €
              </p>
              <p className="investissez-votre-pargne1">
                <b>&nbsp;</b>
              </p>
              <p className="investissez-votre-pargne1">&nbsp;</p>
              <p className="investissez-votre-pargne1">&nbsp;</p>
              <p className="investissez-votre-pargne1">&nbsp;</p>
            </div>
          </div>
          <div className="col31">
            <b className="investissement-solaire1">{`Soutiens & Partenaires`}</b>
            <b className="investissement-solaire1">Rentrée 2023</b>
            <b className="investissement-solaire1">Nous rejoindre</b>
            <b className="investissement-solaire1">Notre Approche</b>
            <b className="investissement-solaire1">Contact</b>
            <b className="investissement-solaire1">CGV</b>
          </div>
        </div>
        <div className="textdroit1">©2023 E+C- | Tout droits reservés</div>
      </footer>
      {isDrawerMenuDrawerItemPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top center"
          onOutsideClick={closeDrawerMenuDrawerItemPopup}
        >
          <DrawerMenuDrawerItem onClose={closeDrawerMenuDrawerItemPopup} />
        </PortalPopup>
      )}
    </div>
  );
};

export default MinicentraleAvecSupportAlu;
