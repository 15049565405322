import React, { useState, useCallback, useEffect } from "react";
import DrawerMenuDrawerItem from "../components/DrawerMenuDrawerItem";
import PortalPopup from "../components/PortalPopup";
import "./FrameComponent1.css";
import MuiBackdrop from "../components/Backdrop/MuiBackdrop";
import MenuIcon from '@mui/icons-material/Menu';
import FooterDesktop from "../components/FooterDesktop";
import HeaderDesktop from "../components/HeaderDesktop";

const FrameComponent1 = () => {
  const [isDrawerMenuDrawerItemPopupOpen, setDrawerMenuDrawerItemPopupOpen] =
    useState(false);

    const [loading, setLoading] = React.useState(false);
    const [projetId, setProjetId] = React.useState('');

    useEffect(() => {
      setLoading(true)
      const searchParams = new URLSearchParams(window.location.search);
      setProjetId(searchParams.get('project'))
      setTimeout(() => {
        setLoading(false)
    },2000)
  }, []);

  const openDrawerMenuDrawerItemPopup = useCallback(() => {
    setDrawerMenuDrawerItemPopupOpen(true);
  }, []);

  const closeDrawerMenuDrawerItemPopup = useCallback(() => {
    setDrawerMenuDrawerItemPopupOpen(false);
  }, []);

  return (
    <>
      <div className="headerheader-parent">
      <MuiBackdrop
                open={loading}
            />
        <HeaderDesktop />
        <div className="frame-child"> 
          <iframe className="resize-iframe" width={"100%"} src={"https://bank-app-lemon.vercel.app/bank/login?project="+projetId}></iframe>
        </div>
        <FooterDesktop />
      </div>
      {isDrawerMenuDrawerItemPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top center"
          onOutsideClick={closeDrawerMenuDrawerItemPopup}
        >
          <DrawerMenuDrawerItem onClose={closeDrawerMenuDrawerItemPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default FrameComponent1;
