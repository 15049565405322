import { useCallback, useState } from "react";
import DrawerMenuMobile from "./DrawerMenuMobile";
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from "react-router-dom";
import PortalPopup from "./PortalPopup";

const HeaderMobile = (props) => {

    const navigate = useNavigate();

    const [isDrawerMenuMobileOpen, setDrawerMenuMobileOpen] = useState(false);

    const openDrawerMenuMobile = useCallback(() => {
        setDrawerMenuMobileOpen(true);
    }, []);

    const closeDrawerMenuMobile = useCallback(() => {
        setDrawerMenuMobileOpen(false);
    }, []);

    return (
        <>
            <header className="headerheader-mobile">

                <div style={{ width: "100%" }} className="d-flex  justify-content-between align-items-center">
                    { props.isHome === false ?
                        <KeyboardBackspaceIcon style={{ cursor: 'pointer' }} fontSize="large" onClick={(e) => { navigate(-1) }} />
                    :
                    <img src="/greenlogo.png"/>
                    }
                    <span className="span-list-projet-mobile">{props.title}</span>
                    <MenuIcon style={{color:"#477520"}} className="" fontSize="large" onClick={openDrawerMenuMobile} />

                </div>
            </header>
            {isDrawerMenuMobileOpen && (
                <PortalPopup
                    overlayColor="rgba(113, 113, 113, 0.3)"
                    placement="Top center"
                    onOutsideClick={closeDrawerMenuMobile}
                >
                    <DrawerMenuMobile onClose={closeDrawerMenuMobile} />
                </PortalPopup>
            )}
        </>
    )
}
export default HeaderMobile;