import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function MuiBackdrop(props) {

    return (
      
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={props?.open ?? false}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

    );
}