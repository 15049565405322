import MenuIcon from '@mui/icons-material/Menu';
import { useCallback, useState } from 'react';
import DrawerMenuDrawerItem from "../components/DrawerMenuDrawerItem";
import PortalPopup from './PortalPopup';

const HeaderDesktop = () => {

    const [isDrawerMenuDrawerItemPopupOpen, setDrawerMenuDrawerItemPopupOpen] = useState(false);

    const openDrawerMenuDrawerItemPopup = useCallback(() => {
        setDrawerMenuDrawerItemPopupOpen(true);
      }, []);
    
      const closeDrawerMenuDrawerItemPopup = useCallback(() => {
        setDrawerMenuDrawerItemPopupOpen(false);
      }, []);

    return (
        <>
        <header className="headerheader d-flex justify-content-between">
          <div className="logo">
            {/* <b className="creative-minds">E+C-</b> */}
            <img width={50} height={32} src="/greenlogo.svg" />
          </div>
          <div className="menuitems">
            <div className="links px-4">
              <div className="nous-rejoindre">Répertoire de projet</div>
              <div className="links-child" />
              <div className="nous-rejoindre">Liste des projets</div>
              <div className="links-child" />
              <div className="nous-rejoindre">Nous rejoindre</div>
              <div className="links-child" />
              <div className="nous-rejoindre">Nous contacter</div>
            </div>

          </div>
          <div className="d-flex" style={{ gap: 10 }}>
            <div
              className="hamburgeritem"
              onClick={openDrawerMenuDrawerItemPopup}
            >
              <div className="iconcontainer">
                {/* <img className="icon" alt="" src="/icon15.svg" /> */}
                <MenuIcon fontSize="large" />
              </div>
            </div>
            <div className="p-2 px-5 " style={{ fontSize: 16, minWidth: 180, backgroundColor: "#477520", textAlign: 'center', color: "#fff", borderRadius: 8, fontWeight: 600 }}>Se connecter</div>
          </div>
        </header>
        {isDrawerMenuDrawerItemPopupOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Top center"
            onOutsideClick={closeDrawerMenuDrawerItemPopup}
          >
            <DrawerMenuDrawerItem onClose={closeDrawerMenuDrawerItemPopup} />
          </PortalPopup>
        )}
        </>
    )
}
export default HeaderDesktop;