import { useState, useCallback, useEffect } from "react";
import PopupinfoNetworkType from "../components/PopupinfoNetworkType";
import PortalPopup from "../components/PortalPopup";
import PopupinfoStorageType from "../components/PopupinfoStorageType";
import "./WebCofinancementKitCoolMy.css";
import products from "../data/product"
import DrawerMenuDrawerItem from "../components/DrawerMenuDrawerItem";
import { useNavigate } from "react-router-dom";

const productsClasses = [
  {
    type: "climatisations",
    marque: "AGN - ECOFLOW - AVALLA - SHINCO - DAIKIN - CLIVET - BOSCH"
  },
  {
    type: "panneaux solaires",
    marque: "LONGI - TRINASOLAR"
  },
  {
    type: "onduleurs solaires, régulateurs et distribution",
    marque: "EPEVER - VICTRON  - ENPHASE"
  },
  {
    type: "batteries",
    marque: "ULTRACELL - PYLONTECH - DYNESS - VICTRON ENERGY"
  },
  {
    type: "câbles et accessoires",
    marque: "PYLONTECH - TECHNIDEAL - VICTRON  - ENPHASE"
  },
]
const WebCofinancementKitCoolMy = () => {

  const navigate = useNavigate();

  const [isPopupinfoNetworkTypeOpen, setPopupinfoNetworkTypeOpen] = useState(false);
  const [isPopupinfoStorageTypeOpen, setPopupinfoStorageTypeOpen] = useState(false);
  const [isDrawerMenuDrawerItemPopupOpen, setDrawerMenuDrawerItemPopupOpen] = useState(false);
  const [choosenProduct, setChoosenProduct] = useState([]);
  const [priceF, setPriceF] = useState(0);

  const handleProduct = (qty,item) => {
    const updatedProducts = [...choosenProduct]; // Create a copy of the products array
    const productToUpdate = updatedProducts.find(
      product => product.name === item.name && product.price === item.price
    );

    if (productToUpdate) {
      productToUpdate.quantity = qty;
    } else {
      const newProduct = item
      newProduct.quantity = qty
      updatedProducts.push(newProduct);
    }
    setChoosenProduct(updatedProducts);
    handlePriceTotal(updatedProducts)

  }

  const openDrawerMenuDrawerItemPopup = useCallback(() => {
    setDrawerMenuDrawerItemPopupOpen(true);
  }, []);

  const closeDrawerMenuDrawerItemPopup = useCallback(() => {
    setDrawerMenuDrawerItemPopupOpen(false);
  }, []);

  useEffect(() => {
    console.log(products)
  })

  
  const openPopupinfoNetworkType = useCallback(() => {
    setPopupinfoNetworkTypeOpen(true);
  }, []);

  const closePopupinfoNetworkType = useCallback(() => {
    setPopupinfoNetworkTypeOpen(false);
  }, []);

  const openPopupinfoStorageType = useCallback(() => {
    setPopupinfoStorageTypeOpen(true);
  }, []);

  const confirmProduct = () => {
    localStorage.setItem("prix-total",priceF.toFixed(2))
    navigate('/hotspot-wifi-solaire')
  }

  const handlePriceTotal = (updatedProducts) => {
    let finalePrice = 0;
    updatedProducts.forEach(element=>{
      finalePrice += parseFloat(element.quantity) * parseFloat(element.price);
    })
    finalePrice.toFixed(2)
    console.log(finalePrice)
    setPriceF(finalePrice)
  }

  return (
    <>
      <div className="web-cofinancement-kit-cool-my">
        <header className="headerheader2">
          <div className="logo3">
            <b className="creative-minds3">E+C-</b>
          </div>
          <div className="menuitems3">
            <div className="links2">
              <div className="summer-school-20232">PILOTE summer 2023</div>
              <div className="links-child3" />
              <div className="summer-school-20232">
                Investir à partir de 1 €
              </div>
              <div className="links-child3" />
              <div className="summer-school-20232">Nous rejoindre</div>
              <div className="links-child3" />
              <div className="summer-school-20232">Se connecter</div>
            </div>
            <div
              className="hamburgeritem2"
              onClick={openDrawerMenuDrawerItemPopup}
            >
              <div className="iconcontainer2">
                <img className="icon2" alt="" src="/icon16.svg" />
              </div>
            </div>
          </div>
        </header>
        <div className="divkit-preview">
          <div className="divkit-image-preview">
            <img
              className="imginterior-preview-icon"
              alt=""
              src="/imginterior-preview@2x.png"
            />
            <div className="ppicture-credit">
              {`Image par `}
              <a
                className="joseph-mucira"
                href={`https://pixabay.com/users/webtechexperts-10518280/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=7267527`}
                target="_blank"
              >
                <span className="joseph-mucira1">Joseph Mucira</span>
              </a>
              {` et `}
              <a
                className="joseph-mucira"
                href={`https://pixabay.com/users/ulleo-1834854/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=2939560`}
                target="_blank"
              >
                <span className="joseph-mucira1">Leopictures</span>
              </a>
              {` from `}
              <a
                className="joseph-mucira"
                href={`https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=7267527`}
                target="_blank"
              >
                <span className="joseph-mucira1">Pixabay</span>
              </a>
            </div>
          </div>
          <div className="divkit-choice">
            <div className="divkit-preview1">
              <div className="divkit-selection-container">
                <div className="h2product-title">Kit Cool My House</div>
                <div className="divnetwork-type">
                  <div className="divnetwork-title">
                    <div className="h3network-type">Type de réseau</div>
                    <div
                      className="buttonbtn-info"
                      onClick={openPopupinfoNetworkType}
                    >
                      <img
                        className="infofilled-icon"
                        alt=""
                        src="/infofilled.svg"
                      />
                    </div>
                  </div>
                  <div className="divnetworks">
                    <button
                      className="buttonout-network"
                      id="btn_out_network_kit_option"
                    >
                      <b className="pbtn-title1">Hors réseau</b>
                    </button>
                    <button
                      className="buttonout-network"
                      id="btn_in_network_kit_option"
                    >
                      <b className="pbtn-title1">Tout réseau</b>
                    </button>
                  </div>
                </div>
                <div className="divnetwork-type">
                  <div className="divnetwork-title">
                    <div className="h3network-type">Type de consommation</div>
                    <div
                      className="buttonbtn-info"
                      onClick={openPopupinfoStorageType}
                    >
                      <img
                        className="infofilled-icon"
                        alt=""
                        src="/infofilled.svg"
                      />
                    </div>
                  </div>
                  <div className="divnetworks">
                    <button
                      className="buttonout-network"
                      id="btn_real_time_kit_option"
                    >
                      <b className="pbtn-title1">
                        Temps réelle (sans stockage)
                      </b>
                    </button>
                    <button
                      className="buttonout-network"
                      id="btn_storage_kit_option"
                    >
                      <b className="pbtn-title1">Avec stockage</b>
                    </button>
                  </div>
                </div>
                <div className="divnetwork-type">
                  <div className="divkits-title">
                    <div className="h3network-type">Taille de la pièce</div>
                  </div>
                  <div className="divkits-options">
                    <button
                      className="buttonout-network"
                      id="btn_15m2_surface_kit_option"
                    >
                      <b className="pbtn-title1">{`Habitation légère ou surface < 15m²`}</b>
                    </button>
                    <button
                      className="buttonout-network"
                      id="btn_20m2_surface_kit_option"
                    >
                      <b className="pbtn-title1">Surface de 20m²</b>
                    </button>
                    <button
                      className="buttonout-network"
                      id="btn_25m2_surface_kit_option"
                    >
                      <b className="pbtn-title1">Surface de 25m²</b>
                    </button>
                    <button
                      className="buttonout-network"
                      id="btn_30m2_surface_kit_option"
                    >
                      <b className="pbtn-title1">Surface de 30m²</b>
                    </button>
                    <button
                      className="buttonout-network"
                      id="btn_35m2_surface_kit_option"
                    >
                      <b className="pbtn-title1">Surface de 35m²</b>
                    </button>
                    <button
                      className="buttonout-network"
                      id="btn_50m2_surface_kit_option"
                    >
                      <b className="pbtn-title1">Surface de 50m²</b>
                    </button>
                    <button
                      className="buttonout-network"
                      id="btn_100m2_surface_kit_option"
                    >
                      <b className="pbtn-title1">Surface de 100m²</b>
                    </button>
                  </div>
                </div>
                <div className="divcustom-btn">
                  <button className="buttoncustom" id="btn_custom_kit">
                    <b className="pbtn-title12">Personnaliser mon kit</b>
                  </button>
                  <div className="pinfo-custom">
                    Personnaliser un kit sans expérience peut causer des
                    combinaisons incompatibles voires dangereuses. Contactez un
                    professionnel pour des conseils sûrs.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divkit-description">
          <div className="dcouvrez-lultime-confort-container">
            <p className="dcouvrez-lultime-confort">
              Découvrez l'ultime confort avec notre kit “Cool My House” de
              climatisation portable et réversible solaire, une solution
              pratique et écologique pour réguler la température de votre
              espace.
            </p>
            <p className="dcouvrez-lultime-confort">&nbsp;</p>
            <p className="dcouvrez-lultime-confort">
              De plus, grâce à notre option de co-financement, accédez à ce
              confort exceptionnel sans compromettre votre budget.
            </p>
          </div>
        </div>
        <div className="divdivider2">
          <div className="imgdivider2" />
        </div>

        {productsClasses.map((item, key) => (
        <div key={key} className="divbrand-product-list-air-coo">
          <div className="divbrand-and-product-name">
            <div className="imgcircle-important">
              <b className="h5step-num">1</b>
            </div>
            <div className="pproduct-name">Choix {item.type}</div>
            <div className="pproduct-name">/</div>
            <b className="pbrand-title">
              {item.marque}
            </b>
          </div>
          <div className="divproduct-list">


            {(products.products.filter(product => product.type === item.type) || []).map((item1, key1) => (
              <div className="divproduct-item1" key={key1}>
                <img
                  className="imgproduct-view-icon1"
                  alt=""
                  src={item1.img}
                />
                <div className="divdivider3">
                  <div className="imgdivider2" />
                </div>
                <div className="divproduct-desc1">
                  <div className="divproduct-title-and-desc1">
                    <b className="ptitle1">{item1.name}</b>
                    <b className="pprice2">{item1.price} €</b>
                  </div>
                  <div className="divproduct-price1">
                    <div className="pdescription1">
                      <ul className="marque-ecoflow-possibilit-da">
                        {item1.prop.map((element, key2) => (
                          <li key={key2} className="marque-ecoflow">{element}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="divselection1">
                  <div className="h3quantity-label1">Quantité choisie :</div>
                  <div className="divquantity-controller1">
                    <input style={{ width: 50 }} min={0} type="number" 
                    onChange={(e)=>{
                      handleProduct(e.target.value,item1)
                      }} />
                  </div>
                </div>
              </div>))
            }

          </div>
        </div>
        ))}

        <div className="divtotal-cost1">
          <div className="divtotal-label1">
            <b className="ptotal1">TOTAL TTC</b>
          </div>
          <div className="divfinal-price1">
            <b className="ptotal1">{priceF.toFixed(2)}</b>
            {/* <div className="divprice-ht1">
              <div className="preduce1">-XX XXX €</div>
              <div className="pht1">XX XXX €</div>
            </div> */}
          </div>
          <button
            className="buttonpublish-cofinancement1"
            id="btn_publish_investment_project"
            onClick={confirmProduct}
          >
            <b className="ppublish-confinancement1">{`Publier le cofinancement `}</b>
          </button>
        </div>
        <footer className="footerfooter2">
          <div className="container2">
            <div className="col12">
              <b className="h5step-num">
                <p className="dcouvrez-lultime-confort">E+C- :</p>
                <p className="dcouvrez-lultime-confort">Investisser</p>
                <p className="dcouvrez-lultime-confort">Autrement</p>
              </b>
            </div>
            <div className="col22">
              <b className="investissement-solaire2">Investissement solaire</b>
              <div className="microproduction-pour-un-container2">
                <p className="dcouvrez-lultime-confort">
                  Microproduction pour un centre de formation : Cible, 590 € ,
                  réalisé à 83%
                </p>
                <p className="dcouvrez-lultime-confort">&nbsp;</p>
                <p className="dcouvrez-lultime-confort">
                  Créer un hot spot wifi partout alimenté via le solaire: cible
                  4900 € réalisé à 58%
                </p>
                <p className="dcouvrez-lultime-confort">&nbsp;</p>
                <p className="dcouvrez-lultime-confort">
                  MInicentrale 4,8 KWc avec support aluminium reconverti: cible
                  4800 €
                </p>
                <p className="dcouvrez-lultime-confort">
                  <b>&nbsp;</b>
                </p>
                <p className="dcouvrez-lultime-confort">&nbsp;</p>
                <p className="dcouvrez-lultime-confort">&nbsp;</p>
                <p className="dcouvrez-lultime-confort">&nbsp;</p>
              </div>
            </div>
            <div className="col32">
              <b className="investissement-solaire2">{`Soutiens & Partenaires`}</b>
              <b className="investissement-solaire2">Rentrée 2023</b>
              <b className="investissement-solaire2">Nous rejoindre</b>
              <b className="investissement-solaire2">Notre Approche</b>
              <b className="investissement-solaire2">Contact</b>
              <b className="investissement-solaire2">CGV</b>
            </div>
          </div>
          <div className="textdroit2">©2023 E+C- | Tout droits reservés</div>
        </footer>
      </div>
      {isPopupinfoNetworkTypeOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top right"
          onOutsideClick={closePopupinfoNetworkType}
        >
          <PopupinfoNetworkType onClose={closePopupinfoNetworkType} />
        </PortalPopup>
      )}
      {isDrawerMenuDrawerItemPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top center"
          onOutsideClick={closeDrawerMenuDrawerItemPopup}
        >
          <DrawerMenuDrawerItem onClose={closeDrawerMenuDrawerItemPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default WebCofinancementKitCoolMy;
