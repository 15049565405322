import "./HotspotWifiSolaire.css";
import React, { useState, useCallback, useEffect } from "react";
import DrawerMenuDrawerItem from "../components/DrawerMenuDrawerItem";
import PortalPopup from "../components/PortalPopup";
import moment from 'moment';
import { apiNodeService } from "../provider/apiNodeService";
import MuiBackdrop from "../components/Backdrop/MuiBackdrop";
import { useNavigate } from "react-router-dom";
import FooterMobile from "../components/FooterMobile";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DrawerMenuMobile from "../components/DrawerMenuMobile";
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import PlaceIcon from '@mui/icons-material/Place';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

const TreeporteurProjet1 = () => {

  const navigate = useNavigate();

  const [isDrawerMenuDrawerItemPopupOpen, setDrawerMenuDrawerItemPopupOpen] =
    useState(false);

  const [daysRemaining, setDaysRemaining] = useState(null);
  const [project, setProject] = React.useState({})
  const [loading, setLoading] = React.useState(false);
  const [pourcentage, setPourcentage] = React.useState(0)
  const [isDrawerMenuMobileOpen, setDrawerMenuMobileOpen] = useState(false);
  const [valueTab, setValueTab] = React.useState('one');
  const [emailConcatName, setEmailConcatName] = React.useState('');

  const handleChange = (newValue) => {
    setValueTab(newValue);
  };

  useEffect(() => {
    setLoading(true)
    apiNodeService.getProjectById("a3e794e6-1e19-4e46-87d6-d0072b77db3b").then(res => {
      console.log(res)
      if (res) {
        if (res.status === 200) {
          setProject(res.data)
          const concatenatedEmails = res.data.investors.map(obj => obj.email.split('@')[0]).join(', ');
          setEmailConcatName(concatenatedEmails)
          setPourcentage(((parseInt(res.data.nb_watt_used) * 100) / parseInt((res.data.nb_cellule_sol * 4))).toFixed(2))
          setLoading(false)
        } else {
          console.log(res.error)
        }
      }
    })
    const targetDate = moment('2024-02-11');
    const currentDate = moment();
    const days = targetDate.diff(currentDate, 'days');
    setDaysRemaining(days);
    const searchParams = new URLSearchParams(window.location.search);
    const projectId = searchParams.get('project');
  }, []);

  const openDrawerMenuDrawerItemPopup = useCallback(() => {
    setDrawerMenuDrawerItemPopupOpen(true);
  }, []);

  const closeDrawerMenuDrawerItemPopup = useCallback(() => {
    setDrawerMenuDrawerItemPopupOpen(false);
  }, []);

  const openDrawerMenuMobile = useCallback(() => {
    setDrawerMenuMobileOpen(true);
  }, []);

  const closeDrawerMenuMobile = useCallback(() => {
    setDrawerMenuMobileOpen(false);
  }, []);

  const openNewWindow = (url) => {
    window.open(url, '_blank');
  };

  return (
    <>
      <div className="hotspot-wifi-solaire">
        <MuiBackdrop
          open={loading}
        />
        <header className="headerheader d-flex justify-content-between">
          <div className="logo">
            {/* <b className="creative-minds">E+C-</b> */}
            <img width={20} height={30} src="/greenlogo.png" />
          </div>
          <div className="menuitems">
            <div className="links px-4">
              <div className="nous-rejoindre">Répertoire de projet</div>
              <div className="links-child" />
              <div className="nous-rejoindre">Liste des projets</div>
              <div className="links-child" />
              <div className="nous-rejoindre">Nous rejoindre</div>
              <div className="links-child" />
              <div className="nous-rejoindre">Nous contacter</div>
            </div>
            
          </div>
          <div className="d-flex" style={{gap:10}}>
          <div
              className="hamburgeritem"
              onClick={openDrawerMenuDrawerItemPopup}
            >
              <div className="iconcontainer">
                {/* <img className="icon" alt="" src="/icon15.svg" /> */}
                <MenuIcon fontSize="large" />
              </div>
            </div>
            <div className="p-2 px-5 " style={{ fontSize: 16, minWidth: 180, backgroundColor: "#1EB2A9", textAlign: 'center', color: "#fff", borderRadius: 8, fontWeight: 600 }}>Se connecter</div>
          </div>
        </header>
        {project.id &&
          <div className="divinvestment-item2">
            <b className="h1title4">
              Solar tuk tuk
            </b>
            <div className="divcompartive-tab4">
              <div className="divinvestment-render2">
                <img
                  className="imgsolar-panel-support-system-icon2"
                  alt=""
                  src="/treeporteurproject1.png"
                />
              </div>
              <div className="divinvestment-details2">
                <div className="divinvestment-state2">
                  <div className="divtitle13">
                    <img
                      className="imgdouble-right-arrow-icon4"
                      alt=""
                      src="/imgdouble-right-arrow1@2x.png"
                    />
                    <b className="h3open-investment4">Investissement ouvert</b>
                  </div>
                  <div className="divcollect-info2">
                    <div className="divcurrent-money-collected2">
                      <div className="h3open-investment4">{project.nb_watt_used} € collectés</div>
                      <div className="pmoney-wanted2">
                        {project.nb_cellule_sol * 4} € seuil de réussite
                      </div>
                    </div>
                    <b className="ppercentage2">{pourcentage}%</b>
                    <div className="divdisplay-bar2">
                      <div className="progress-bar1" style={{ width: "100%" }}>
                        <div className="progress" style={{ border: "2px solid #254952" }}>
                          <div className="progress-bar" role="progressbar" style={{ width: `${pourcentage}%`, backgroundColor: "#254952" }} aria-value={pourcentage} aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>
                    <div className="h1title4">{parseInt(project.nb_cellule_sol * 4) - parseInt(project.nb_watt_used)} € restants</div>
                    <div className="ppercentage2">{`Jusqu'au 2 février 2024 `}</div>
                  </div>
                  <div className="divinvestor-on-it2">
                    <img
                      className="imginvestor-avatar-icon2"
                      alt=""
                      src="/imginvestor-avatar1@2x.png"
                    />
                    <div className="h3open-investment5" style={{ backgroundColor: "#f9d649" }}><b>{project.investors ? project.investors.length : 0}</b></div>
                    <b className="h3open-investment4">{`Investisseurs `}</b>
                    <div className="h3open-investment66" style={{ backgroundColor: "#f9d649" }}> <b>reste {daysRemaining} jours</b></div>
                  </div>
                </div>
                <div className="divproject-hoster4">
                  <img
                    className="imgproject-hoster-icon2"
                    alt=""
                    src="/imgproject-hoster2@2x.png"
                  />
                  <div className="divproject-hoster-info2">
                    <div className="pproject-hosted-default2">Projet porté par</div>
                    <b className="pproject-hosted-default2">Treeporteur</b>
                  </div>
                  <div className="divproject-hoster-info2">
                    <div className="pproject-hosted-default2">Emplacement du projet</div>
                    <b className="pproject-hosted-default2">{project.adress.commune}</b>
                  </div>
                </div>
                <div className="divinterest-rate-and-time11">
                  <div className="divtitle13">
                    <img
                      className="imgdouble-right-arrow-icon4"
                      alt=""
                      src="/imgdouble-right-arrow1@2x.png"
                    />
                    <b className="h3open-investment4">Taux d’intérêt et durée *</b>
                  </div>
                  <div className="divinterest-rate-and-time12">
                    <img className="imglock-icon2" alt="" src="/imglock1@2x.png" />
                    <b className="h3open-investment4">
                      Consulter le taux et la durée
                    </b>
                  </div>
                </div>
                <div className="divinvest-controls2">
                  <div className="divinvest-button-field1 "
                    style={{ cursor: "pointer" }}>
                    <div className="buttoninvest-in-btn1 invest-button">
                      <div className="pinvest-btn1">Voir les risques</div>
                    </div>
                  </div>
                  <div className="divinvest-button-field1 " onClick={(e) => {
                    navigate("/frame-314?project=a3e794e6-1e19-4e46-87d6-d0072b77db3b")
                  }}
                    style={{ cursor: "pointer" }}>
                    <div className="buttoninvest-in-btn1 invest-button">
                      <div className="pinvest-btn1">Investir</div>
                    </div>
                  </div>
                </div>
                <a className="tri-taux-container2">
                  <ul className="tri-taux-de-rentabilit-inte2">
                    <span>{`TRI ( Taux de rentabilité interne ) contractuel ( `}</span>
                    <span className="voir-fiscalit2">voir fiscalité</span>
                    <span className="span3">{` ) `}</span>
                  </ul>
                </a>
              </div>
            </div>
            <div className="contentshare2">
              <div className="pproject-hosted-default2">Partager</div>
              <div className="groupshare-icons2">
                <img
                  className="capture-decran-2023-07-27-a6"
                  alt=""
                  src="/capture-decran-20230727-a-19343@2x.png"
                />
                <img
                  className="capture-decran-2023-07-27-a7"
                  alt=""
                  src="/capture-decran-20230727-a-19344@2x.png"
                />
                <img
                  className="capture-decran-2023-07-27-a7"
                  alt=""
                  src="/capture-decran-20230727-a-19345@2x.png"
                />
              </div>
            </div>
          </div>}
        <div className="investment-details2">
          <div className="pinvestment-warning2">
            Investir présente un risque de perte en capital et de liquidité.
            N’investissez que l’argent dont vous n’avez pas besoin immédiatement
            et diversifiez votre épargne.
          </div>
          <b className="h1title5">
            <p className="de-hot-spot-wifi">Détails de l'investissement</p>
          </b>
          <div className="divcompartive-tab5">
            <div className="column1caracteristics2">
              <div className="divtitle15">
                <b className="pcolumn-title9">Caractéristiques</b>
              </div>
              <div className="divaccess-field5">
                <div className="paccessible6">Accessible</div>
              </div>
              <div className="divaccess-field5">
                <div className="paccessible6">Taux d’intérêt et durée *</div>
              </div>
              <div className="divaccess-field5">
                <div className="paccessible6">Remaining</div>
              </div>
              <div className="divaccess-field5">
                <div className="paccessible6">Investissement min</div>
              </div>
              <div className="divaccess-field5">
                <div className="paccessible6">Investissement max</div>
              </div>
              <div className="divaccess-field5">
                <div className="paccessible6">Valeur unitaire</div>
              </div>
              <div className="divaccess-field5">
                <div className="paccessible6">Participant</div>
              </div>
            </div>
            <div className="column1caracteristics2">
              <div className="divtitle15">
                <b className="pcolumn-title9">Simulateur</b>
              </div>
              <div className="divaccess-field6">
                <div className="paccessible6">Accessible</div>
              </div>
              <div className="divaccess-field6">
                <div className="paccessible6">Taux d’intérêt et durée *</div>
              </div>
              <div className="divaccess-field6">
                <div className="paccessible6">Remaining</div>
              </div>
              <div className="divaccess-field6">
                <div className="paccessible6">Investissement min</div>
              </div>
              <div className="divaccess-field6">
                <div className="paccessible6">Investissement max</div>
              </div>
              <div className="divaccess-field6">
                <div className="paccessible6">Valeur unitaire</div>
              </div>
              <div className="divaccess-field6">
                <div className="paccessible6">Participant</div>
              </div>
            </div>
            <div className="column3investment-details2">
              <div className="row0who-can-invest2">
                <div className="divtitle15">
                  <b className="pcolumn-title9">Qui peut investir</b>
                </div>
                <div className="divdate-and-place-info2">
                  <div className="pdate-and-place-info4">
                    <p className="de-hot-spot-wifi">
                      À partir du jeudi 16 février 2023 à 12h30
                    </p>
                    <ul className="tri-taux-de-rentabilit-inte2">
                      <li>
                        Investissement ouvert aux habitants et collectivités des
                        départements 16 - 17 - 24 - 33 - 79 - 85
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="divaccess-field6">
                  <div className="paccessible6">Taux d’intérêt et durée *</div>
                </div>
                <div className="divaccess-field6">
                  <div className="paccessible6">Remaining</div>
                </div>
                <div className="divaccess-field6">
                  <div className="paccessible6">Investissement min</div>
                </div>
                <div className="divaccess-field6">
                  <div className="paccessible6">Investissement max</div>
                </div>
                <div className="divaccess-field6">
                  <div className="paccessible6">Valeur unitaire</div>
                </div>
                <div className="divaccess-field6">
                  <div className="paccessible6">Participant</div>
                </div>
              </div>
              <div className="row1end-of-investment2">
                <div className="divtitle15">
                  <b className="pcolumn-title9">Date de fin</b>
                </div>
                <div className="divaccess-field5">
                  <b className="pdate-and-place-info4">31 juillet 2023 à 23h59</b>
                </div>
                <div className="divend-date-info2">
                  <div className="pend-date-info2">
                    La date de fin de collecte pourra être prolongée à la demande
                    du porteur de projet.Le rendement de votre investissement sera
                    calculé à partir de la date de génération des certificats de
                    souscription, c'est-à-dire une fois l'intégralité des fonds
                    reçus et la documentation juridique signée par le porteur de
                    projet.
                  </div>
                </div>
                <div className="divsecondary-house-info2">
                  <div className="pend-date-info2">
                    Si vous avez une résidence secondaire ou principale dans l'une
                    de ces zones, vous pouvez investir en renseignant un
                    justificatif de domicile.
                  </div>
                </div>
                <div className="divaccess-field6">
                  <div className="paccessible6">Investissement min</div>
                </div>
                <div className="divaccess-field6">
                  <div className="paccessible6">Investissement max</div>
                </div>
                <div className="divaccess-field6">
                  <div className="paccessible6">Valeur unitaire</div>
                </div>
                <div className="divaccess-field6">
                  <div className="paccessible6">Participant</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divproject-hoster-details2">
          <div className="navbarproject-nav-bar2">
            <div className="buttonbutton-nav-bar-the-proj2">
              <b className="pproject-hosted-default2">{`Le projet `}</b>
              <div className="divider2" />
            </div>
            <div className="buttonbutton-nav-bar-project-2">
              <b className="pproject-hosted-default2">Le porteur de projet</b>
            </div>
            <div className="buttonbutton-nav-bar-project-2">
              <b className="pproject-hosted-default2">Actualités</b>
            </div>
            <div className="buttonbutton-nav-bar-project-2">
              <b className="pproject-hosted-default2">Commentaires</b>
            </div>
            <button className="buttonbutton-be-call-back2" id="btn_etre_rappele">
              <b className="pbe-call-back2">{`Etre rappelé `}</b>
            </button>
            <button className="buttonbutton-doc2" id="btn_documentation">
              <img
                className="imgicon-download2"
                alt=""
                src="/imgicon-download1@2x.png"
              />
              <b className="pbe-call-back2">Documentation</b>
            </button>
            <button className="buttonbutton-invest2" id="btn_investir"
              onClick={(e) => {
                navigate("/frame-314?project=a3e794e6-1e19-4e46-87d6-d0072b77db3b")
              }}>
              <b className="p-invest2">Investir</b>
            </button>
          </div>
          <div className="divessential-info2">
            <div className="divessential-description2">
              <div className="l-essentiel2">L ‘ESSENTIEL</div>
              <div className="un-centre-de2">{`Un tuk tuk, véhicule mobile alimenté par un panneau solaire et une station d’énergie.`}</div>
            </div>
            <div className="divproject-hoster5">
              <div className="divfield-simulateur2">
                <div className="psimulator2">Simulateur</div>
              </div>
              <div className="divfield-simulateur2">
                <div className="psimulator2">{`Consulter le taux et la durée `}</div>
              </div>
              <div className="div-project-hoster-details2">
                <div className="pproject-hoster-name5">
                  <p className="le-porteur2">{`LE PORTEUR `}</p>
                  <p className="de-hot-spot-wifi">DE PROJET</p>
                </div>
                <img
                  className="imgproject-hoster-img-icon2"
                  alt=""
                  src="/imgproject-hoster-img2@2x.png"
                />
              </div>
            </div>
          </div>
        </div>
        <footer className="footer">
          <div className="d-flex flex-column px-4">
            <img width={20} height={30} src="/greenlogofooter.png" />
            <p style={{ fontSize: 18 }}>Financement participatif pour tous, du solaire à l’éducation</p>
          </div>
          <div className="row align-items-start p-4" style={{ width: "100%" }}>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <p style={{ fontSize: 26, fontWeight: 700, marginTop: 40 }} className="investisseurs mb-3">Investissements en cours</p>
                <p style={{ fontSize: 18 }}>Microproduction pour centre de formation - 100 € restants</p>
                <p style={{ fontSize: 18 }}>Hot spot WiFi mobile solaire - 2 058 € restants</p>
                <p style={{ fontSize: 18 }}>Minicentrale 4.8 kWc avec support alu reconverti - 4 800 € restants</p>
                <p style={{ fontSize: 18 }}>(À venir) Pompage solaire pour un village - 100 000 € restants</p>

                <div className="d-flex mt-4" style={{ gap: 50 }}>
                  <img src="/facebookfooter.png" />
                  <img src="/twitterfooter.png" />
                  <img src="/instagramfooter.png" />
                  <img src="/linkedinfooter.png" />
                  <img src="/youtubefooter.png" />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <p style={{ fontSize: 26, fontWeight: 700, marginTop: 40 }} className="investisseurs">Découvrir Watti</p>
              <p style={{ fontSize: 18 }}>Soutiens & partenaires</p>
              <p style={{ fontSize: 18 }}>Nous rejoindre</p>
              <p style={{ fontSize: 18 }}>Notre approche</p>
              <p style={{ fontSize: 18 }}>Nous contacter</p>
            </div>
            <div className="col-md-3">
              <p style={{ fontSize: 26, fontWeight: 700, marginTop: 40 }} className="investisseurs">Mentions légales</p>
              <p style={{ fontSize: 18 }}>Politique de confidentialité</p>
              <p style={{ fontSize: 18 }}>Mentions légales</p>
              <p style={{ fontSize: 18 }}>CGV</p>
            </div>
          </div>
          <p style={{ fontSize: 18, marginTop: 40 }}>©2023-2024  Watti | Tout droits reservés</p>
        </footer>
        {isDrawerMenuDrawerItemPopupOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Top center"
            onOutsideClick={closeDrawerMenuDrawerItemPopup}
          >
            <DrawerMenuDrawerItem onClose={closeDrawerMenuDrawerItemPopup} />
          </PortalPopup>
        )}
        {isDrawerMenuMobileOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Top center"
            onOutsideClick={closeDrawerMenuMobile}
          >
            <DrawerMenuMobile onClose={closeDrawerMenuMobile} />
          </PortalPopup>
        )}
      </div>

      <div className="treeporteur-project-mobile" style={{ marginTop: 92, marginBottom: 100 }}>
        <header className="headerheader-mobile">
          <div style={{ width: "100%" }} className="d-flex  justify-content-between align-items-center">
            <KeyboardBackspaceIcon style={{ cursor: 'pointer' }} fontSize="large" onClick={(e) => { navigate(-1) }} />
            <span className="span-list-projet-mobile">Treeporteur SAS</span>
            <MenuIcon className="" fontSize="large" onClick={openDrawerMenuMobile} />
          </div>
        </header>

        <Carousel
          infiniteLoop={true}
          autoPlay={true}
          showStatus={false}
          showArrows={false}
          showThumbs={false}
          interval={5000}
          swipeable={false}
        >
          <div className="section1-detail-projet-tuktuk d-flex align-items-start flex-column px-2 pb-1">
            <div className="mt-auto d-flex align-items-center px-2">
              <img width={90} height={70} src="/imgproject-hoster2@2x.png" />
              <span className="mt-2" style={{ color: "#fff", fontSize: 30, marginLeft: 20 }}>Treeporteur SAS</span>
            </div>
            <span className="projet-description-section2" style={{ textAlign: 'left' }}>
              Système de déploiement rapide de centrales solaires réduisant les coûts et le temps d’installation.
            </span>
            <div className="d-flex justify-content-between" style={{ width: '100%' }}>
              <div className="d-flex mt-4">
                <PlaceIcon style={{ color: "#46A59F" }} fontSize="large" onClick={(e) => { openNewWindow(project.adress.maps_url) }} />
                {project.adress && <span className="mt-1" style={{ marginLeft: 15, color: "#fff", fontFamily: "Plus Jakarta Sans", fontSize: 16 }} >{project.adress.commune}</span>}
              </div>
              <div className="d-flex flex-column">
                <FavoriteIcon style={{ color: "#46A59F" }} fontSize="large" />
                <ShareIcon className="mt-3" style={{ color: "#46A59F" }} fontSize="large" />
              </div>
            </div>
          </div>
          <div className="section1-detail-projet-tuktuk d-flex align-items-start flex-column px-2 pb-1">
            <div className="mt-auto d-flex align-items-center px-2">
              <img width={90} height={70} src="/imgproject-hoster2@2x.png" />
              <span className="mt-2" style={{ color: "#fff", fontSize: 30, marginLeft: 20 }}>Treeporteur SAS</span>
            </div>
            <span className="projet-description-section2" style={{ textAlign: 'left' }}>
              Système de déploiement rapide de centrales solaires réduisant les coûts et le temps d’installation.
            </span>
            <div className="d-flex justify-content-between" style={{ width: '100%' }}>
              <div className="d-flex mt-4">
                <PlaceIcon style={{ color: "#46A59F" }} fontSize="large" onClick={(e) => { openNewWindow(project.adress.maps_url) }} />
                {project.adress && <span className="mt-1" style={{ marginLeft: 15, color: "#fff", fontFamily: "Plus Jakarta Sans", fontSize: 16 }} >{project.adress.commune}</span>}
              </div>
              <div className="d-flex flex-column">
                <FavoriteIcon style={{ color: "#46A59F" }} fontSize="large" />
                <ShareIcon className="mt-3" style={{ color: "#46A59F" }} fontSize="large" />
              </div>
            </div>
          </div>
          <div className="section1-detail-projet-tuktuk d-flex align-items-start flex-column px-2 pb-1">
            <div className="mt-auto d-flex align-items-center px-2">
              <img width={90} height={70} src="/imgproject-hoster2@2x.png" />
              <span className="mt-2" style={{ color: "#fff", fontSize: 30, marginLeft: 20 }}>Treeporteur SAS</span>
            </div>
            <span className="projet-description-section2" style={{ textAlign: 'left' }}>
              Système de déploiement rapide de centrales solaires réduisant les coûts et le temps d’installation.
            </span>
            <div className="d-flex justify-content-between" style={{ width: '100%' }}>
              <div className="d-flex mt-4">
                <PlaceIcon style={{ color: "#46A59F" }} fontSize="large" onClick={(e) => { openNewWindow(project.adress.maps_url) }} />
                {project.adress && <span className="mt-1" style={{ marginLeft: 15, color: "#fff", fontFamily: "Plus Jakarta Sans", fontSize: 16 }} >{project.adress.commune}</span>}
              </div>
              <div className="d-flex flex-column">
                <FavoriteIcon style={{ color: "#46A59F" }} fontSize="large" />
                <ShareIcon className="mt-3" style={{ color: "#46A59F" }} fontSize="large" />
              </div>
            </div>
          </div>
        </Carousel>
        <div className="section2-detail-projet d-flex flex-column" >
          <div className="bar-detail-projet align-self-center mt-3">i</div>
          <div className="section-percent align-self-center">{pourcentage} %</div>
        </div>
        <div className="px-1">
          <div className="d-flex justify-content-between text-tabs p-2 mt-5">
            <div className="mt-auto"
              onClick={(e => { handleChange("one") })}
              style={{ maxWidth: 85, textAlign: 'center', borderBottom: valueTab === 'one' ? "2px solid #188F87" : "", color: valueTab === 'one' ? "#188F87" : "" }}
            >Détails</div>
            <div className="mt-auto"
              onClick={(e => { handleChange("two") })}
              style={{ maxWidth: 85, textAlign: 'center', borderBottom: valueTab === 'two' ? "2px solid #188F87" : "", color: valueTab === 'two' ? "#188F87" : "" }}
            >Description</div>
            <div
              onClick={(e => { handleChange("three") })}
              style={{ maxWidth: 85, textAlign: 'center', borderBottom: valueTab === 'three' ? "2px solid #188F87" : "", color: valueTab === 'three' ? "#188F87" : "" }}
            >Porteur de projet</div>
            <div className="mt-auto"
              onClick={(e => { handleChange("four") })}
              style={{ maxWidth: 85, textAlign: 'center', borderBottom: valueTab === 'four' ? "2px solid #188F87" : "", color: valueTab === 'four' ? "#188F87" : "" }}
            >Actualités</div>
          </div>
          {valueTab === "one" &&
            <>
              <div className="d-flex flex-column px-2 details-text">
                <div className="d-flex justify-content-between mt-3">
                  <div>
                    <img src="/moneydetail.png" />
                    <span style={{ marginLeft: 12 }}>Collectés</span>
                  </div>
                  <span className="mt-1" style={{ color: "#188F87", fontSize: 18, fontFamily: "Plus Jakarta Sans" }}>
                    {project.nb_watt_used} €
                  </span>
                </div>

                <hr style={{ color: "#46A59F", opacity: 1 }} />

                <div className="d-flex justify-content-between">
                  <div>
                    <img src="/flech.svg" />
                    <span style={{ marginLeft: 12 }}>Objectif du projet</span>
                  </div>
                  <span className="mt-1" style={{ color: "#188F87", fontSize: 18, fontFamily: "Plus Jakarta Sans" }}>
                    {project.nb_cellule_sol * 4} €
                  </span>
                </div>

                <hr style={{ color: "#46A59F", opacity: 1 }} />

                <div className="d-flex justify-content-between">
                  <div>
                    <img src="/watch.svg" />
                    <span style={{ marginLeft: 12 }}>Temps restant</span>
                  </div>
                  <span className="mt-1" style={{ color: "#188F87", fontSize: 18, fontFamily: "Plus Jakarta Sans" }}>
                    {daysRemaining} jours
                  </span>
                </div>

                <hr style={{ color: "#46A59F", opacity: 1 }} />

                <div className="d-flex justify-content-between">
                  <div>
                    <img src="/percent.svg" />
                    <span style={{ marginLeft: 12 }}>Taux d’intérêts annuel</span>
                  </div>
                  <span className="mt-1" style={{ color: "#188F87", fontSize: 18, fontFamily: "Plus Jakarta Sans" }}>
                    4 %
                  </span>
                </div>

                <hr style={{ color: "#46A59F", opacity: 1 }} />

                <div className="d-flex justify-content-between">
                  <div>
                    <img src="/calendar.svg" />
                    <span style={{ marginLeft: 12 }}>Échéance</span>
                  </div>
                  <span className="mt-1" style={{ color: "#188F87", fontSize: 18, fontFamily: "Plus Jakarta Sans" }}>
                    2 ans
                  </span>
                </div>

                <hr style={{ color: "#46A59F", opacity: 1 }} />

                <div className="d-flex justify-content-between">
                  <div>
                    <img src="/contract.svg" />
                    <span style={{ marginLeft: 12 }}>Type d’action</span>
                  </div>
                  <span style={{ color: "#188F87", fontSize: 18, fontFamily: "Plus Jakarta Sans", maxWidth: 105 }}>
                    Obligations convertibles
                  </span>
                </div>

              </div>
            </>
          }
          {valueTab === "two" &&
            <>
              <div className="d-flex flex-column mt-3 px-1">
                <img src="/treeporteurproject1.png" />
                <div className="bar-description-projet align-self-center mt-3"></div>
                <div className="px-4 mt-3">
                  <p className="p-description">Un tuk-tuk électrique innovant, conçu pour être une solution de mobilité urbaine écologique et durable comportant quatre panneaux solaires rétractables logés dans le coffre, pour une recharge solaire autonome.</p>
                  <p className="p-description">L’utilisation de la batterie onduleur de haute performance permet de stocker l'énergie solaire et de charger le tuk-tuk.
                    Ce projet comporte 2 options de chargement (via panneaux solaires ou branchement à une source d'énergie conventionnelle).
                  </p>
                  <p className="p-description">
                    Un design compact permet au tuk-tuk une meilleure navigation dans les zones urbaines congestionnées et permet une facilité de stationnement.
                    De plus, ce projet favorise l’emploi à travers l'utilisation des tuk-tuks pour les services de taxi et de livraison, tout en réduisant l'empreinte carbone.</p>
                    <p className="p-description">
                    Il est également possible d’ajouter des stations de charge solaire afin d’augmenter les capacités pour des flottes de tuk-tuks.
Pour finir, le coût de fonctionnement est grandement réduit grâce à l'utilisation de l'énergie solaire, rendant le service plus accessible pour tous.</p>
                </div>
              </div>
            </>
          }
          {valueTab === "three" &&
            <>
              <div className="d-flex flex-column mt-3 px-1">
                <div className="d-flex justify-content-between px-3">
                  <img width={100} height={70} src="/imgproject-hoster2@2x.png" />
                  <div className="d-flex flex-column">
                    <span style={{ color: "#188F87", fontSize: 16, fontFamily: "Plus Jakarta Sans" }}>Le projet est porté par</span>
                    <span style={{ color: "#188F87", fontSize: 24, fontFamily: "Plus Jakarta Sans" }}>Treeporteur SAS</span>
                  </div>
                </div>
                <div className="bar-description-projet align-self-center mt-3"></div>
                <div className="px-2 mt-3">
                  <p className="p-description">Treeporteur SAS à comme mission d'innover dans la conception, le développement, la mise à disposition et l'exploitation d'infrastructures techniques logicielles et d'applications web/mobiles.  </p>
                  <p className="p-description">Leur vison cherche à offrir des solutions permettant une personnalisation complète des aménagements, qu'ils soient fixes ou mobiles. En outre, Ils s’engagent dans la réalisation physique de ces espaces. </p>
                  <p className="p-description">Leur expertise s'étend également à la planification de formations, d'événements et à l'élaboration de stratégies de commercialisation dans une diversité de domaines associés.</p>

                  <span style={{ color: "#188F87", fontSize: 20, fontFamily: "Plus Jakarta Sans" }}>Treeporteur SAS</span>

                  <div className="porter-bottom-card d-flex flex-column mt-4 py-4 px-3">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <img width={50} height={50} src="/gateau.svg" />
                        <div className="d-flex flex-column">
                          <span style={{ fontSize: 22, color: "#188F87", marginLeft: 10 }}>2 ans</span>
                          <span style={{ marginLeft: 10, color: "#188F87", fontSize: 14, fontFamily: "Plus Jakarta Sans" }}>Créé(e) en 2021</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <img width={50} height={50} src="/Flag.svg" />
                        <div className="d-flex flex-column">
                          <span style={{ fontSize: 20, color: "#188F87", marginLeft: 10 }}>1 pays</span>
                          <span style={{ marginLeft: 10, color: "#188F87", fontSize: 14, fontFamily: "Plus Jakarta Sans" }}>Présence en France</span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex mt-4">
                      <img width={50} height={50} src="/activitydiagram.png" />
                      <div className="d-flex flex-column">
                        <span style={{ fontSize: 22, color: "#188F87", marginLeft: 10 }}>3 secteurs d’activités</span>
                        <span style={{ marginLeft: 10, color: "#188F87", fontSize: 14, fontFamily: "Plus Jakarta Sans" }}>Logiciels de simulations, électronique, transports</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
          {valueTab === "four" &&
            <>
              <div className="d-flex flex-column px-2 actuality-text">
                <div className="d-flex justify-content-between mt-3">
                  <div className="d-flex">
                    <img src="/actu1.png" />
                    <span style={{ marginLeft: 12, maxWidth: 180 }}>Lancement de la campagne de financement</span>
                  </div>
                  <span className="mt-1">
                  Le 11/09/2023
                  </span>
                </div>

                <hr style={{ color: "#46A59F", opacity: 1 }} />

                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <img src="/actu2.png" />
                    <span style={{ marginLeft: 12, maxWidth: 180 }}>Seuil des 25 % dépassé !</span>
                  </div>
                  <span className="mt-1">
                  Le 21/09/2023
                  </span>
                </div>

                <hr style={{ color: "#46A59F", opacity: 1 }} />

                {/* <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <img src="/actu3.png" />
                    <span style={{ marginLeft: 12, maxWidth: 180 }}>Seuil des 50 % dépassé !</span>
                  </div>
                  <span className="mt-1">
                    Le XX/XX/XXXX
                  </span>
                </div>

                <hr style={{ color: "#46A59F", opacity: 1 }} />

                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <img src="/actu4.png" />
                    <span style={{ marginLeft: 12, maxWidth: 180 }}>Seuil des 75 % dépassé !</span>
                  </div>
                  <span className="mt-1">
                    Le XX/XX/XXXX
                  </span>
                </div>

                <hr style={{ color: "#46A59F", opacity: 1 }} />

                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <img src="/actu5.png" />
                    <span style={{ marginLeft: 12, maxWidth: 180 }}>Seuil des 100 % dépassé !</span>
                  </div>
                  <span className="mt-1">
                    Le XX/XX/XXXX
                  </span>
                </div>

                <hr style={{ color: "#46A59F", opacity: 1 }} />

                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <img src="/actu5.png" />
                    <span style={{ marginLeft: 12, maxWidth: 180 }}>Projet annulé par manque de fond</span>
                  </div>
                  <span className="mt-1">
                    Le XX/XX/XXXX
                  </span>
                </div>

                <hr style={{ color: "#46A59F", opacity: 1 }} />

                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <img src="/actu5.png" />
                    <span style={{ marginLeft: 12, maxWidth: 180 }}>Projet installé et opérationnel</span>
                  </div>
                  <span className="mt-1">
                    Le XX/XX/XXXX
                  </span>
                </div>

                <hr style={{ color: "#46A59F", opacity: 1 }} />

                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <img src="/actu5.png" />
                    <span style={{ marginLeft: 12, maxWidth: 180 }}>Financement du projet terminé !</span>
                  </div>
                  <span className="mt-1">
                    Le XX/XX/XXXX
                  </span>
                </div> */}
              </div>
            </>
          }
        </div>

        <div className="detail-final d-flex flex-column mt-5 px-2">
          {project.investors &&
            <div className="d-flex align-items-center">
              <AvatarGroup max={4}>
                {
                  project.investors.map((item, key) => (
                    <Avatar key={key} alt="Remy Sharp" src="/avatar12.png" />
                  ))
                }

              </AvatarGroup>
              <span style={{ marginLeft: 10 }} className="concat-emails">{emailConcatName}</span>
            </div>
          }
          <button onClick={(e) => {
            navigate("/frame-314?project=a3e794e6-1e19-4e46-87d6-d0072b77db3b")
          }} className="btn-discover-mobile align-self-center mt-4">
            <b className="default-btn">J'investi !</b>
          </button>
          <p style={{ textAlign: "center" }} className="concat-emails mt-2">Investir présente un risque de perte en capital et de liquidité. N’investissez que l’argent dont vous n’avez pas besoin immédiatement et diversifiez votre épargne.</p>
        </div>
        <FooterMobile />

        {isDrawerMenuMobileOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Top center"
            onOutsideClick={closeDrawerMenuMobile}
          >
            <DrawerMenuMobile onClose={closeDrawerMenuMobile} />
          </PortalPopup>
        )}
      </div>
    </>
  );
};

export default TreeporteurProjet1;
