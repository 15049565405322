import HomeIcon from '@mui/icons-material/Home';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import WindowIcon from '@mui/icons-material/Window';
import { useNavigate } from "react-router-dom";
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const FooterMobile = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const [selectedPage, setSelectedPage] = React.useState('/')

  useEffect(() => {
    const currentRoute = location.pathname;
    setSelectedPage(currentRoute)
    console.log(currentRoute)
  }, []);

  const handleSelectedPage = (selected) => {
    setSelectedPage(selected)
  }


  return (
    <div className="frame-228bottomappbar">
      {/* <img className="subtract-icon" alt="" src="/subtract.svg" />
        <img className="vector-icon26" alt="" src="/homef.png" />
        <img className="vector-icon27" alt="" src="/vector14.svg" />
        <img className="vector-icon28" alt="" src="/vector15.svg" />
        <img className="vector-icon29" alt="" src="/vector13.svg" />
        
        <button className="scanfab">
          <div className="scanfab-child" />
          <img className="scan-icon" alt="" src="/scan.svg" />
        </button> */}
      <HomeIcon style={{ cursor: 'pointer', color: selectedPage === "/" ? '#477520' : "" }}
        fontSize='large'
        onClick={(e) => {
          handleSelectedPage('/')
          navigate('/')
        }}
      />
      <AccountBalanceWalletIcon fontSize='large' />
      <WindowIcon style={{ cursor: 'pointer', color: selectedPage === "/web-invest" ? '#477520' : "" }}
        fontSize='large'
        onClick={(e) => {
          handleSelectedPage('/web-invest')
          navigate('/web-invest')
        }} />
      <PeopleIcon style={{ cursor: 'pointer', color: selectedPage === "/frame-chat" ? '#477520' : "" }}
        fontSize='large'
        onClick={(e) => {
          handleSelectedPage('/frame-chat')
          navigate('/frame-chat')
        }}
      />
      <PersonIcon style={{ cursor: 'pointer', color: selectedPage === "/profile" || selectedPage === "/setting-details" || selectedPage === "/setting" ? '#477520' : "" }}
      fontSize='large'
        onClick={(e) => {
          handleSelectedPage('/profile')
          navigate('/profile')
        }}
      />
    </div>
  )
}
export default FooterMobile;