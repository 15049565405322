exports.secteurs = [
    {"label": "Technologie"},
    {"label": "Finance"},
    {"label": "Santé"},
    {"label": "Énergie"},
    {"label": "Industrie manufacturière"},
    {"label": "Commerce de détail"},
    {"label": "Services professionnels"},
    {"label": "Éducation"},
    {"label": "Transport"},
    {"label": "Alimentation et boissons"},
    {"label": "Immobilier"},
    {"label": "Tourisme et loisirs"},
    {"label": "Télécommunications"},
    {"label": "Médias et divertissement"},
    {"label": "Mode et habillement"},
    {"label": "Automobile"},
    {"label": "Agriculture"},
    {"label": "Aérospatiale et défense"},
    {"label": "Environnement"},
    {"label": "Biotechnologie"},
    {"label": "Ingénierie"},
    {"label": "Artisanat"},
    {"label": "Juridique"},
    {"label": "Consultation"},
    {"label": "Logistique"},
    {"label": "Assurance"},
    {"label": "Banque"},
    {"label": "Électronique grand public"},
    {"label": "Sécurité informatique"},
    {"label": "Immobilier commercial"},
    {"label": "Construction"},
    {"label": "Spectacle"},
    {"label": "Ingénierie informatique"},
    {"label": "Robotique"},
    {"label": "Éducation en ligne"},
    {"label": "Ressources humaines"},
    {"label": "Recyclage"},
    {"label": "Aménagement paysager"},
    {"label": "Génie civil"},
    {"label": "Événementiel"},
    {"label": "Restauration"},
    {"label": "Développement web"},
    {"label": "Marketing numérique"},
    {"label": "Consultance en gestion"},
    {"label": "Gestion de projet"},
    {"label": "Audiovisuel"},
    {"label": "Édition"},
    {"label": "Fabrication de produits chimiques"},
    {"label": "Exploitation minière"},
    {"label": "Services funéraires"},
    {"label": "Plomberie et chauffage"},
    {"label": "Gestion des déchets"},
    {"label": "Énergies renouvelables"},
    {"label": "E-commerce"},
    {"label": "Santé mentale"},
    {"label": "Équipement sportif"},
    {"label": "Architecture"},
    {"label": "Photographie"},
    {"label": "Agence de voyage"},
    {"label": "Gestion de la chaîne d'approvisionnement"},
    {"label": "Fabrication de matériel médical"},
    {"label": "Élevage"},
    {"label": "Fabrication de jouets"},
    {"label": "Foresterie"},
    {"label": "Conception graphique"},
    {"label": "Cinéma"},
    {"label": "Développement de jeux vidéo"},
    {"label": "Hôtellerie"},
    {"label": "Laboratoire de recherche"},
    {"label": "Fabrication de meubles"},
    {"label": "Sécurité industrielle"},
    {"label": "Cabinet comptable"},
    {"label": "Agence de publicité"},
    {"label": "Gestion de patrimoine"},
    {"label": "Fabrication de textiles"},
    {"label": "Fabrication de papier"},
    {"label": "Commerce en gros"},
    {"label": "Fabrication de métal"},
    {"label": "Services de nettoyage"},
    {"label": "Boulangerie et pâtisserie"},
    {"label": "Gestion de projet informatique"},
    {"label": "Services vétérinaires"},
    {"label": "Agroalimentaire"},
    {"label": "Fabrication de verre"},
    {"label": "BTP"},
    {"label": "Transports en commun"},
    {"label": "Cabinet de conseil en stratégie"},
    {"label": "Fabrication de céramiques"},
    {"label": "Développement durable"},
    {"label": "Distribution de films"},
    {"label": "Analyse de données"},
    {"label": "Fabrication de plastiques"},
    {"label": "Services de traduction"},
    {"label": "Gestion de la qualité"},
    {"label": "Conception de mode"},
    {"label": "Gestion des ressources naturelles"},
    {"label": "Fabrication de pneumatiques"},
    {"label": "Étude de marché"},
    {"label": "Services postaux"},
    {"label": "Fabrication de matériel audio"},
    {"label": "Événementiel sportif"},
    {"label": "Électronique embarquée"},
    {"label": "Pisciculture"},
    {"label": "Fabrication de véhicules électriques"},
    {"label": "Sécurité alimentaire"},
    {"label": "Services de livraison"},
    {"label": "Recrutement"},
    {"label": "Distribution de musique"},
    {"label": "Services d'archivage"},
    {"label": "Services de santé animale"},
    {"label": "Fabrication de chaussures"},
    {"label": "Consultance en marketing"},
    {"label": "Fabrication de produits en cuir"},
    {"label": "Édition musicale"},
    {"label": "Gestion des risques"},
    {"label": "Fabrication de produits en caoutchouc"},
    {"label": "Animation"},
    {"label": "Gestion des installations"},
    {"label": "Fabrication de produits laitiers"},
    {"label": "Formation professionnelle"},
    {"label": "Fabrication de produits cosmétiques"},
    {"label": "Horticulture"},
    {"label": "Élevage de volailles"},
    {"label": "Entreposage et logistique"},
    {"label": "Gestion des investissements"},
    {"label": "Consultance en communication"},
    {"label": "Fabrication de produits en bois"},
    {"label": "Éducation musicale"},
    {"label": "Services de location de véhicules"},
    {"label": "Services financiers"},
    {"label": "Fabrication de produits pharmaceutiques"},
    {"label": "Consultance en ressources humaines"},
    {"label": "Collecte de fonds"},
    {"label": "Énergie solaire"},
    {"label": "Services de conseil fiscal"},
    {"label": "Fabrication de produits métalliques"},
    {"label": "Conseil en conception de sites web"},
    {"label": "Consultance en développement international"},
    {"label": "Services de conseil en santé mentale"},
    {"label": "Analyse financière"},
    {"label": "Fabrication de machines agricoles"},
    {"label": "Fabrication de matériaux de construction"},
    {"label": "Consultance en éducation"},
    {"label": "Services de design d'intérieur"},
    {"label": "Études environnementales"},
    {"label": "Services de conseil en sécurité"},
    {"label": "Services d'entretien automobile"},
    {"label": "Consultance en durabilité"},
    {"label": "Gestion de flotte"},
    {"label": "Consultance en conception de produits"},
    {"label": "Services de conseil juridique"},
    {"label": "Services d'entretien des équipements"},
    {"label": "Consultance en énergie"},
    {"label": "Consultance en architecture"},
    {"label": "Services de conseil en marketing digital"},
    {"label": "Gestion de l'eau"},
    {"label": "Consultance en éthique des affaires"},
    {"label": "Consultance en sécurité informatique"},
    {"label": "Services de conseil en ingénierie"},
    {"label": "Consultance en relations publiques"},
    {"label": "Services de conseil en gestion de la chaîne d'approvisionnement"},
    {"label": "Consultance en finances personnelles"},
    {"label": "Consultance en intelligence artificielle"},
    {"label": "Services de conseil en ressources naturelles"},
    {"label": "Services de conseil en gestion des risques"},
    {"label": "Services de conseil en gestion de projet"},
    {"label": "Consultance en conformité réglementaire"},
    {"label": "Services de conseil en stratégie d'entreprise"},
    {"label": "Consultance en conception de logiciels"},
    {"label": "Services de conseil en gestion de la qualité"},
    {"label": "Services de conseil en développement de produits"},
    {"label": "Services de conseil en efficacité opérationnelle"},
    {"label": "Consultance en développement de carrière"},
    {"label": "Consultance en gestion de la relation client"},
    {"label": "Consultance en gestion des talents"},
    {"label": "Consultance en développement organisationnel"}
]