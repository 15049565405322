import React, { useEffect } from "react";
import FooterMobile from "../components/FooterMobile";
import "./Login.css"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { apiNodeService } from "../provider/apiNodeService";
import MuiBackdrop from "../components/Backdrop/MuiBackdrop";
import { useNavigate } from "react-router-dom";

const Signup = () => {

    const navigate = useNavigate();

    const [email, setEmail] = React.useState('')
    const [nom, setNom] = React.useState('')
    const [prenom, setPrenom] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [passwordVerif, setPasswordVerif] = React.useState('')
    const [validate, setValidate] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [isEmptyValue, setIsEmptyValue] = React.useState(false)
    const [isMatch, setIsMatch] = React.useState(false)
    const [inscritError, setInscritError] = React.useState(false)

    useEffect(() => {

    }, []);

    const getUserByEmail = async () => {
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "green_user", filter: { email: email }, db: "CREATIVE_MINDS" }).then(userRes => {
                console.log(userRes)
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    console.log(userRes.data[0])
                    resolve(userRes.data[0])
                } else {
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    const inscription = async () => {
        setIsEmptyValue(false)
        setValidate(false)
        setIsMatch(false)
        setInscritError(false)
        const exist = await getUserByEmail()
        if (exist) {
            setValidate(true)
        } else {
            if (nom === "" || prenom === "" || email === "" || password === "" || passwordVerif === "") {
                setIsEmptyValue(true)
            } else {
                if (password !== passwordVerif) {
                    setIsMatch(true)
                } else {
                    let data = {
                        first_name: prenom,
                        last_name: nom,
                        password: password,
                        email: email
                    }
                    console.log(data)
                    apiNodeService.registerGreen(data).then(res=>{
                        console.log(res)
                        if (res && res.status === 200) {
                            navigate(-1)
                        } else {
                            console.log("erreur")
                            setInscritError(true)
                        }
                    })
                    // apiNodeService.insertTableData({ db: "CREATIVE_MINDS", table: "gree  n_user", data: data }).then(resAdd => {
                    //     console.log(resAdd)
                    //     if (resAdd && resAdd.status === 200) {
                    //         navigate(-1)
                    //     } else {
                    //         console.log('false')
                    //         setInscritError(true)
                    //     }
                    // })
                }
            }
        }
    }
    return (
        <>
            <div className="login-page">
                <MuiBackdrop
                    open={loading}
                />
                <header className="headerheader-mobile">
                    <div style={{ width: "100%" }} className="d-flex align-items-center justify-content-between">
                        <KeyboardBackspaceIcon style={{ cursor: 'pointer' }} fontSize="large" onClick={(e) => { navigate(-1) }} />
                        <span className="span-list-projet-mobile">Inscription</span>
                        <span></span>
                    </div>
                </header>
                <div className="p-3 mt-5">
                    <div className="card-etape d-flex flex-column align-self-center p-3 mt-5">
                        <span className="title-etape">Créez votre compte</span>
                        <div className="form-group d-flex mt-4" style={{ gap: 4 }}>
                            <input onChange={(e) => { setPrenom(e.target.value) }} type="email" className="form-control is-valid" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Prénom" />
                            <input onChange={(e) => { setNom(e.target.value) }} type="email" className="form-control is-valid"  aria-describedby="emailHelp" placeholder="Nom" />
                        </div>
                        <div className="form-group mt-3">
                            <input onChange={(e) => { setEmail(e.target.value) }} type="email" className="form-control is-valid"  aria-describedby="emailHelp" placeholder="Email" />
                        </div>
                        <div className="form-group d-flex mt-4" style={{ gap: 4 }}>
                            <input onChange={(e) => { setPassword(e.target.value) }} type="password" className="form-control is-valid"  aria-describedby="emailHelp" placeholder="Mot de passe" />
                        </div>
                        <div className="form-group d-flex mt-4" style={{ gap: 4 }}>
                            <input onChange={(e) => { setPasswordVerif(e.target.value) }} type="password" className="form-control is-valid" aria-describedby="emailHelp" placeholder="Confirmez mot de passe" />
                        </div>
                        {validate === true && <span className="mt-1" style={{ color: "red", marginLeft: 2 }}> Email existe !</span>}
                        {isEmptyValue === true && <span className="mt-1" style={{ color: "red", marginLeft: 2 }}> Veuillez remplir les champs *</span>}
                        {isMatch === true && <span className="mt-1" style={{ color: "red", marginLeft: 2 }}>Veuillez vérifier le mot de passe !</span>}
                        <div className="d-flex flex-column align-items-center mt-3">
                            <button onClick={inscription} className="login-button">
                                Inscription
                            </button>
                        </div>
                        {inscritError === true && <span className="mt-1" style={{ color: "red", marginLeft: 2 }}>Erreur survenue !</span>}
                    </div>
                </div>
                <FooterMobile />
            </div>
        </>
    )
}
export default Signup;