import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import './Setting.css'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Brightness4OutlinedIcon from '@mui/icons-material/Brightness4Outlined';
import GTranslateOutlinedIcon from '@mui/icons-material/GTranslateOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import FooterMobile from '../components/FooterMobile';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useNavigate } from "react-router-dom";

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#5C2DA8',
                borderRadius: 16
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 16,
        height: 16,
        borderRadius: 16,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 30 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const Setting = () => {

    const navigate = useNavigate();

    const [sectionDisplayed,setSectionDisplayed] = React.useState("")

    const handleSection = (section) => {
        navigate("/setting-details?section="+section)
    }

    return (
        <>
            <div className="setting-page">
                <header className="headerheader-mobile">
                    <div style={{ width: "100%" }} className="d-flex align-items-center justify-content-between">
                        <KeyboardBackspaceIcon style={{ cursor: 'pointer' }} fontSize="large" onClick={(e) => { navigate(-1) }} />
                        <span className="span-list-projet-mobile">Paramètres</span>
                        <span></span>
                    </div>
                </header>
                    <div className='section1-setting p-3 d-flex flex-column'>
                        <span className='span-setting mt-3'>Général</span>
                        <div className='d-flex flex-column px-2 py-3 mt-2 first-setting'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='title-setting d-flex align-items-center' style={{ gap: 10 }}>
                                    <QuestionMarkIcon fontSize='large' style={{ color: "#477520" }} />
                                    <span>Aide</span>
                                </div>
                                <ArrowForwardIosIcon onClick={(e)=>{handleSection("Aide")}} fontSize='large' style={{ color: "#477520" }} />
                            </div>
                            <hr />
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='title-setting d-flex align-items-center' style={{ gap: 10 }}>
                                    <PersonOutlineIcon fontSize='large' style={{ color: "#477520" }} />
                                    <span>Compte</span>
                                </div>
                                <ArrowForwardIosIcon onClick={(e)=>{handleSection("Compte")}} fontSize='large' style={{ color: "#477520" }} />
                            </div>
                            <hr />
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='title-setting d-flex align-items-center' style={{ gap: 10 }}>
                                    <LockOutlinedIcon fontSize='large' style={{ color: "#477520" }} />
                                    <span>Sécurité et confidentialité</span>
                                </div>
                                <ArrowForwardIosIcon onClick={(e)=>{handleSection("Sécurité et confidentialité")}} fontSize='large' style={{ color: "#477520" }} />
                            </div>
                            <hr />
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='title-setting d-flex align-items-center' style={{ gap: 10 }}>
                                    <EmailOutlinedIcon fontSize='large' style={{ color: "#477520" }} />
                                    <span>Communication</span>
                                </div>
                                <ArrowForwardIosIcon onClick={(e)=>{handleSection("Communication")}} fontSize='large' style={{ color: "#477520" }} />
                            </div>
                            <hr />
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='title-setting d-flex align-items-center' style={{ gap: 10 }}>
                                    <span className='span-setting-eplus'>GreenCard</span>
                                    <span>À propos de GreenCard</span>
                                </div>
                                <ArrowForwardIosIcon fontSize='large' style={{ color: "#477520" }} />
                            </div>
                        </div>
                    </div>

                    <div className='section2-setting p-3 d-flex flex-column'>
                        <span className='span-setting mt-2'>Personnalisation</span>
                        <div className='d-flex flex-column px-2 py-3 mt-2 first-setting'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='title-setting d-flex align-items-center' style={{ gap: 10 }}>
                                    <Brightness4OutlinedIcon fontSize='large' style={{ color: "#477520" }} />
                                    <span>Apparence</span>
                                </div>
                                <div className='d-flex flex-column align-items-center'>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <AntSwitch size='large' defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                    </Stack>
                                    <span style={{ fontSize: 14 }}>sombre</span>
                                </div>
                            </div>
                            <hr />
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='title-setting d-flex align-items-center' style={{ gap: 10 }}>
                                    <GTranslateOutlinedIcon fontSize='large' style={{ color: "#477520" }} />
                                    <span>Langue</span>
                                </div>
                                <ArrowForwardIosIcon fontSize='large' style={{ color: "#477520" }} />
                            </div>
                        </div>
                    </div>

                    <div className='final-section-setting p-3 mt-2'>
                        <div onClick={(e)=>{
                            localStorage.clear()
                            navigate('/')
                            }} 
                            className='d-flex deconet-setting p-3 align-items-center' style={{ gap: 10 }}>
                            <ExitToAppOutlinedIcon fontSize='large' style={{ color: "8F1818" }} />
                            <span>Se deconnecter</span>
                        </div>

                        <div className='d-flex flex-column justify-content-center mt-4 align-items-center'>
                            <span>V.0.0.4 </span>
                            <span>GreenCard</span>
                        </div>
                    </div>
                <FooterMobile />
            </div>
        </>
    )
}
export default Setting;