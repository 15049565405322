exports.impacts = [
    {label:"Conservation de la nature"},
    {label:"Nature régénération"},
    {label:"Éducation"},
    {label:"Énergie renouvelable"},
    {label:"Atténution du changement climatique"},
    {label:"Adaptation au climat - résilience"},
    {label:"Santé et bien-être"},
    {label:"Gestion des terres"},
    {label:"Gestion de l’eau"},
    {label:"Éco-alimentaire et nutrition"},
    {label:"Conservation de la biodiversité"},
    {label:"Développement de la communauté"},
    {label:"Réduction de la pauvreté"},
    {label:"Égalité des genres"},
    {label:"Engagement communautaire"},
    {label:"Transport propre"},
    {label:"Gestion des déchêts"},
    {label:"Technologies financières"},
    {label:"Entreprenariat et Développement PME"},
    {label:"Paix et justice"},
    {label:"Droits humains et antiviolence"}
]