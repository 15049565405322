import "./HotspotWifiSolaire.css";
import React, { useState, useCallback, useEffect } from "react";
import DrawerMenuDrawerItem from "../components/DrawerMenuDrawerItem";
import PortalPopup from "../components/PortalPopup";
import moment from 'moment';
import { apiNodeService } from "../provider/apiNodeService";
import MuiBackdrop from "../components/Backdrop/MuiBackdrop";
import { useNavigate } from "react-router-dom";

const Rooftop = () => {

    const navigate = useNavigate();

    const [isDrawerMenuDrawerItemPopupOpen, setDrawerMenuDrawerItemPopupOpen] =
        useState(false);

    const [daysRemaining, setDaysRemaining] = useState(null);
    const [project, setProject] = React.useState({})
    const [loading, setLoading] = React.useState(false);
    const [pourcentage, setPourcentage] = React.useState(0)

    useEffect(() => {
        setLoading(true)
        apiNodeService.getProjectById("564c90b5-1c46-4743-89cb-b94e640efec2").then(res => {
            console.log(res)
            if (res) {
                if (res.status === 200) {
                    setProject(res.data)
                    setPourcentage(((parseInt(res.data.nb_cellule_sol) * 100) / parseInt(res.data.capacite_watt)).toFixed(2))
                    setLoading(false)
                } else {
                    console.log(res.error)
                }
            }
        })
        const targetDate = moment('2023-12-31');
        const currentDate = moment();
        const days = targetDate.diff(currentDate, 'days');
        setDaysRemaining(days);
        const searchParams = new URLSearchParams(window.location.search);
        const projectId = searchParams.get('project');
    }, []);

    const openDrawerMenuDrawerItemPopup = useCallback(() => {
        setDrawerMenuDrawerItemPopupOpen(true);
    }, []);

    const closeDrawerMenuDrawerItemPopup = useCallback(() => {
        setDrawerMenuDrawerItemPopupOpen(false);
    }, []);

    return (
        <div className="hotspot-wifi-solaire">
            <MuiBackdrop
                open={loading}
            />
            <header className="headerheader2">
                <div className="logo3">
                    <b className="creative-minds3">E+C-</b>
                </div>
                <div className="menuitems3">
                    <div className="links2">
                        <div className="summer-school-20232">PILOTE summer 2023</div>
                        <div className="links-child3" />
                        <div className="summer-school-20232">
                            Investir à partir de 1 €
                        </div>
                        <div className="links-child3" />
                        <div className="summer-school-20232">Nous rejoindre</div>
                        <div className="links-child3" />
                        <div className="summer-school-20232">Se connecter</div>
                    </div>
                    <div
                        className="hamburgeritem2"
                        onClick={openDrawerMenuDrawerItemPopup}
                    >
                        <div className="iconcontainer2">
                            <img className="icon2" alt="" src="/icon16.svg" />
                        </div>
                    </div>
                </div>
            </header>
            {project.id &&
                <div className="divinvestment-item2">
                    <b className="h1title4">
                        Créer un hot spot wifi partout alimenté via le solaire
                    </b>
                    <div className="divcompartive-tab4">
                        <div className="divinvestment-render2">
                            {/* <div className="divtags2">
                                <img
                                    alt=""
                                    src="/tag1.png"
                                    width={130}
                                />
                                <img
                                    alt=""
                                    src="/tag2.png"
                                    width={120}
                                />
                                <img
                                    alt=""
                                    src="/tag3.png"
                                    width={100}
                                />
                                <img
                                    alt=""
                                    src="/tag4.png"
                                    width={93}
                                />
                                <img
                                    alt=""
                                    src="/tag5.png"
                                    width={90}
                                />
                                <img
                                    alt=""
                                    src="/tag6.png"
                                    width={130}
                                />
                                <img
                                    alt=""
                                    src="/tag7.png"
                                    width={120}
                                />
                                <img
                                    alt=""
                                    src="/tag8.png"
                                    width={145}
                                />
                                <img
                                    alt=""
                                    src="/tag9.png"
                                    width={130}
                                />
                                <img
                                    alt=""
                                    src="/tag10.png"
                                    width={145}
                                />
                            </div> */}
                            <img
                                className="imgsolar-panel-support-system-icon2"
                                alt=""
                                src="/imgsolar-panel-support-system2@2x.png"
                            />
                        </div>
                        <div className="divinvestment-details2">
                            <div className="divinvestment-state2">
                                <div className="divtitle13">
                                    <img
                                        className="imgdouble-right-arrow-icon4"
                                        alt=""
                                        src="/imgdouble-right-arrow1@2x.png"
                                    />
                                    <b className="h3open-investment4">Investissement ouvert</b>
                                </div>
                                <div className="divcollect-info2">
                                    <div className="divcurrent-money-collected2">
                                        <div className="h3open-investment4">{project.nb_cellule_sol} € collectés</div>
                                        <div className="pmoney-wanted2">
                                            {project.capacite_watt} € seuil de résussite
                                        </div>
                                    </div>
                                    <b className="ppercentage2">{pourcentage}%</b>
                                    <div className="divdisplay-bar2">
                                        <div className="progress-bar1" style={{ width: "100%" }}>
                                            <div className="progress" style={{border:"2px solid #254952"}}>
                                                <div className="progress-bar" role="progressbar" style={{ width: `${pourcentage}%`, backgroundColor: "#254952" }} aria-value={pourcentage} aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="h1title4">{parseInt(project.capacite_watt) - parseInt(project.nb_cellule_sol)} € restants</div>
                                    <div className="ppercentage2">{`Jusqu'au 15 décembre `}</div>
                                </div>
                                <div className="divinvestor-on-it2">
                                    <img
                                        className="imginvestor-avatar-icon2"
                                        alt=""
                                        src="/imginvestor-avatar1@2x.png"
                                    />
                                    <div className="h3open-investment5" style={{ backgroundColor: "#f9d649" }}><b>{project.investors ? project.investors.length : 0}</b></div>
                                    <b className="h3open-investment4">{`Investisseurs `}</b>
                                    <div className="h3open-investment66" style={{ backgroundColor: "#f9d649" }}> <b>reste {daysRemaining} jours</b></div>
                                </div>
                            </div>
                            <div className="divproject-hoster4">
                                <img
                                    className="imgproject-hoster-icon2"
                                    alt=""
                                    src="/empty.png"
                                />
                                <div className="divproject-hoster-info2">
                                    <div className="pproject-hosted-default2">Projet porté par</div>
                                    <b className="pproject-hosted-default2">Rooftop</b>
                                </div>
                                <div className="divproject-hoster-info2">
                                <div className="pproject-hosted-default2">Emplacement du projet</div>
                                <b className="pproject-hosted-default2">{project.adress.commune}</b>
                                </div>
                            </div>
                            <div className="divinterest-rate-and-time11">
                                <div className="divtitle13">
                                    <img
                                        className="imgdouble-right-arrow-icon4"
                                        alt=""
                                        src="/imgdouble-right-arrow1@2x.png"
                                    />
                                    <b className="h3open-investment4">Taux d’intérêt et durée *</b>
                                </div>
                                <div className="divinterest-rate-and-time12">
                                    <img className="imglock-icon2" alt="" src="/imglock1@2x.png" />
                                    <b className="h3open-investment4">
                                        Consulter le taux et la durée
                                    </b>
                                </div>
                            </div>
                            <div className="divinvest-controls2">
                                <div className="divinvest-button-field1 "
                                    style={{ cursor: "pointer" }}>
                                    <div className="buttoninvest-in-btn1 invest-button">
                                        <div className="pinvest-btn1">Voir les risques</div>
                                    </div>
                                </div>
                                <div className="divinvest-button-field1 " onClick={(e) => {
                                    navigate("/frame-314?project=564c90b5-1c46-4743-89cb-b94e640efec2")
                                }}
                                    style={{ cursor: "pointer" }}>
                                    <div className="buttoninvest-in-btn1 invest-button">
                                        <div className="pinvest-btn1">Investir</div>
                                    </div>
                                </div>
                            </div>
                            <a className="tri-taux-container2">
                                <ul className="tri-taux-de-rentabilit-inte2">
                                    <span>{`TRI ( Taux de rentabilité interne ) contractuel ( `}</span>
                                    <span className="voir-fiscalit2">voir fiscalité</span>
                                    <span className="span3">{` ) `}</span>
                                </ul>
                            </a>
                        </div>
                    </div>
                    <div className="contentshare2">
                        <div className="pproject-hosted-default2">Partager</div>
                        <div className="groupshare-icons2">
                            <img
                                className="capture-decran-2023-07-27-a6"
                                alt=""
                                src="/capture-decran-20230727-a-19343@2x.png"
                            />
                            <img
                                className="capture-decran-2023-07-27-a7"
                                alt=""
                                src="/capture-decran-20230727-a-19344@2x.png"
                            />
                            <img
                                className="capture-decran-2023-07-27-a7"
                                alt=""
                                src="/capture-decran-20230727-a-19345@2x.png"
                            />
                        </div>
                    </div>
                </div>
            }
            <div className="investment-details2">
                <div className="pinvestment-warning2">
                    Investir présente un risque de perte en capital et de liquidité.
                    N’investissez que l’argent dont vous n’avez pas besoin immédiatement
                    et diversifiez votre épargne.
                </div>
                <b className="h1title5">
                    <p className="de-hot-spot-wifi">Détails de l'investissement</p>
                </b>
                <div className="divcompartive-tab5">
                    <div className="column1caracteristics2">
                        <div className="divtitle15">
                            <b className="pcolumn-title9">Caractéristiques</b>
                        </div>
                        <div className="divaccess-field5">
                            <div className="paccessible6">Accessible</div>
                        </div>
                        <div className="divaccess-field5">
                            <div className="paccessible6">Taux d’intérêt et durée *</div>
                        </div>
                        <div className="divaccess-field5">
                            <div className="paccessible6">Remaining</div>
                        </div>
                        <div className="divaccess-field5">
                            <div className="paccessible6">Investissement min</div>
                        </div>
                        <div className="divaccess-field5">
                            <div className="paccessible6">Investissement max</div>
                        </div>
                        <div className="divaccess-field5">
                            <div className="paccessible6">Valeur unitaire</div>
                        </div>
                        <div className="divaccess-field5">
                            <div className="paccessible6">Participant</div>
                        </div>
                    </div>
                    <div className="column1caracteristics2">
                        <div className="divtitle15">
                            <b className="pcolumn-title9">Simulateur</b>
                        </div>
                        <div className="divaccess-field6">
                            <div className="paccessible6">Accessible</div>
                        </div>
                        <div className="divaccess-field6">
                            <div className="paccessible6">Taux d’intérêt et durée *</div>
                        </div>
                        <div className="divaccess-field6">
                            <div className="paccessible6">Remaining</div>
                        </div>
                        <div className="divaccess-field6">
                            <div className="paccessible6">Investissement min</div>
                        </div>
                        <div className="divaccess-field6">
                            <div className="paccessible6">Investissement max</div>
                        </div>
                        <div className="divaccess-field6">
                            <div className="paccessible6">Valeur unitaire</div>
                        </div>
                        <div className="divaccess-field6">
                            <div className="paccessible6">Participant</div>
                        </div>
                    </div>
                    <div className="column3investment-details2">
                        <div className="row0who-can-invest2">
                            <div className="divtitle15">
                                <b className="pcolumn-title9">Qui peut investir</b>
                            </div>
                            <div className="divdate-and-place-info2">
                                <div className="pdate-and-place-info4">
                                    <p className="de-hot-spot-wifi">
                                        À partir du jeudi 16 février 2023 à 12h30
                                    </p>
                                    <ul className="tri-taux-de-rentabilit-inte2">
                                        <li>
                                            Investissement ouvert aux habitant·es et collectivités des
                                            départements 16 - 17 - 24 - 33 - 79 - 85
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="divaccess-field6">
                                <div className="paccessible6">Taux d’intérêt et durée *</div>
                            </div>
                            <div className="divaccess-field6">
                                <div className="paccessible6">Remaining</div>
                            </div>
                            <div className="divaccess-field6">
                                <div className="paccessible6">Investissement min</div>
                            </div>
                            <div className="divaccess-field6">
                                <div className="paccessible6">Investissement max</div>
                            </div>
                            <div className="divaccess-field6">
                                <div className="paccessible6">Valeur unitaire</div>
                            </div>
                            <div className="divaccess-field6">
                                <div className="paccessible6">Participant</div>
                            </div>
                        </div>
                        <div className="row1end-of-investment2">
                            <div className="divtitle15">
                                <b className="pcolumn-title9">Date de fin</b>
                            </div>
                            <div className="divaccess-field5">
                                <b className="pdate-and-place-info4">31 juillet 2023 à 23h59</b>
                            </div>
                            <div className="divend-date-info2">
                                <div className="pend-date-info2">
                                    La date de fin de collecte pourra être prolongée à la demande
                                    du porteur de projet.Le rendement de votre investissement sera
                                    calculé à partir de la date de génération des certificats de
                                    souscription, c'est-à-dire une fois l'intégralité des fonds
                                    reçus et la documentation juridique signée par le porteur de
                                    projet.
                                </div>
                            </div>
                            <div className="divsecondary-house-info2">
                                <div className="pend-date-info2">
                                    Si vous avez une résidence secondaire ou principale dans l'une
                                    de ces zones, vous pouvez investir en renseignant un
                                    justificatif de domicile.
                                </div>
                            </div>
                            <div className="divaccess-field6">
                                <div className="paccessible6">Investissement min</div>
                            </div>
                            <div className="divaccess-field6">
                                <div className="paccessible6">Investissement max</div>
                            </div>
                            <div className="divaccess-field6">
                                <div className="paccessible6">Valeur unitaire</div>
                            </div>
                            <div className="divaccess-field6">
                                <div className="paccessible6">Participant</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="divproject-hoster-details2">
                <div className="navbarproject-nav-bar2">
                    <div className="buttonbutton-nav-bar-the-proj2">
                        <b className="pproject-hosted-default2">{`Le projet `}</b>
                        <div className="divider2" />
                    </div>
                    <div className="buttonbutton-nav-bar-project-2">
                        <b className="pproject-hosted-default2">Le porteur de projet</b>
                    </div>
                    <div className="buttonbutton-nav-bar-project-2">
                        <b className="pproject-hosted-default2">Actualités</b>
                    </div>
                    <div className="buttonbutton-nav-bar-project-2">
                        <b className="pproject-hosted-default2">Commentaires</b>
                    </div>
                    <button className="buttonbutton-be-call-back2" id="btn_etre_rappele">
                        <b className="pbe-call-back2">{`Etre rappelé `}</b>
                    </button>
                    <button className="buttonbutton-doc2" id="btn_documentation">
                        <img
                            className="imgicon-download2"
                            alt=""
                            src="/imgicon-download1@2x.png"
                        />
                        <b className="pbe-call-back2">Documentation</b>
                    </button>
                    <button className="buttonbutton-invest2" id="btn_investir" 
                      onClick={(e) => {
                        navigate("/frame-314?project=564c90b5-1c46-4743-89cb-b94e640efec2")
                    }} >
                        <b className="p-invest2">Investir</b>
                    </button>
                </div>
                <div className="divessential-info2">
                    <div className="divessential-description2">
                        <div className="l-essentiel2">L ‘ESSENTIEL</div>
                        <div className="un-centre-de2">{`Un centre de formation , basé sur toulon, sur les nouvelles technologie pour les jeunes de 10-14 ans ( Collège et 15-18 ans  (lycée ) souhaite produire l’énergie électrique qu’elle consomme. Pour ce faire, une mini-centrale de production via des panneaux solaires serait mis en place grâce à un financement participatif. Au lieu, d’un classique emprunt, l’idée est de proposer à chacun des apprenants de devenir actionnaire de cette mini-centrale électrique. Chaque action, d’un euro, correspondra à un actif d’une Unité de compte solaire (UCS ).  `}</div>
                        <div className="un-centre-de2">La mini</div>
                    </div>
                    <div className="divproject-hoster5">
                        <div className="divfield-simulateur2">
                            <div className="psimulator2">Simulateur</div>
                        </div>
                        <div className="divfield-simulateur2">
                            <div className="psimulator2">{`Consulter le taux et la durée `}</div>
                        </div>
                        <div className="div-project-hoster-details2">
                            <div className="pproject-hoster-name5">
                                <p className="le-porteur2">{`LE PORTEUR `}</p>
                                <p className="de-hot-spot-wifi">DE PROJET</p>
                            </div>
                            <img
                                className="imgproject-hoster-img-icon2"
                                alt=""
                                src="/empty.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footerfooter2">
                <div className="container2">
                    <div className="col12">
                        <b className="summer-school-20232">
                            <p className="de-hot-spot-wifi">E+C- :</p>
                            <p className="de-hot-spot-wifi">Investisser</p>
                            <p className="de-hot-spot-wifi">Autrement</p>
                        </b>
                    </div>
                    <div className="col22">
                        <b className="investissement-solaire2">Investissement solaire</b>
                        <div className="microproduction-pour-un-container2">
                            <p className="de-hot-spot-wifi">
                                Microproduction pour un centre de formation : Cible, 590 € ,
                                réalisé à 83%
                            </p>
                            <p className="de-hot-spot-wifi">&nbsp;</p>
                            <p className="de-hot-spot-wifi">
                                Créer un hot spot wifi partout alimenté via le solaire: cible
                                4900 € réalisé à 58%
                            </p>
                            <p className="de-hot-spot-wifi">&nbsp;</p>
                            <p className="de-hot-spot-wifi">
                                MInicentrale 4,8 KWc avec support aluminium reconverti: cible
                                4800 €
                            </p>
                            <p className="de-hot-spot-wifi">
                                <b>&nbsp;</b>
                            </p>
                            <p className="de-hot-spot-wifi">&nbsp;</p>
                            <p className="de-hot-spot-wifi">&nbsp;</p>
                            <p className="de-hot-spot-wifi">&nbsp;</p>
                        </div>
                    </div>
                    <div className="col32">
                        <b className="investissement-solaire2">{`Soutiens & Partenaires`}</b>
                        <b className="investissement-solaire2">Rentrée 2023</b>
                        <b className="investissement-solaire2">Nous rejoindre</b>
                        <b className="investissement-solaire2">Notre Approche</b>
                        <b className="investissement-solaire2">Contact</b>
                        <b className="investissement-solaire2">CGV</b>
                    </div>
                </div>
                <div className="textdroit2">©2023 E+C- | Tout droits reservés</div>
            </footer>
            {isDrawerMenuDrawerItemPopupOpen && (
                <PortalPopup
                    overlayColor="rgba(113, 113, 113, 0.3)"
                    placement="Top center"
                    onOutsideClick={closeDrawerMenuDrawerItemPopup}
                >
                    <DrawerMenuDrawerItem onClose={closeDrawerMenuDrawerItemPopup} />
                </PortalPopup>
            )}
        </div>
    );
};

export default Rooftop;
