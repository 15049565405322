import "./WebMainInvestmentProjectLi.css";
import DrawerMenuDrawerItem from "../components/DrawerMenuDrawerItem";
import { useNavigate } from "react-router-dom";
import React, { useState, useCallback, useEffect } from "react";
import PopupinfoNetworkType from "../components/PopupinfoNetworkType";
import PortalPopup from "../components/PortalPopup";
import { apiNodeService } from "../provider/apiNodeService";
import InfoIcon from '@mui/icons-material/Info';
import FooterMobile from "../components/FooterMobile";
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DrawerMenuMobile from "../components/DrawerMenuMobile";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import MuiBackdrop from "../components/Backdrop/MuiBackdrop";
import HeaderMobile from "../components/HeaderMobile";
import FooterDesktop from "../components/FooterDesktop";
import HeaderDesktop from "../components/HeaderDesktop";

const categorie_project = [
  "Alimentation",
  "Sylviculture",
  "Tourisme",
  "Batîments écologiques",
  "Apiculture",
  "L’économie bleu",
  "Énergies renouvelables",
  "Transport & logistique"
]

const WebMainInvestmentProjectLi = () => {

  const navigate = useNavigate();

  const [isDrawerMenuDrawerItemPopupOpen, setDrawerMenuDrawerItemPopupOpen] = useState(false);  
 
  const [isDrawerMenuMobileOpen, setDrawerMenuMobileOpen] = useState(false);
  
  const [isFuturDisplayed, setIsFuturDisplayed] = useState(true);
  const [isAlimentation, setIsAlimentation] = useState(false);
  const [isCategorie2, setIsCategorie2] = useState(false);
  const [isCategorie3, setIsCategorie3] = useState(false);
  const [isCategorie4, setIsCategorie4] = useState(false);
  const [isCategorie5, setIsCategorie5] = useState(false);
  const [isCategorie6, setIsCategorie6] = useState(false);
  const [isCategorie7, setIsCategorie7] = useState(false);
  const [isCategorie8, setIsCategorie8] = useState(false);
  const [projects, setProjects] = useState([])
  const [loading, setLoading] = React.useState(false);
  const [categorieSelected, setCategorieSelected] = useState([])

  useEffect(() => {
    setLoading(true)
    apiNodeService.getAllProjects().then(res => {

      if (res && res.status === 200) {
        console.log(res.data)
        setProjects(res.data)
       
        setLoading(false)
      }
    })
    
  }, []);

  const openDrawerMenuDrawerItemPopup = useCallback(() => {
    setDrawerMenuDrawerItemPopupOpen(true);
  }, []);

  const closeDrawerMenuDrawerItemPopup = useCallback(() => {
    setDrawerMenuDrawerItemPopupOpen(false);
  }, []);

  const onChoixProjetTreeporteur = useCallback((project) => {
    navigate("/hotspot-wifi-solaire?project=" + project);
  }, [navigate]);

  

  

  const openDrawerMenuMobile = useCallback(() => {
    setDrawerMenuMobileOpen(true);
  }, []);

  const closeDrawerMenuMobile = useCallback(() => {
    setDrawerMenuMobileOpen(false);
  }, []);

  const addItem = (newItem) => {
    setCategorieSelected([...categorieSelected, newItem]);
  };

  // Function to delete an element from the array
  const deleteItem = (itemToDelete) => {
    const updatedItems = categorieSelected.filter(item => item !== itemToDelete);
    setCategorieSelected(updatedItems);
  };

  return (
    <>
      <MuiBackdrop
        open={loading}
      />
      <div className="web-main-investment-project-li">
        <HeaderDesktop />
        <Fab onClick={(e) => { navigate('/newproject') }} style={{ position: "fixed", bottom: 10, right: 2, backgroundColor: "#477520", color: "#FFF" }} aria-label="add">
          <AddIcon fontSize="large" />
        </Fab>
        <div className="sectionsearch-and-investment-">

          <div className="divinvestment-list px-4">
            <div className="d-flex justify-content-start align-items-center" onClick={(e) => { navigate("/") }} style={{ gap: 20, cursor: 'pointer', maxWidth: 300 }}>
              <ArrowBackIcon />
              <span className="span-back">Revenir à la page d’accueil</span>
            </div>

            <div className="categorie-section mb-4">
              <div className="mt-4 px-4 mb-3">
                <span className="title-categorie">Catégories</span>
              </div>
              <div className="d-flex flex-wrap justify-content-center p-2 px-4" style={{ gap: 20 }}>
                <div
                  onClick={(e)=>{navigate('/categorie?cat=Alimentation')}}
                  onMouseOver={(e) => { setIsAlimentation(true) }}
                  onMouseOut={(e) => { setIsAlimentation(false) }}
                  className="card-categorie d-flex align-items-center justify-content-center p-2">
                  {isAlimentation === false && <span className="span-title-cat">Alimentation</span>}
                  {isAlimentation === true &&
                    <div className="d-flex flex-column" style={{ textAlign: 'center' }}>
                      <span className="span-title-cat">Alimentation</span>
                      <p className="parag-categorie">Projet pilote "Transformation des systèmes alimentaires" en agroécologie à l'échelle locale (Flandre), nationale (Belgique) et mondiale, visant à contrer la perte de biodiversité et à promouvoir la durabilité...</p>
                      <div className="d-flex justify-content-center align-items-center" style={{ gap: 10 }}>
                        <span className="span-savoir-plus">En savoir plus</span>
                        <ArrowForwardIosIcon style={{ color: "#fff" }} fontSize="small" />
                      </div>
                    </div>
                  }
                </div>
                <div
                onClick={(e)=>{navigate('/categorie?cat=Sylviculture')}}
                  onMouseOver={(e) => { setIsCategorie2(true) }}
                  onMouseOut={(e) => { setIsCategorie2(false) }}
                  className="card-categorie1 d-flex align-items-center justify-content-center p-2">
                  {isCategorie2 === false && <span className="span-title-cat">Sylviculture</span>}
                  {isCategorie2 === true &&
                    <div className="d-flex flex-column" style={{ textAlign: 'center' }}>
                      <span className="span-title-cat">Sylviculture</span>
                      <p className="parag-categorie">Projet pilote "Assurer les pratiques NP dans la transition d'insertion carbone dans le secteur forestier", visant à promouvoir des approches de NP via l'insertion dans les chaînes de valeur...</p>
                      <div className="d-flex justify-content-center align-items-center" style={{ gap: 10 }}>
                        <span className="span-savoir-plus">En savoir plus</span>
                        <ArrowForwardIosIcon style={{ color: "#fff" }} fontSize="small" />
                      </div>
                    </div>
                  }
                </div>
                <div
                onClick={(e)=>{navigate('/categorie?cat=Tourisme')}}
                  onMouseOver={(e) => { setIsCategorie3(true) }}
                  onMouseOut={(e) => { setIsCategorie3(false) }}
                  className="card-categorie3 d-flex align-items-center justify-content-center p-2">
                  {isCategorie3 === false && <span className="span-title-cat">Tourisme</span>}
                  {isCategorie3 === true &&
                    <div className="d-flex flex-column" style={{ textAlign: 'center' }}>
                      <span className="span-title-cat">Tourisme</span>
                      <p className="parag-categorie">Projet pilote "Destination touristique naturellement positive à Valsugana, Italie", visant à promouvoir des approches de NP dans le secteur du tourisme durable ou naturellement positif...</p>
                      <div className="d-flex justify-content-center align-items-center" style={{ gap: 10 }}>
                        <span className="span-savoir-plus">En savoir plus</span>
                        <ArrowForwardIosIcon style={{ color: "#fff" }} fontSize="small" />
                      </div>
                    </div>
                  }
                </div>

                <div
                onClick={(e)=>{navigate('/categorie?cat=Apiculture')}}
                  onMouseOver={(e) => { setIsCategorie4(true) }}
                  onMouseOut={(e) => { setIsCategorie4(false) }}
                  className="card-categorie4 d-flex align-items-center justify-content-center p-2">
                  {isCategorie4 === false && <span className="span-title-cat">Apiculture</span>}
                  {isCategorie4 === true &&
                    <div className="d-flex flex-column" style={{ textAlign: 'center' }}>
                      <span className="span-title-cat">Apiculture</span>
                      <p className="parag-categorie">Projet pilote "Apiculture pour le développement économique local", visant à promouvoir l'apiculture et l'agriculture régénérative dans la région métropolitaine de la vallée d'Aburrá en Colombie...</p>
                      <div className="d-flex justify-content-center align-items-center" style={{ gap: 10 }}>
                        <span className="span-savoir-plus">En savoir plus</span>
                        <ArrowForwardIosIcon style={{ color: "#fff" }} fontSize="small" />
                      </div>
                    </div>
                  }
                </div>

                <div
                 onClick={(e)=>{navigate('/categorie?cat=L’économie bleu')}}
                  onMouseOver={(e) => { setIsCategorie5(true) }}
                  onMouseOut={(e) => { setIsCategorie5(false) }}
                  className="card-categorie5 d-flex align-items-center justify-content-center p-2">
                  {isCategorie5 === false && <span className="span-title-cat">L’économie bleu</span>}
                  {isCategorie5 === true &&
                    <div className="d-flex flex-column" style={{ textAlign: 'center' }}>
                      <span className="span-title-cat">L’économie bleu</span>
                      <p className="parag-categorie">Projet pilote "Intégration des indicateurs NP, des politiques et des soutiens pour l'entrepreneuriat et l'investissement innovants basés sur la nature", centré sur l'aquaculture régénérative...</p>
                      <div className="d-flex justify-content-center align-items-center" style={{ gap: 10 }}>
                        <span className="span-savoir-plus">En savoir plus</span>
                        <ArrowForwardIosIcon style={{ color: "#fff" }} fontSize="small" />
                      </div>
                    </div>
                  }
                </div>

                <div
                onClick={(e)=>{navigate('/categorie?cat=Batîments écologiques')}}
                  onMouseOver={(e) => { setIsCategorie6(true) }}
                  onMouseOut={(e) => { setIsCategorie6(false) }}
                  className="card-categorie6 d-flex align-items-center justify-content-center p-2">
                  {isCategorie6 === false && <span className="span-title-cat" style={{ textAlign: 'center' }}>Batîments écologiques</span>}
                  {isCategorie6 === true &&
                    <div className="d-flex flex-column" style={{ textAlign: 'center' }}>
                      <span className="span-title-cat" style={{ fontSize: 20 }}>Batîments écologiques</span>
                      <p className="parag-categorie">Projet pilote "Quantification du potentiel NP des Bâtiments Verts", axé sur les bâtiments écologiques en Autriche, en Slovaquie et au Portugal, avec une portée élargie à l'échelle européenne...</p>
                      <div className="d-flex justify-content-center align-items-center" style={{ gap: 10 }}>
                        <span className="span-savoir-plus">En savoir plus</span>
                        <ArrowForwardIosIcon style={{ color: "#fff" }} fontSize="small" />
                      </div>
                    </div>
                  }
                </div>
                <div
                onClick={(e)=>{navigate('/categorie?cat=Énergies renouvelables')}}
                  onMouseOver={(e) => { setIsCategorie7(true) }}
                  onMouseOut={(e) => { setIsCategorie7(false) }}
                  className="card-categorie7 d-flex align-items-center justify-content-center p-2">
                  {isCategorie7 === false && <span className="span-title-cat" style={{ textAlign: 'center' }}>Énergies renouvelables</span>}
                  {isCategorie7 === true &&
                    <div className="d-flex flex-column" style={{ textAlign: 'center' }}>
                      <span className="span-title-cat" style={{ fontSize: 20 }}>Énergies renouvelables</span>
                      <p className="parag-categorie">Projet pilote "Évaluation du potentiel NP des énergies renouvelables", visant à évaluer l'impact positif sur la nature des énergies renouvelables en Europe...</p>
                      <div className="d-flex justify-content-center align-items-center" style={{ gap: 10 }}>
                        <span className="span-savoir-plus">En savoir plus</span>
                        <ArrowForwardIosIcon style={{ color: "#fff" }} fontSize="small" />
                      </div>
                    </div>
                  }
                </div>
                <div
                onClick={(e)=>{navigate('/categorie?cat=Transport & Logistique')}}
                  onMouseOver={(e) => { setIsCategorie8(true) }}
                  onMouseOut={(e) => { setIsCategorie8(false) }}
                  className="card-categorie8 d-flex align-items-center justify-content-center p-2">
                  {isCategorie8 === false && <span className="span-title-cat" style={{ textAlign: 'center' }}>Transport & Logistique</span>}
                  {isCategorie8 === true &&
                    <div className="d-flex flex-column" style={{ textAlign: 'center' }}>
                      <span className="span-title-cat" style={{ fontSize: 20 }}>Transport & Logistique</span>
                      <p className="parag-categorie">Projet pilote "Évaluation du potentiel NP dans le secteur des transports et de la logistique", focalisé sur les pratiques durables et l'impact environnemental positif en Europe...</p>
                      <div className="d-flex justify-content-center align-items-center" style={{ gap: 10 }}>
                        <span className="span-savoir-plus">En savoir plus</span>
                        <ArrowForwardIosIcon style={{ color: "#fff" }} fontSize="small" />
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>

            <div className="projet-section d-flex flex-wrap justify-content-center py-2" style={{ gap: 20 }}>
              {projects.map((item, key) => (
                <div key={key} onClick={(e) => { onChoixProjetTreeporteur(item.id) }} className="card-projet-desktop mt-3">
                  <div className="projet-description-top1 d-flex align-items-start flex-column">

                  </div>
                  <div className="projet-description-bottom d-flex align-items-start flex-column py-2">
                    <div className="d-flex align-items-start flex-column" style={{ height: 155 }}>
                      <span className="span-card-project-d2 mt-1 px-3">{item.title ? item.title : "Hot spot mobile alimenté au solaire"}</span>
                      <span className="span-card-project-3 mt-2 px-3">par : <b>{item.porteur ? item.porteur : "Treeporteur"}</b> </span>
                    </div>
                    <div className="d-flex justify-content-between px-3" style={{ width: "100%" }}>
                      <div className="d-flex flex-column">
                        <span style={{ fontWeight: 600 }}>{parseInt(item.investors.length)}</span>
                        <span style={{ fontSize: 14 }}>investisseurs</span>
                      </div>
                      <div className="d-flex flex-column">
                        <span style={{ fontWeight: 600 }}>{item.date_fin ? moment(item.date_fin).diff(moment(), 'days') : 213} jours</span>
                        <span style={{ fontSize: 14 }}>restants</span>
                      </div>
                      <div className="d-flex flex-column">
                        <span style={{ fontWeight: 600 }}>{parseInt(item.nb_watt_used)} €</span>
                        <span style={{ fontSize: 14, fontWeight: 500 }}>sur {parseInt(item.nb_cellule_sol * 4)} €</span>
                      </div>
                    </div>
                    {/* <div className="d-flex justify-content-between mt-2 px-3" style={{ width: "100%" }}>
                    <span style={{ color: "#188F87", fontSize: 20, fontWeight: 500 }}>{((parseInt(item.nb_watt_used) / parseInt(item.nb_cellule_sol * 4)) * 100).toFixed(2)} %</span>
                    {creativeProject && <span style={{ fontSize: 20, fontWeight: 500 }}>{parseInt(item.nb_cellule_sol * 4)} €</span>}
                  </div> */}
                    <div className="d-flex align-items-center mt-2 mb-2" style={{ width: "100%" }}>
                      <div className="progress-bar1 px-2" style={{ width: "82%" }}>
                        <div className="progress" style={{ border: "1px solid #254952", height: 7 }}>
                          <div className="progress-bar" role="progressbar" style={{ width: `${(parseInt(item.nb_watt_used) / parseInt(item.nb_cellule_sol * 4)) * 100}%`, backgroundColor: "#1EB2A9" }} aria-value={(parseInt(item.nb_watt_used) / parseInt(item.nb_cellule_sol * 4)) * 100} aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                      <span style={{ color: "#616161", fontSize: 14, fontWeight: 600 }}>{((parseInt(item.nb_watt_used) / parseInt(item.nb_cellule_sol * 4)) * 100).toFixed(2)} %</span>
                    </div>

                  </div>
                  {/* <div className="d-flex justify-content-center mb-2">
                  <button onClick={(e) => { onChoixProjetTreeporteur(item.id) }} className="btn-choix-projet-mobile">
                    Voir le projet
                  </button>
                </div> */}
                </div>
              ))
              }
              <div className="card-projet-desktop mt-4">
                <div className="projet-description-top6 d-flex align-items-start flex-column">

                </div>
                <div className="projet-description-bottom d-flex align-items-start flex-column mb-3">
                  <div className="d-flex align-items-start flex-column" style={{ height: 155 }}>
                    <span className="span-card-project-d2 mt-1 px-3">Centrale photovoltaïque sur sol de 20 kW</span>
                    <span className="span-card-project-3 mt-2 px-3">par : <b>Bali School</b> </span>
                  </div>
                  <div className="d-flex justify-content-between px-3" style={{ width: "100%" }}>
                    <div className="d-flex flex-column">
                      <span style={{ fontWeight: 600 }}>0</span>
                      <span style={{ fontSize: 14 }}>investisseurs</span>
                    </div>
                    <div className="d-flex flex-column">
                      <span style={{ fontWeight: 600 }}>442 jours</span>
                      <span style={{ fontSize: 14 }}>restants</span>
                    </div>
                    <div className="d-flex flex-column">
                      <span style={{ fontWeight: 600 }}>0 €</span>
                      <span style={{ fontSize: 14, fontWeight: 500 }}>sur 20 000 €</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-2" style={{ width: "100%" }}>
                    <div className="progress-bar1 px-2" style={{ width: "82%" }}>
                      <div className="progress" style={{ border: "1px solid #254952", height: 7 }}>
                        <div className="progress-bar" role="progressbar" style={{ width: `${0}%`, backgroundColor: "#1EB2A9" }} aria-value={0} aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <span style={{ color: "#616161", fontSize: 14, fontWeight: 600 }}>0 %</span>
                  </div>
                </div>
              </div>

              <div className="card-projet-desktop mt-4">
                <div className="projet-description-top6 d-flex align-items-start flex-column">

                </div>
                <div className="projet-description-bottom d-flex align-items-start flex-column mb-3">
                  <div className="d-flex align-items-start flex-column" style={{ height: 155 }}>
                    <span className="span-card-project-d2 mt-1 px-3">Pompage solaire sur 100 kW</span>
                    <span className="span-card-project-3 mt-2 px-3">par : <b>Someflu</b> </span>
                  </div>
                  <div className="d-flex justify-content-between px-3" style={{ width: "100%" }}>
                    <div className="d-flex flex-column">
                      <span style={{ fontWeight: 600 }}>0</span>
                      <span style={{ fontSize: 14 }}>investisseurs</span>
                    </div>
                    <div className="d-flex flex-column">
                      <span style={{ fontWeight: 600 }}>442 jours</span>
                      <span style={{ fontSize: 14 }}>restants</span>
                    </div>
                    <div className="d-flex flex-column">
                      <span style={{ fontWeight: 600 }}>0 €</span>
                      <span style={{ fontSize: 14, fontWeight: 500 }}>sur 20 000 €</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-2" style={{ width: "100%" }}>
                    <div className="progress-bar1 px-2" style={{ width: "82%" }}>
                      <div className="progress" style={{ border: "1px solid #254952", height: 7 }}>
                        <div className="progress-bar" role="progressbar" style={{ width: `${0}%`, backgroundColor: "#1EB2A9" }} aria-value={0} aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <span style={{ color: "#616161", fontSize: 14, fontWeight: 600 }}>0 %</span>
                  </div>
                </div>
              </div>

              <div className="card-projet-desktop mt-4">
                <div className="projet-description-top7 d-flex align-items-start flex-column">

                </div>
                <div className="projet-description-bottom d-flex align-items-start flex-column mb-3">
                  <div className="d-flex align-items-start flex-column" style={{ height: 155 }}>
                    <span className="span-card-project-d2 mt-1 px-3">120 PV sur château sur 48 kW</span>
                    <span className="span-card-project-3 mt-2 px-3">par : <b>Quinsac Solar</b> </span>
                  </div>
                  <div className="d-flex justify-content-between px-3" style={{ width: "100%" }}>
                    <div className="d-flex flex-column">
                      <span style={{ fontWeight: 600 }}>4</span>
                      <span style={{ fontSize: 14 }}>investisseurs</span>
                    </div>
                    <div className="d-flex flex-column">
                      <span style={{ fontWeight: 600 }}>0 jours</span>
                      <span style={{ fontSize: 14 }}>restants</span>
                    </div>
                    <div className="d-flex flex-column">
                      <span style={{ fontWeight: 600 }}>113 000 €</span>
                      <span style={{ fontSize: 14, fontWeight: 500 }}>sur 113 000 €</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-2" style={{ width: "100%" }}>
                    <div className="progress-bar1 px-2" style={{ width: "82%" }}>
                      <div className="progress" style={{ border: "1px solid #254952", height: 7 }}>
                        <div className="progress-bar" role="progressbar" style={{ width: `${100}%`, backgroundColor: "#1EB2A9" }} aria-value={100} aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <span style={{ color: "#616161", fontSize: 14, fontWeight: 600 }}>100 %</span>
                  </div>
                </div>
              </div>

            </div>


          </div>

        </div>
        <FooterDesktop />
        {isDrawerMenuDrawerItemPopupOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Top center"
            onOutsideClick={closeDrawerMenuDrawerItemPopup}
          >
            <DrawerMenuDrawerItem onClose={closeDrawerMenuDrawerItemPopup} />
          </PortalPopup>
        )}
      </div>
      <div className="web-invest-mobile" style={{ marginTop: 80, marginBottom: 100 }}>
        <HeaderMobile isHome={false} title={"Liste des projets"} />

        <Fab onClick={(e) => { navigate('/newproject') }} style={{ position: "fixed", bottom: 90, right: 2, backgroundColor: "#477520", color: "#FFF" }} aria-label="add">
          <AddIcon fontSize="large" />
        </Fab>
        <div className="d-flex flex-column p-3">

          <div className="title-mobile-project"><span>{`Tous nos projets`}</span></div>

          {/* <Paper
            className="mt-4"
            component="form"
            sx={{ p: '2px 4px', display: 'flex', border: "none", alignItems: 'center', width: 330, borderRadius: 25, backgroundColor: "#d1e9e77d" }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              className="input-serach-project"
              placeholder="Entrez un nom de projet"
              inputProps={{ 'aria-label': 'Entrez un nom de projet' }}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon fontSize="large" style={{ color: "#000" }} />
            </IconButton>
          </Paper> */}

          {/* <span className="span-categorie mt-4 px-3">Catégories</span> */}

          <div className="d-flex align-items-center mt-1">
            <Checkbox
              size="large"
              
              checked={isFuturDisplayed}
              sx={{
                color: "#477520",
                '&.Mui-checked': {
                  color: "#477520",
                },
              }}
              onChange={(e) => {
                console.log(e.target.checked)
                setIsFuturDisplayed(e.target.checked)
              }}
            />
            <span>Afficher les projets terminés ou futurs</span>
          </div>

          {/* <div className="d-flex flex-wrap align-items-center mt-2" style={{ gap: 10 }}>

            {categorie_project.map((item, key) => (
              <button key={key} className="btn-select-categorie"
                onClick={(e => {
                  if (categorieSelected.includes(item)) {
                    // If the item exists, delete it
                    deleteItem(item);
                  } else {
                    // If the item doesn't exist, add it
                    addItem(item);
                  }
                })}
                style={{ backgroundColor: categorieSelected.includes(item) === true ? "#1EB2A9" : "#bcd5cb", color: categorieSelected.includes(item) ? "#fff" : "", fontWeight: 600 }}>{item}</button>
            ))}
          </div> */}

          <span className="title-encours mt-4">En cours</span>

          <div className="cards-list-project pb-3">
            {projects.map((item, key) => (
              <div key={key} onClick={(e) => { onChoixProjetTreeporteur(item.id) }} className="card-projet-mobile mt-3" style={{ marginLeft: key > 0 ? 25 : 5 }}>
                <div className="projet-description-top d-flex align-items-start flex-column">
                  <span className="projet-name-mobile mt-auto mb-4 ml-2">{item.porteur ? item.porteur : "Treeporteur"} <br /> <span className="projet-title-mobile">{item.type_action ? item.type_action : "Obligations convertibles"}</span> </span>

                </div>
                <div className="middle-section-card d-flex justify-content-between">
                  <div className="date-project pt-1 px-3">
                    <span className="span-date-project">Se termine le {item.date_fin ? moment(item.date_fin).format("DD/MM/YYYY") : "30/11/2024"}</span>
                  </div>
                </div>
                <div className="projet-description-bottom d-flex align-items-start flex-column py-2">
                  {item.type_projet === "Transport & Logistique" ? <img src="/transport_et_logistique.svg" /> : item.type_projet === "Sylviculture" ? <img src="/sylviculture.svg" /> : item.type_projet === "Tourisme" ? <img src="/tourisme.svg" /> : item.type_projet === "Apiculture" ? <img src="/apiculture.svg" /> : item.type_projet === "L’économie bleu" ? <img src="/economie_bleu.svg" /> : item.type_projet === "Batîments écologiques" ? <img src="/batiments_ecologiques.svg" /> : item.type_projet === "Énergies renouvelables" ? <img src="/energies_renouvelables.svg" /> : <img src="/alimentation.svg" />}
                  {/* <span className="span-card-project-1 mt-2">{item.type_projet ? item.type_projet : "Photovoltaïque au sol"}</span> */}
                  <span className="span-card-project-2 mt-3 px-3">{item.title ? item.title : "Hot spot mobile alimenté au solaire"}</span>
                  <span className="span-card-project-3 mt-3 px-3">Intérêts : {item.taux ? item.taux : "5"} %</span>
                  <div className="d-flex justify-content-between mt-2 px-3" style={{ width: "100%" }}>
                    <span style={{ color: "#477520", fontSize: 20, fontWeight: 500 }}>{((parseInt(item.nb_watt_used) / parseInt(item.nb_cellule_sol * 4)) * 100).toFixed(2)} %</span>
                    <span style={{ fontSize: 20, fontWeight: 500 }}>{parseInt(item.nb_cellule_sol * 4)} €</span>
                  </div>
                  <div className="progress-bar1 mt-1 px-3" style={{ width: "100%" }}>
                    <div className="progress" style={{ border: "1px solid #254952", height: 7 }}>
                      <div className="progress-bar" role="progressbar" style={{ width: `${(parseInt(item.nb_watt_used) / parseInt(item.nb_cellule_sol * 4)) * 100}%`, backgroundColor: "#477520" }} aria-value={(parseInt(item.nb_watt_used) / parseInt(item.nb_cellule_sol * 4)) * 100} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div className="d-flex flex-column p-3">
                    {(item.impact && item.impact.length > 0) ?
                      <>
                        {item.impact.map((item,key)=>(
                          <span style={{ fontSize: 16, color: '#477520' }} key={key}>{item.label}</span>
                        ))}
                      </>
                      :
                      <>
                        <span style={{ fontSize: 16, color: '#477520' }}>Conservation de la nature</span>
                        <span style={{ fontSize: 16, color: '#477520' }}>Santé et bien-être</span>
                      </>
                    }
                  </div>
                  <div className="d-flex mt-3 px-3 mb-3">
                    <img src="/clock.svg" />
                    <span style={{ fontSize: 16, color: '#477520', marginLeft: 10 }}>{item.date_fin ? moment(item.date_fin).diff(moment(), 'days') : 213}  Jour(s) restant(s)</span>
                  </div>
                </div>
                <div className="d-flex justify-content-center mb-4">
                  <button onClick={(e) => { onChoixProjetTreeporteur(item.id) }} className="btn-choix-projet-mobile">
                    Voir le projet
                  </button>
                </div>
              </div>
            ))
            }
          </div>

          {isFuturDisplayed === true &&
            <>
              <span className="title-encours mt-4">Prochainement</span>

              <div className="cards-list-project pb-3">
                <div className="card-projet-mobile mt-3" style={{ marginLeft: 5 }}>
                  <div className="projet-description-top d-flex align-items-start flex-column">
                    <span className="projet-name-mobile mt-auto mb-4 ml-2">Bali School <br /> <span className="projet-title-mobile">Obligations simples</span> </span>

                  </div>
                  <div className="middle-section-card d-flex justify-content-between">
                    <div className="date-project-prochain pt-1 px-3">
                      <span className="span-date-project-prochain">Ouvre le 7/11/2023</span>
                    </div>
                  </div>
                  <div className="projet-description-bottom mb-3 d-flex align-items-start flex-column">
                    {/* <span className="span-card-project-1 mt-2">Photovoltaïque au sol</span> */}
                    <span className="span-card-project-2 mt-3 px-3">Autoconsommation solaire sur 20kW</span>
                    <span className="span-card-project-3 mt-3 px-3">Intérêts : 8%/an sur 3 ans</span>
                    <div className="d-flex justify-content-between mt-2 px-3" style={{ width: "100%" }}>
                      <span style={{ color: "#477520", fontSize: 20, fontWeight: 500 }}>{0} %</span>
                      <span style={{ fontSize: 20, fontWeight: 500 }}>{20000} €</span>
                    </div>
                    <div className="progress-bar1 mt-1 px-3" style={{ width: "100%" }}>
                      <div className="progress" style={{ border: "1px solid #254952", height: 7 }}>
                        <div className="progress-bar" role="progressbar" style={{ width: `${0}%`, backgroundColor: "#477520" }} aria-value={0} aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex mt-3 px-3 mb-3">
                    <img src="/clock.svg" />
                    <span style={{ fontSize: 16, color: '#477520', marginLeft: 10 }}>Commence prochainement</span>
                  </div>
                </div>

                <div className="card-projet-mobile mt-4" style={{ marginLeft: 25 }}>
                  <div className="projet-description-top d-flex align-items-start flex-column">
                    <span className="projet-name-mobile mt-auto mb-4 ml-2">Someflu <br /> <span className="projet-title-mobile">Obligations simples</span> </span>

                  </div>
                  <div className="middle-section-card d-flex justify-content-between">
                    <div className="date-project-prochain pt-1 px-3">
                      <span className="span-date-project-prochain mt-2">Ouvre le 5/11/2023</span>
                    </div>
                  </div>
                  <div className="projet-description-bottom d-flex align-items-start flex-column mb-3">
                    {/* <span className="span-card-project-1 mt-2">Photovoltaïque au sol</span> */}
                    <span className="span-card-project-2 mt-3 px-3">Pompage solaire sur 100 kW</span>
                    <span className="span-card-project-3 mt-3 px-3">Intérêts : 7%/an sur 4 ans</span>
                    <div className="d-flex justify-content-between mt-2 px-3" style={{ width: "100%" }}>
                      <span style={{ color: "#477520", fontSize: 20, fontWeight: 500 }}>{0} %</span>
                      <span style={{ fontSize: 20, fontWeight: 500 }}>{100000} €</span>
                    </div>
                    <div className="progress-bar1 mt-1 px-3" style={{ width: "100%" }}>
                      <div className="progress" style={{ border: "1px solid #254952", height: 7 }}>
                        <div className="progress-bar" role="progressbar" style={{ width: `${0}%`, backgroundColor: "#477520" }} aria-value={0} aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex mt-3 px-3 mb-3">
                    <img src="/clock.svg" />
                    <span style={{ fontSize: 16, color: '#477520', marginLeft: 10 }}>Commence prochainement</span>
                  </div>
                </div>

              </div>
            </>
          }

          {isFuturDisplayed === true &&
            <>
              <span className="title-encours mt-4">Terminés</span>
              <div className="cards-list-project pb-3">
                <div className="card-projet-mobile mt-3" style={{ marginLeft: 5 }}>
                  <div className="projet-description-top d-flex align-items-start flex-column">
                    <span className="projet-name-mobile mt-auto mb-4 ml-2">QUINSAC Solar <br /> <span className="projet-title-mobile">Obligations simples</span> </span>
                  </div>
                  <div className="middle-section-card d-flex justify-content-between">
                    <div className="date-project-prochain pt-1 px-3">
                      <span className="span-date-project-prochain">Terminé le 06/08/2020</span>
                    </div>
                  </div>
                  <div className="projet-description-bottom mb-3 d-flex align-items-start flex-column">
                    <span className="span-card-project-2 mt-3 px-3">120 panneaux sur château sur 48 kW</span>
                    <span className="span-card-project-3 mt-3 px-3">Intérêts : 6%/an sur 2 ans</span>
                    <div className="d-flex justify-content-between mt-2 px-3" style={{ width: "100%" }}>
                      <span style={{ color: "#477520", fontSize: 20, fontWeight: 500 }}>{100} %</span>
                      <span style={{ fontSize: 20, fontWeight: 500 }}>{130000} €</span>
                    </div>
                    <div className="progress-bar1 mt-1 px-3" style={{ width: "100%" }}>
                      <div className="progress" style={{ border: "1px solid #254952", height: 7 }}>
                        <div className="progress-bar" role="progressbar" style={{ width: `${100}%`, backgroundColor: "#477520" }} aria-value={100} aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex mt-3 px-3 mb-3">
                    <img src="/clock.svg" />
                    <span style={{ fontSize: 16, color: '#477520', marginLeft: 10 }}>Terminé</span>
                  </div>
                </div>
              </div>
            </>
          }

        </div>
        <FooterMobile />
      </div>
      {isDrawerMenuMobileOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top center"
          onOutsideClick={closeDrawerMenuMobile}
        >
          <DrawerMenuMobile onClose={closeDrawerMenuMobile} />
        </PortalPopup>
      )}
    </>
  );
};

export default WebMainInvestmentProjectLi;
