const endpoint = process.env.REACT_APP_API

export const apiNodeService = {

    login(data){
        return fetch(endpoint+'/api/bankapp/auth/login', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    loginGreen(data){
        return fetch(endpoint+'/api/green/auth/login', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    resetPasswordGreen(data){
        return fetch(endpoint+'/api/green/auth/resetPassword', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    updatePasswordGreen(data){
        return fetch(endpoint+'/api/green/auth/updatePassword', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    contact(data){
        return fetch(endpoint+'/api/contact', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    coursRegister(data){
        return fetch(endpoint+'/api/course/register', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    register(data){
        return fetch(endpoint+'/api/auth/register', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    registerBank(data){
        return fetch(endpoint+'/api/bankapp/auth/register', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    registerGreen(data){
        return fetch(endpoint+'/api/green/auth/register', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    verifEmailBank(data){
        return fetch(endpoint+'/api/bankapp/auth/verifEmail', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    achatUnit(data){
        return fetch(endpoint+'/api/bankapp/creditAccount', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    transferUnit(data){
        return fetch(endpoint+'/api/bankapp/transfer', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    getAllUsers(){
        return fetch(endpoint+'/api/bankapp/allUsers', {
            method: 'GET',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    getAllProjects(){
        return fetch(endpoint+'/api/bankapp/invest/projects', {
            method: 'GET',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    getProjectById(id){
        return fetch(endpoint+'/api/bankapp/invest/project/'+id, {
            method: 'GET',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    investSolar(id,data){
        return fetch(endpoint+'/api/bankapp/invest/project/'+id, {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    getHistory(data){
        return fetch(endpoint+'/api/bankapp/userHistory', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    emailProcess(data){
        return fetch(endpoint+'/api/bankapp/invest/beginMailProcess', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    resetPassword(data){
        return fetch(endpoint+'/api/bankapp/auth/resetPassword', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    updatePassword(data){
        return fetch(endpoint+'/api/bankapp/auth/updatePassword', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    filterTable(data){
        return fetch(endpoint+'/api/rethink/filterTableData', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    getTableData(data){
        return fetch(endpoint+'/api/rethink/getTableData', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    insertTableData(data){
        return fetch(endpoint+'/api/rethink/insertTableRow', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    updateTableData(data){
        return fetch(endpoint+'/api/rethink/updateTableRow', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
};
