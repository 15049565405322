import React, { useState, useCallback, useEffect } from "react";
import DrawerMenuDrawerItem from "../components/DrawerMenuDrawerItem";
import PortalPopup from "../components/PortalPopup";
import "./FrameComponent1.css";
import MuiBackdrop from "../components/Backdrop/MuiBackdrop";
import FooterMobile from "../components/FooterMobile";
import { useNavigate } from "react-router-dom";

const FrameChat = () => {

  const navigate = useNavigate();

  const [isDrawerMenuDrawerItemPopupOpen, setDrawerMenuDrawerItemPopupOpen] =
    useState(false);

  const [loading, setLoading] = React.useState(false);
  const [projetId, setProjetId] = React.useState('');

  useEffect(() => {
    if (!localStorage.getItem("email") || !localStorage.getItem("first_name")) {
      navigate('/signin?page=frame-chat')
    } else {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 3000)
    }
  }, []);

  const openDrawerMenuDrawerItemPopup = useCallback(() => {
    setDrawerMenuDrawerItemPopupOpen(true);
  }, []);

  const closeDrawerMenuDrawerItemPopup = useCallback(() => {
    setDrawerMenuDrawerItemPopupOpen(false);
  }, []);

  return (
    <>
      <div className="frame-chat">
        <MuiBackdrop
          open={loading}
        />
        <header className="headerheader2">
          <div className="logo3">
            <b className="creative-minds3">E+C-</b>
          </div>
          <div className="menuitems3">
            <div className="links2">
              <div className="summer-school-20232">PILOTE summer 2023</div>
              <div className="links-child3" />
              <div className="summer-school-20232">
                Investir à partir de 1 €
              </div>
              <div className="links-child3" />
              <div className="summer-school-20232">Nous rejoindre</div>
              <div className="links-child3" />
              <div className="summer-school-20232">Se connecter</div>
            </div>
            <div
              className="hamburgeritem2"
              onClick={openDrawerMenuDrawerItemPopup}
            >
              <div className="iconcontainer2">
                <img className="icon2" alt="" src="/icon16.svg" />
              </div>
            </div>
          </div>
        </header>
        <div className="frame-child">
          <iframe className="resize-iframe frame-chat-margin" width={"100%"} src={"https://whatsusfront.vercel.app?email="+localStorage.getItem("email")}></iframe>
        </div>
        <footer className="footer2">
          <div className="container2">
            <div className="col12">
              <b className="creative-minds-container2">
                <p className="ec-2">E+C- :</p>
                <p className="ec-2">Investisser</p>
                <p className="ec-2">Autrement</p>
              </b>
            </div>
            <div className="col22">
              <b className="stages-vacances2">Investissement solaire</b>
              <div className="chatgpt-mid-container2">
                <p className="ec-2">France : Toulon (Association formation )</p>
                <p className="ec-2"> 900 €</p>
                <p className="ec-2">&nbsp;</p>
                <p className="ec-2">{`France : Toulon  (association  formation `}</p>
                <p className="ec-2">3500 €</p>
                <p className="ec-2">&nbsp;</p>
                <p className="ec-2">{`France : Seyne sur Mer ( usine . ) `}</p>
                <p className="ec-2">10 000 €</p>
                <p className="ec-2">&nbsp;</p>
                <p className="ec-2">Bali : Ubud ( Coworking CoLiving )</p>
                <p className="ec-2">45 000 €</p>
                <p className="ec-2">&nbsp;</p>
                <p className="ec-2">&nbsp;</p>
              </div>
            </div>
            <div className="col32">
              <div className="col-4column2">
                <b className="soutiens-partenaires2">{`Soutiens & Partenaires`}</b>
                <b className="rentre-20235">Rentrée 2023</b>
                <b className="rentre-20235">Nous rejoindre</b>
                <b className="rentre-20235">Notre Approche</b>
                <b className="rentre-20235">Contact</b>
                <b className="rentre-20235">CGV</b>
              </div>
            </div>
          </div>
          <div className="textdroit2">©2023 E+C- | Tout droits reservés</div>
        </footer>
        <FooterMobile />
      </div>
      {isDrawerMenuDrawerItemPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top center"
          onOutsideClick={closeDrawerMenuDrawerItemPopup}
        >
          <DrawerMenuDrawerItem onClose={closeDrawerMenuDrawerItemPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default FrameChat;
