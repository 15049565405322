import "./Profile.css"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from "react-router-dom";
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { apiNodeService } from "../provider/apiNodeService";
import moment from 'moment';
import FooterMobile from "../components/FooterMobile";
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import Autocomplete from '@mui/material/Autocomplete';
import countries from "../data/countries"
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import utilFunctions from "../tools/functions";
import MuiBackdrop from "../components/Backdrop/MuiBackdrop";

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 35,
    height: 35,
    border: `2px solid ${theme.palette.background.paper}`,
}));


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 13,
        fontWeight: 500,
        maxWidth: 450,
    },
}));

const Profile = () => {

    const navigate = useNavigate();

    const [projects, setProjects] = useState([]);
    const [userTypePorteur, setUserTypePorteur] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [prenom, setPrenom] = React.useState('');
    const [nom, setNom] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [ville, setVille] = React.useState('');
    const [facebook, setFacebook] = React.useState('');
    const [linkedin, setLinkedin] = React.useState('');
    const [gmail, setGmail] = React.useState('');
    const [twitter, setTwitter] = React.useState('');
    const [whatsapp, setWhatsapp] = React.useState('');
    const [naissance, setNaissance] = React.useState('')
    const [codep, setCodep] = React.useState("");
    const [adress, setAdress] = React.useState("");
    const [adressCompl, setAdressCompl] = React.useState("");
    const [pays, setPays] = React.useState(null);
    const [userType, setUserType] = React.useState('');
    const [user, setUser] = React.useState(null)
    const [photo, setPhoto] = React.useState(null)
    const [denomination, setDenomination] = React.useState("");
    const [formJuridique, setFormJuridique] = React.useState("");
    const [siret, setSiret] = React.useState("");
    const [numTva, setNumTva] = React.useState("");
    const [dateCreationJuridique, setDateCreationJuridique] = React.useState('');
    const [adressJuridique, setAdressJuridique] = React.useState("");
    const [complAdressJuridique, setComplAdressJuridique] = React.useState("");
    const [codepJuridique, setCodepJuridique] = React.useState("");
    const [villeJuridique, setVilleJuridique] = React.useState("");
    const [paysJuridique, setPaysJuridique] = React.useState(null);
    const [paysJuridiqueP, setPaysJuridiqueP] = React.useState("");
    const fileInputRef = useRef(null);
    const [editing1, setEditing1] = useState(false);
    const [job, setJob] = React.useState("");
    const [editing, setEditing] = useState(false);
    const [description, setDescription] = useState("");

    useEffect(() => {
        if (!localStorage.getItem("email") || !localStorage.getItem("first_name")) {
            navigate('/signin?page=profile')
        } else {
            getUserByEmail(localStorage.getItem("email"))
            apiNodeService.getAllProjects().then(res => {
                if (res && res.status === 200) {
                    let lstproject = res.data.filter(element => element.id === "b093efff-b1ba-4da3-9072-27a0c0200243")
                    setProjects(lstproject)
                }
            })

        }

    }, []);

    const handleToggleEdit1 = () => {
        setEditing1(!editing1);
    };

    const handleToggleEdit = () => {
        setEditing(!editing);
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleTextChange1 = (event) => {
        setJob(event.target.value);
        updateProfile("job_title", event.target.value, "green_user")
    };

    const handleBlur1 = () => {
        setEditing1(false);
    };

    const handleTextChange = (event) => {
        setDescription(event.target.value);
        updateProfile("description", event.target.value, "green_user")
    };

    const handleBlur = () => {
        setEditing(false);
    };

    const getUserByEmail = async (email) => {
        setLoading(true)
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "green_user", filter: { email: email }, db: "CREATIVE_MINDS" }).then(userRes => {
                console.log(userRes)
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    if (!userRes.data[0]) {
                        // let data = { email: email }
                        // apiNodeService.insertTableData({ db: "CREATIVE_MINDS", table: "green_user", data: data }).then(resAdd => {
                        //     console.log(resAdd)
                        //     if (resAdd && resAdd.status === 200) {
                        //         setUser(data)
                        //         setLoading(false)
                        //     } else {
                        //         console.log('false')
                        //     }
                        // })
                        console.log('im here hh')
                        navigate('/signin')
                    } else {
                        setUser(userRes.data[0])
                        console.log(userRes.data[0].first_name)
                        setNom(userRes.data[0].last_name ? userRes.data[0].last_name : "")
                        setPhoto(userRes.data[0].photo ? userRes.data[0].photo : null)
                        setPrenom(userRes.data[0].first_name ? userRes.data[0].first_name : '')
                        setNaissance(dayjs(userRes.data[0].naissance) || "")
                        setMobile(userRes.data[0].mobile ? userRes.data[0].mobile : "")
                        setPays(userRes.data[0].pays ? userRes.data[0].pays : "")
                        setVille(userRes.data[0].ville ? userRes.data[0].ville : "")
                        setCodep(userRes.data[0].codep ? userRes.data[0].codep : "")
                        setAdress(userRes.data[0].adress ? userRes.data[0].adress : "")
                        setAdressCompl(userRes.data[0].adress_complet ? userRes.data[0].adress_complet : "")
                        setFacebook(userRes.data[0].facebook ? userRes.data[0].facebook : '')
                        setLinkedin(userRes.data[0].linkedin ? userRes.data[0].linkedin : '')
                        setWhatsapp(userRes.data[0].whatsapp ? userRes.data[0].whatsapp : '')
                        setTwitter(userRes.data[0].twitter ? userRes.data[0].twitter : '')
                        setGmail(userRes.data[0].gmail ? userRes.data[0].gmail : userRes.data[0].email)
                        setUserType(userRes.data[0].user_type ? userRes.data[0].user_type : "Une personne physique")
                        setDenomination(userRes.data[0].denomination ? userRes.data[0].denomination : '')
                        setSiret(userRes.data[0].siret ? userRes.data[0].siret : '')
                        setFormJuridique(userRes.data[0].form_juridique ? userRes.data[0].form_juridique : '')
                        setNumTva(userRes.data[0].num_tva ? userRes.data[0].num_tva : '')
                        setDateCreationJuridique(userRes.data[0].date_creation_juridique ? dayjs(userRes.data[0].date_creation_juridique) : '')
                        setPaysJuridiqueP(userRes.data[0].pays_juridique ? userRes.data[0].pays_juridique : '')
                        setAdressJuridique(userRes.data[0].adress_juridique ? userRes.data[0].adress_juridique : '')
                        setComplAdressJuridique(userRes.data[0].compl_adress_juridique ? userRes.data[0].compl_adress_juridique : '')
                        setCodepJuridique(userRes.data[0].codep_juridique ? userRes.data[0].codep_juridique : '')
                        setVilleJuridique(userRes.data[0].ville_juridique ? userRes.data[0].ville_juridique : '')
                        
                        setJob(userRes.data[0].job_title ? userRes.data[0].job_title : "")
                        setDescription(userRes.data[0].description ? userRes.data[0].description : "")

                        setLoading(false)
                    }
                } else {
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    const handleFileChangeUpdated = async (event) => {
        const file = event.target.files[0];
        if (file) {
            let thumbnail = await utilFunctions.compressImage(file, 0.4, 0.4, 300, 300, true)
            setPhoto(thumbnail);
            updateProfile('photo', thumbnail, "green_user")
            localStorage.setItem("photo", thumbnail.data);
        } else {
            console.log("No file selected!");
        }
    };

    const updateProfile = (element, value, table) => {
        let data = {}
        data[element] = value
        apiNodeService.updateTableData({ db: "CREATIVE_MINDS", table: table, id: user.id, data: data }).then(resAdd => {
            console.log(resAdd)

        })
    }

    return (
        <>
        <MuiBackdrop
        open={loading}
      />
            {(localStorage.getItem("email") && localStorage.getItem("first_name")) &&
                <div className="profile-page p-1">
                    <header className="headerheader-mobile">
                        <div style={{ width: "100%" }} className="d-flex  justify-content-between align-items-center">
                            <KeyboardBackspaceIcon style={{ cursor: 'pointer' }} fontSize="large" onClick={(e) => { navigate(-1) }} />
                            <span className="span-list-projet-mobile">Mon profil</span>
                            <SettingsIcon className="" fontSize="large" onClick={(e) => { navigate("/setting") }} />
                        </div>
                    </header>

                    <div className="first-section-profil d-flex flex-column align-items-center">
                        <div className="row activity-pro p-2">
                            <div className="col-6 p-2">
                                <div className="card-invest-home p-3 mt-3">
                                    <div className="d-flex flex-column">
                                        <span className="span-activity-profil">3</span>
                                        <span className="span-activity-profil1">Projets publiés</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 p-2">
                                <div className="card-invest-home p-3 mt-3">
                                    <div className="d-flex flex-column">
                                        <span className="span-activity-profil">120</span>
                                        <span className="span-activity-profil1">Étoiles récoltées</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 p-2">
                                <div className="card-invest-home p-3 mt-3">
                                    <div className="d-flex flex-column">
                                        <span className="span-activity-profil">9</span>
                                        <span className="span-activity-profil1">Investissements</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 p-2">
                                <div className="card-invest-home p-3 mt-3">
                                    <div className="d-flex flex-column">
                                        <span className="span-activity-profil">3</span>
                                        <span className="span-activity-profil1">Années avec nous</span>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                    <div className="second-section-profil py-4 mt-4">
                        <span className="span-reussite">Mes achèvements</span>
                        <div className="d-flex justify-content-center mt-4" style={{ gap: 10 }}>
                            <div className="d-flex flex-column image-reussite align-items-center" style={{ width: 80, backgroundColor: "#fff" }}>
                                <img width={80} alt="R" src="/reussiteA.svg" />
                                <span style={{ textAlign: "center", fontSize: 12, fontWeight: 600 }}>1er projet lancé</span>
                            </div>
                            <div className="d-flex flex-column image-reussite" style={{ width: 80 }}>
                                <img width={80} alt="R" src="/reussiteB.svg" />
                                <span style={{ textAlign: "center", fontSize: 12, fontWeight: 600 }}>5 projets lancés</span>
                            </div>
                            <div className="d-flex flex-column image-reussite" style={{ width: 80 }}>
                                <img width={80} alt="R" src="/reussiteC.svg" />
                                <span style={{ textAlign: "center", fontSize: 12, fontWeight: 600 }}>5 placements réalisés</span>
                            </div>
                            <div className="d-flex flex-column image-reussite" style={{ width: 80 }}>
                                <img width={80} alt="R" src="/reussiteD.svg" />
                                <span style={{ textAlign: "center", fontSize: 12, fontWeight: 600 }}>1er projet financé</span>
                            </div>
                        </div>
                    </div>

                    <div className="p-1 mt-4">
                        <span className="span-reussite">Mes détails</span>
                        <div className='p-2 shadow-profil' style={{ backgroundColor: '#fff' }}>
                            <div className="p-2 d-flex align-items-center mb-4" style={{ gap: 20 }}>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileChangeUpdated}
                                />
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <div className="update-avatar">
                                            <SmallAvatar onClick={handleButtonClick} style={{ backgroundColor: "#fff", border: "2px solid green", cursor: 'pointer' }} alt="Remy Sharp" src="/updateprofile.svg" />
                                        </div>
                                    }
                                >
                                    {photo ?
                                        <Avatar
                                            alt="Remy Sharp"
                                            className="avatar-profil"
                                            src={photo.prefix + photo.data}
                                            style={{border:"2px solid green"}}
                                        />
                                        : <Avatar className="avatar-profil"  style={{border:"2px solid green"}}>
                                            {localStorage.getItem('first_name')[0]}
                                        </Avatar>
                                    }
                                </Badge>
                                <div className="d-flex flex-column">
                                    <span>{prenom} {nom}</span>
                                    <div className='mb-2'>
                                        {editing1 ? (

                                            <TextField
                                                multiline
                                                size='small'
                                                value={job}
                                                onChange={handleTextChange1}
                                                onBlur={handleBlur1}
                                                autoFocus
                                            />
                                        ) : (
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <p className="mb-0" style={{ fontFamily: 'Montserrat' }}>{job === "" ? "Titre du job" : job}</p>
                                                <IconButton onClick={handleToggleEdit1}>
                                                    <EditIcon fontSize='small' color='success' />
                                                </IconButton>


                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column align-items-center mb-3 mt-3">
                                <div style={{width:"95%",height:1,backgroundColor:"#477520"}}/>
                                <div className='mt-4 mb-' style={{width:"100%"}}>
                                        {editing ? (
                                            <div className='d-flex flex-column' style={{gap:5}}>
                                                <span style={{fontSize:20,fontFamily:"Roboto",fontWeight:600}}>À propos</span>

                                                <TextField
                                                    multiline
                                                    size='small'
                                                    fullWidth
                                                    value={description}
                                                    onChange={handleTextChange}
                                                    onBlur={handleBlur}
                                                    autoFocus
                                                />
                                            </div>
                                        ) : (
                                            <div className='d-flex flex-column'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <span style={{fontSize:20,fontFamily:"Roboto",fontWeight:600}}>À propos</span>
                                                    <IconButton onClick={handleToggleEdit}>
                                                        <EditIcon fontSize='small' color='success' />
                                                    </IconButton>
                                                </div>

                                                <p style={{ fontFamily: 'Roboto',fontSize:20 }}>{description === "" ? "Entrez une brève description de vous même et de votre façon de travailler" : description}</p>

                                            </div>
                                        )}
                                    </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <span className="span-title">Linkedin</span>
                                    <FormControl fullWidth variant="outlined">
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            size="small"
                                            variant="filled"
                                            value={linkedin}
                                            onChange={(e) => {
                                                setLinkedin(e.target.value)
                                                updateProfile('linkedin', e.target.value, "green_user")
                                            }}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        edge="start"
                                                    >
                                                        <img src="/linkedin1.png" />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </div>

                            </div>

                            <div className='row mt-2'>
                                <div className='col-md-12'>
                                    <span className="span-title">Facebook</span>
                                    <FormControl fullWidth variant="outlined">
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            size="small"
                                            variant="filled"
                                            value={facebook}
                                            onChange={(e) => {
                                                setFacebook(e.target.value)
                                                updateProfile('facebook', e.target.value, "green_user")
                                            }}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        edge="start"
                                                    >
                                                        <img src="/facb.png" />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </div>

                            </div>

                            <div className='row mt-2'>
                                <div className='col-md-12'>
                                    <span className="span-title">Twitter</span>
                                    <FormControl fullWidth variant="outlined">
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            size="small"
                                            variant="filled"
                                            value={twitter}
                                            onChange={(e) => {
                                                setTwitter(e.target.value)
                                                updateProfile('twitter', e.target.value, "green_user")
                                            }}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        edge="start"
                                                    >
                                                        <img src="/twitter.png" />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </div>

                            </div>

                            <div className='row mt-2'>
                                <div className='col-md-12'>
                                    <span className="span-title">Whatsapp</span>
                                    <FormControl fullWidth variant="outlined">
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            size="small"
                                            variant="filled"
                                            value={whatsapp}
                                            onChange={(e) => {
                                                setWhatsapp(e.target.value)
                                                updateProfile('whatsapp', e.target.value, "green_user")
                                            }}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        edge="start"
                                                    >
                                                        <img src="/whatsapp.png" />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </div>

                            </div>

                            <div className='row mt-2'>
                                <div className='col-md-12'>
                                    <span className="span-title">Gmail</span>
                                    <FormControl fullWidth variant="outlined">
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            size="small"
                                            variant="filled"
                                            value={gmail}
                                            onChange={(e) => {
                                                setGmail(e.target.value)
                                                updateProfile('gmail', e.target.value, "green_user")
                                            }}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        edge="start"
                                                    >
                                                        <img src="/gmail.png" />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div className='p-2 shadow-profil' style={{ backgroundColor: '#fff' }}>

                            <div className='d-flex align-items-center' style={{ gap: 15 }}>
                                <span className='mt-1' style={{ color: 'grey', fontWeight: 700 }}>* = Champs obligatoires</span>

                            </div>


                            <>
                                <div className='row mt-3'>
                                    <div className='col-6'>
                                        <span className="span-title mb-1">Nom de famille*</span>
                                        <TextField fullWidth size="small" id="outlined-basic" variant="outlined"
                                            value={nom}
                                            onChange={(e) => {
                                                setNom(e.target.value)
                                                updateProfile("last_name", e.target.value, "green_user")
                                            }} />
                                    </div>
                                    <div className='col-6'>
                                        <span className="span-title mb-1">Prénom*</span>
                                        <TextField fullWidth size="small" id="outlined-basic1" variant="outlined"
                                            value={prenom}
                                            onChange={(e) => {
                                                setPrenom(e.target.value)
                                                updateProfile("first_name", e.target.value, "green_user")

                                            }} />
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-md-6 mt-1'>
                                        <span className="span-title">Téléphone</span>
                                        <TextField fullWidth className='mt-2' size="small" id="outlined-basic3" variant="outlined"
                                            value={mobile}
                                            placeholder='+33 6 XX XX XX XX'
                                            onChange={(e) => {
                                                setMobile(e.target.value)
                                                updateProfile("mobile", e.target.value, "green_user")
                                            }} />
                                    </div>
                                    <div className='col-md-6 mt-1 date-section1'>
                                        <span className="span-title">Date de naissance*</span>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                                <DatePicker format="DD/MM/YYYY" value={dayjs(naissance)} onChange={(e) => {
                                                    if (e) {
                                                        setNaissance(moment(e.$d).format('YYYY-MM-DD'))
                                                        updateProfile("naissance", moment(e.$d).format('YYYY-MM-DD'), "green_user")
                                                    }

                                                }} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                </div>

                                <div className='row mt-2 mb-2'>
                                    <div className='col-6'>
                                        <span className="span-title mb-1">Adresse*</span>
                                        <TextField fullWidth size="small" id="outlined-basic10" variant="outlined"
                                            value={adress}
                                            onChange={(e) => {
                                                setAdress(e.target.value)
                                                updateProfile("adress", e.target.value, "green_user")
                                            }} />
                                    </div>
                                    <div className='col-6'>
                                        <span className="span-title mb-1">Complément d’adresse</span>
                                        <TextField fullWidth size="small" id="outlined-basic10" variant="outlined"
                                            value={adressCompl}
                                            onChange={(e) => {
                                                setAdressCompl(e.target.value)
                                                updateProfile("adress_complet", e.target.value, "green_user")
                                            }} />
                                    </div>

                                </div>

                                <div className='row'>

                                    <div className='col-6'>
                                        <span className="span-title mb-1">Code postal*</span>
                                        <TextField fullWidth size="small" id="outlined-basic10" variant="outlined"
                                            value={codep}
                                            onChange={(e) => {
                                                setCodep(e.target.value)
                                                updateProfile("codep", e.target.value, "green_user")
                                            }} />
                                    </div>

                                    <div className='col-6'>
                                        <span className="span-title mb-1">Ville*</span>
                                        <TextField fullWidth size="small" id="outlined-basic11" variant="outlined"
                                            value={ville}
                                            onChange={(e) => {
                                                setVille(e.target.value)
                                                updateProfile("ville", e.target.value, "green_user")
                                            }} />
                                    </div>
                                </div>

                                <div className='row mt-2'>
                                    <div className='col-md-6'>
                                        <span className="span-title mb-1">Pays*</span>
                                        <Autocomplete
                                            id="country-select-demo"
                                            options={countries.countries}
                                            className="countries-autocomplete"
                                            value={pays ? pays : null}
                                            onChange={(e, selected) => {
                                                if (selected) {
                                                    setPays(selected.label)
                                                    updateProfile("pays", selected, "green_user")
                                                }
                                            }}
                                            autoHighlight
                                            getOptionLabel={(option) => option.label}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    <img
                                                        loading="lazy"
                                                        width="20"
                                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                        alt=""
                                                    />
                                                    {option.label}
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                        />

                                    </div>
                                    <div className='col-md-6'>
                                        <span className="span-title">je suis*</span>
                                        <div className='d-flex align-items-center' style={{ gap: 10 }}>
                                            <Select
                                                className='mt-1'
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={userType}
                                                style={{ height: 40 }}
                                                size="small"
                                                onChange={(e) => {
                                                    setUserType(e.target.value)
                                                    updateProfile("user_type", e.target.value, "green_user")
                                                }}
                                                fullWidth
                                            >
                                                <MenuItem value={"Une personne physique"}>Une personne physique</MenuItem>
                                                <MenuItem value={"Une personne morale"}>Une personne morale</MenuItem>
                                            </Select>
                                            <LightTooltip style={{ backgroundColor: '#fff' }} title="Une personne morale est une entité juridique distincte de ses membres, telle qu'une entreprise ou une association, avec des droits et des responsabilités propres. En revanche, une personne physique est un individu humain, avec une identité légale et des droits personnels.">
                                                <InfoOutlinedIcon className="mt-1" style={{ cursor: 'pointer' }} color="primary" />
                                            </LightTooltip>
                                        </div>
                                    </div>
                                </div>
                            </>

                        </div>

                        <div className="p-1 mt-4">
                            <span className="span-reussite">Mes informations juridiques</span>
                            <div className='p-2 shadow-profil mt-2' style={{ backgroundColor: '#fff', marginBottom: 90 }}>
                                <>
                                    <div className='d-flex align-items-center' style={{ gap: 15 }}>
                                        <span className='mt-1' style={{ color: 'grey', fontWeight: 700 }}>* = Champs obligatoires</span>

                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-md-6'>
                                            <span className="span-title mb-1">Dénomination sociale*</span>
                                            <TextField fullWidth size="small" id="outlined-basic" variant="outlined"
                                                value={denomination}
                                                onChange={(e) => {
                                                    setDenomination(e.target.value)
                                                    updateProfile("denomination", e.target.value, "green_user")
                                                }} />
                                        </div>
                                        <div className='col-md-6'>
                                            <span className="span-title mb-1">Forme juridique*</span>
                                            <TextField fullWidth size="small" id="outlined-basic1" variant="outlined"
                                                value={formJuridique}
                                                onChange={(e) => {
                                                    setFormJuridique(e.target.value)
                                                    updateProfile("form_juridique", e.target.value, "green_user")

                                                }} />
                                        </div>
                                    </div>


                                    <div className='row mt-2'>
                                        <div className='col-6'>
                                            <span className="span-title">SIRET*</span>
                                            <TextField fullWidth className='mt-1' size="small" id="outlined-basic3" variant="outlined"
                                                value={siret}
                                                onChange={(e) => {
                                                    setSiret(e.target.value)
                                                    updateProfile("siret", e.target.value, "green_user")
                                                }} />
                                        </div>
                                        <div className='col-6'>
                                            <span className="span-title mb-1">Numéro de TVA</span>
                                            <TextField className='mt-1' fullWidth size="small" id="outlined-basic10" variant="outlined"
                                                value={numTva}
                                                onChange={(e) => {
                                                    setNumTva(e.target.value)
                                                    updateProfile("num_tva", e.target.value, "green_user")
                                                }} />
                                        </div>
                                    </div>

                                    <div className='row mb-1'>

                                        <div className='col-md-6 date-section1'>
                                            <span className="span-title">Date de création*</span>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker']}>
                                                    <DatePicker format="DD/MM/YYYY" value={dayjs(dateCreationJuridique)} onChange={(e) => {
                                                        console.log(e)
                                                        if (e) {
                                                            setDateCreationJuridique(moment(e.$d).format('YYYY-MM-DD'))
                                                            updateProfile("date_creation_juridique", moment(e.$d).format('YYYY-MM-DD'), "green_user")
                                                        }

                                                    }} />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>
                                        <div className='col-md-6'>
                                            <span className="span-title mb-1">Pays*</span>
                                            <Autocomplete
                                                id="country-select-demo1"
                                                options={countries.countries}
                                                className="countries-autocomplete mt-2"
                                                value={paysJuridiqueP ? paysJuridiqueP : null}
                                                onChange={(e, selected) => {
                                                    if (selected) {
                                                        setPaysJuridique(selected)
                                                        updateProfile("pays_juridique", selected, "green_user")
                                                    }

                                                }}
                                                autoHighlight
                                                getOptionLabel={(option) => option.label}
                                                renderOption={(props, option) => (
                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                        <img
                                                            loading="lazy"
                                                            width="20"
                                                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                            alt=""
                                                        />
                                                        {option.label}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>

                                    </div>

                                    <div className='row'>

                                        <div className='col-6'>
                                            <span className="span-title mb-1">Adresse*</span>
                                            <TextField fullWidth size="small" id="outlined-basic10" variant="outlined"
                                                value={adressJuridique}
                                                onChange={(e) => {
                                                    setAdressJuridique(e.target.value)
                                                    updateProfile("adress_juridique", e.target.value, "green_user")
                                                }} />
                                        </div>

                                        <div className='col-6'>
                                            <span className="span-title mb-1" style={{ fontSize: 15 }}>Complément d’adresse</span>
                                            <TextField fullWidth size="small" id="outlined-basic11" variant="outlined"
                                                value={complAdressJuridique}
                                                onChange={(e) => {
                                                    setComplAdressJuridique(e.target.value)
                                                    updateProfile("compl_adress_juridique", e.target.value, "green_user")
                                                }} />
                                        </div>
                                    </div>

                                    <div className='row mt-2'>
                                        <div className='col-6'>
                                            <span className="span-title mb-1">Code postal*</span>
                                            <TextField fullWidth size="small" id="outlined-basic11" variant="outlined"
                                                value={codepJuridique}
                                                onChange={(e) => {
                                                    setCodepJuridique(e.target.value)
                                                    updateProfile("codep_juridique", e.target.value, "green_user")
                                                }} />
                                        </div>

                                        <div className='col-6'>
                                            <span className="span-title mb-1">Ville*</span>
                                            <TextField fullWidth size="small" id="outlined-basic11" variant="outlined"
                                                value={villeJuridique}
                                                onChange={(e) => {
                                                    setVilleJuridique(e.target.value)
                                                    updateProfile("ville_juridique", e.target.value, "green_user")
                                                }} />
                                        </div>
                                    </div>

                                </>
                            </div>
                        </div>
                    </div>

                    <FooterMobile />
                </div>
            }
        </>
    )
}

export default Profile;