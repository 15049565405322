
const FooterDesktop = () => {

    return (
        <footer className="footer">
          <div className="d-flex flex-column px-4">
            <img width={50} height={32} src="/greenlogofooter.svg" />
            <p style={{ fontSize: 18 }}>Un compte, un espoir</p>
          </div>
          <div className="row align-items-start p-1 px-4" style={{ width: "100%" }}>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <p style={{ fontSize: 26, fontWeight: 700, marginTop: 10 }} className="investisseurs mb-3">Investissements en cours</p>
                <p style={{ fontSize: 18 }}>Microproduction pour centre de formation - 100 € restants</p>
                <p style={{ fontSize: 18 }}>Hot spot WiFi mobile solaire - 2 058 € restants</p>
                <p style={{ fontSize: 18 }}>Minicentrale 4.8 kWc pompe - 4 800 € restants</p>


                <div className="d-flex mt-4" style={{ gap: 50 }}>
                  <img src="/facebookfooter.png" />
                  <img src="/twitterfooter.png" />
                  <img src="/instagramfooter.png" />
                  <img src="/linkedinfooter.png" />
                  <img src="/youtubefooter.png" />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <p style={{ fontSize: 26, fontWeight: 700, marginTop: 10 }} className="investisseurs">Découvrir GreenCard</p>
              <p style={{ fontSize: 18 }}>Soutiens & partenaires</p>
              <p style={{ fontSize: 18 }}>Nous rejoindre</p>
              <p style={{ fontSize: 18 }}>Notre approche</p>
              <p style={{ fontSize: 18 }}>Nous contacter</p>
            </div>
            <div className="col-md-3">
              <p style={{ fontSize: 26, fontWeight: 700, marginTop: 10 }} className="investisseurs">Mentions légales</p>
              <p style={{ fontSize: 18 }}>Politique de confidentialité</p>
              <p style={{ fontSize: 18 }}>Mentions légales</p>
              <p style={{ fontSize: 18 }}>CGV</p>
            </div>
          </div>
          <p style={{ fontSize: 18, marginTop: 10 }}>©2023-2024  GreenCard | Tout droits reservés</p>
        </footer>
    )
}
export default FooterDesktop;