import React, { useEffect } from "react";
import "./DrawerMenuDrawerItem.css";
import { useLocation, useNavigate } from 'react-router-dom';

const DrawerMenuMobile = ({ onClose }) => {

    const location = useLocation();
    const navigate = useNavigate();

    const [selectedPage, setSelectedPage] = React.useState('/')

    useEffect(() => {
        const currentRoute = location.pathname;
        setSelectedPage(currentRoute)
        const scrollAnimElements = document.querySelectorAll(
            "[data-animate-on-scroll]"
        );
        const observer = new IntersectionObserver(
            (entries) => {
                for (const entry of entries) {
                    if (entry.isIntersecting || entry.intersectionRatio > 0) {
                        const targetElement = entry.target;
                        targetElement.classList.add("animate");
                        observer.unobserve(targetElement);
                    }
                }
            },
            {
                threshold: 0.15,
            }
        );

        for (let i = 0; i < scrollAnimElements.length; i++) {
            observer.observe(scrollAnimElements[i]);
        }

        return () => {
            for (let i = 0; i < scrollAnimElements.length; i++) {
                observer.unobserve(scrollAnimElements[i]);
            }
        };
    }, []);

    const handleSelectedPage = (selected) => {
        setSelectedPage(selected)
    }

    return (
        <div className="drawermenumobile mt-4" data-animate-on-scroll>
            <div className="menuitemsmobile">
                <div className="investir-partir-mobile"
                    style={{ backgroundColor: selectedPage === "/" ? "#477520" : "", color: selectedPage === "/" ? "#fff" : "" }}
                    onClick={(e) => {
                        handleSelectedPage('/')
                        navigate('/')
                    }}
                >Accueil</div>
                <div className="investir-partir-mobile"
                    style={{ backgroundColor: selectedPage === "/web-invest" ? "#477520" : "", color: selectedPage === "/web-invest" ? "#fff" : "" }}
                    onClick={(e) => {
                        handleSelectedPage('/web-invest')
                        navigate('/web-invest')
                    }}
                >Liste des projets</div>
                {/* <div className="investir-partir-mobile">Pilote winter 2023</div> */}
                {(!localStorage.getItem("email") || !localStorage.getItem("first_name")) &&
                    <div className="investir-partir-mobile"
                    onClick={(e) => {
                        handleSelectedPage('/signin')
                        navigate('/signin')
                    }}
                    >Se connecter
                    </div>
                }
                {(localStorage.getItem("email") && localStorage.getItem("first_name")) &&
                    <div className="investir-partir-mobile"
                        onClick={(e) => {
                            handleSelectedPage('/')
                            localStorage.clear()
                            navigate('/')
                        }}>
                        Se déconnecter
                    </div>
                }
            </div>
        </div>
    );
};

export default DrawerMenuMobile;
