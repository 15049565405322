import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import LandingResp from "./pages/LandingResp";
import FrameComponent1 from "./pages/FrameComponent1";
import DcouvrezLesPrincipes from "./pages/DcouvrezLesPrincipes";
import LeCrowdInvesting from "./pages/LeCrowdInvesting";
import { useEffect } from "react";
import MinicentraleAvecSupportAlu from "./pages/MinicentraleAvecSupportAlu";
import HotspotWifiSolaire from "./pages/HotspotWifiSolaire";
import MicroproductionCentreDeForm from "./pages/MicroproductionCentreDeForm";
import WebCofinancementKitCoolMy from "./pages/WebCofinancementKitCoolMy";
import WebMainInvestmentProjectLi from "./pages/WebMainInvestmentProjectLi";
import Quinsac from "./pages/Quinsac";
import Rooftop from "./pages/Rooftop";
import FrameChat from "./pages/FrameChat";
import Profile from "./pages/Profile";
import Setting from "./pages/Setting";
import SettingDetails from "./pages/SettingDetails";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import TreeporteurProjet1 from "./pages/TreeporteurProjet1";
import TreeporteurProjet2 from "./pages/TreeporteurProjet2";
import TreeporteurProjet3 from "./pages/TreeporteurProjet3";
import FrameLearning from "./pages/FrameLearning";
import FrameOdoo from "./pages/FrameOdoo";
import Add_Projet from "./pages/Add_Projet";
import Categorie_Details from "./pages/Categorie_Details";
import Reset_Password from "./pages/Reset_Password";
import Update_Password from "./pages/Update_Password";
import { gapi } from "gapi-script";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
    gapi.load("client:auth2", () => {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
        plugin_name: "chat",
      });
    });
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/frame-314":
        title = "";
        metaDescription = "";
        break;
      case "/dcouvrez-les-principes":
        title = "";
        metaDescription = "";
        break;
      case "/le-crowdinvesting":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<LandingResp />} />
      <Route path="/frame-314" element={<FrameComponent1 />} />
      <Route path="/frame-chat" element={<FrameChat />} />
      <Route path="/odoo" element={<FrameOdoo />} />

      <Route
        path="/dcouvrez-les-principes"
        element={<DcouvrezLesPrincipes />}
      />
      <Route path="/le-crowdinvesting" element={<LeCrowdInvesting />} />
      <Route path="/mini-central" element={<MinicentraleAvecSupportAlu />} />
      <Route path="/hotspot-wifi-solaire" element={<HotspotWifiSolaire />} />
      <Route path="/treeporteur-tuktuk" element={<TreeporteurProjet1 />} />
      <Route path="/treeporteur-homy" element={<TreeporteurProjet2 />} />
      <Route path="/treeporteur-dep" element={<TreeporteurProjet3 />} />
      <Route path="/quinsac" element={<Quinsac />} />
      <Route path="/rooftop" element={<Rooftop />} />
      <Route path="/newproject" element={<Add_Projet />} />
      <Route path="/categorie" element={<Categorie_Details />} />
      <Route
        path="/microproduction-centre-de-formation"
        element={<MicroproductionCentreDeForm />}
      />
      <Route path="/web-confinance" element={<WebCofinancementKitCoolMy />} />
      <Route path="/web-invest" element={<WebMainInvestmentProjectLi />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/setting" element={<Setting />} />
      <Route path="/setting-details" element={<SettingDetails />} />
      <Route path="/signin" element={<Login />} />
      <Route path="/reset-password" element={<Reset_Password />} />
      <Route path="/update_password/:table/:token" element={<Update_Password />} />

      <Route path="/signup" element={<Signup />} />
    </Routes>
  );
}
export default App;
