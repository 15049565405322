import { useState, useCallback } from "react";
import DrawerMenuDrawerItem from "../components/DrawerMenuDrawerItem";
import PortalPopup from "../components/PortalPopup";
import "./LeCrowdInvesting.css";
const LeCrowdInvesting = () => {
  const [isDrawerMenuDrawerItemPopupOpen, setDrawerMenuDrawerItemPopupOpen] =
    useState(false);

  const openDrawerMenuDrawerItemPopup = useCallback(() => {
    setDrawerMenuDrawerItemPopupOpen(true);
  }, []);

  const closeDrawerMenuDrawerItemPopup = useCallback(() => {
    setDrawerMenuDrawerItemPopupOpen(false);
  }, []);

  return (
    <>
      <div className="le-crowdinvesting">
        <header className="headerheader4">
          <div className="logo5">
            <b className="creative-minds5">E+C-</b>
          </div>
          <div className="menuitems5">
            <div className="links4">
              <div className="quest-ce-que-linvestissement">
                PILOTE summer 2023
              </div>
              <div className="links-child9" />
              <div className="quest-ce-que-linvestissement">
                Investir à partir de 10 €
              </div>
              <div className="links-child9" />
              <div className="quest-ce-que-linvestissement">Nous rejoindre</div>
              <div className="links-child9" />
              <div className="quest-ce-que-linvestissement">Se connecter</div>
            </div>
            <div
              className="hamburgeritem4"
              onClick={openDrawerMenuDrawerItemPopup}
            >
              <div className="iconcontainer4">
                <img className="icon4" alt="" src="/icon15.svg" />
              </div>
            </div>
          </div>
        </header>
        <div className="tandis-que-les-solutions-finan-wrapper">
          <b className="tandis-que-les">
            Tandis que les solutions financières alternatives se démocratisent
            de plus en plus en Europe, le financement participatif gagne du
            terrain. Mais en quoi consiste-t-il exactement ? Enrichissez vos
            connaissances et découvrez comment E+C- incorpore le financement
            participatif à son modèle économique."
          </b>
        </div>
        <div className="frame-group">
          <div className="quest-ce-que-linvestissement-parent">
            <b className="quest-ce-que-linvestissement">
              "Qu'est-ce que l'investissement participatif ?
            </b>
            <div className="linvestissement-participatif-container">
              <p className="linvestissement-participatif">
                L'investissement participatif implique qu'un grand nombre
                d'investisseurs financent un projet, chacun contribuant
                financièrement à une petite échelle en fonction du capital total
                nécessaire. Avec le temps, l'investisseur récupère la somme
                versée en plus d'un intérêt conséquent, sous forme de paiement
                en espèces. C'est là que se situe la différence majeure avec le
                financement participatif traditionnel, où l'investisseur reçoit
                habituellement une compensation en nature."
              </p>
              <p className="linvestissement-participatif">&nbsp;</p>
              <p className="linvestissement-participatif">{`"Les secteurs phares de l'investissement participatif comprennent l'immobilier, l'énergie (notamment renouvelable) et les start-ups. `}</p>
              <p className="linvestissement-participatif">&nbsp;</p>
              <p className="linvestissement-participatif">
                En général, les individus ont le choix d'investir soit dans des
                projets spécifiques, soit dans des entreprises, en fonction de
                la plateforme proposée. L'investissement participatif peut aussi
                être un élément constitutif d'un modèle économique, comme le
                démontre l'exemple E+C-."
              </p>
            </div>
          </div>
          <div className="qui-peut-investir-parent">
            <b className="quest-ce-que-linvestissement">
              "Qui peut investir ?"
            </b>
            <div className="linvestissement-participatif-container">
              "Cela dépend essentiellement de la plateforme en question et de
              divers facteurs tels que le pays d'origine et les réglementations
              applicables. Toutefois, le financement participatif est
              généralement une forme d'investissement très accessible,
              caractérisée par des processus simples et transparents ainsi que
              des investissements minimaux peu élevés. Chez E+C- les
              investissements peuvent commencer à partir de 10 €."
            </div>
          </div>
          <div className="quel-rendement-peuvent-esprer-parent">
            <b className="quest-ce-que-linvestissement">
              Quel rendement peuvent espérer les investisseurs ?
            </b>
            <div className="les-retours-sur-container">
              <p className="linvestissement-participatif">
                Les retours sur investissement de 5%, avec un bonus de 0,5% pour
                les investissements effectués la première semaine. Nous
                envisageons d'offrir entre 5 et 7 % d'intérêts sur l'ensemble de
                nos projets, dont la durée moyenne est de 5 ans.
              </p>
              <p className="linvestissement-participatif">&nbsp;</p>
              <p className="linvestissement-participatif">
                Les taux d'intérêt peuvent fluctuer d'une plateforme de
                financement participatif à l'autre - bien que la moyenne se
                situe souvent aux alentours de 5%, certains secteurs peuvent se
                démarquer. Les investisseurs participatifs peuvent toutefois
                anticiper des gains supérieurs à ceux qu'ils obtiendraient d'un
                compte épargne classique en banque. De surcroît, d'autres
                bénéfices non-financiers sont souvent à la clé."
              </p>
            </div>
          </div>
          <div className="quels-sont-les-risques-encouru-parent">
            <b className="quels-sont-les-container">
              <p className="linvestissement-participatif">
                Quels sont les risques encourus par les investisseurs ?
              </p>
            </b>
            <div className="tout-investissement-est-container">
              <p className="linvestissement-participatif">
                Tout investissement est sujet à des risques - ceci est
                explicitement mentionné sur toutes les plateformes de
                financement participatif. Dans le cas le plus défavorable,
                l'investissement initial peut être intégralement perdu. Il est
                vivement recommandé aux investisseurs de s'informer sur les
                risques spécifiques à chaque projet dans lequel ils envisagent
                d'investir. Ils sont aussi fortement incités à diversifier leurs
                investissements entre plusieurs projets afin de réduire le
                risque.
              </p>
              <p className="linvestissement-participatif">&nbsp;</p>
              <p className="linvestissement-participatif">{` Chez E+C-  nous collaborons avec des entités  capables de régler leurs factures d'électricité , afin de limiter une partie de ce risque. `}</p>
              <p className="linvestissement-participatif">&nbsp;</p>
              <p className="linvestissement-participatif">{`Contrairement au financement participatif pour les start-ups ou les entreprises, la réussite des projets d'énergie renouvelable est liée à l'énergie produite par les installations plutôt qu'à la croissance de l'entreprise. `}</p>
              <p className="linvestissement-participatif">&nbsp;</p>
              <p className="linvestissement-participatif">
                <span>{`Ainsi, la réussite du projet `}</span>
                <b>est plus prévisible et facile à planifier.</b>
              </p>
              <p className="linvestissement-participatif">
                <b>&nbsp;</b>
              </p>
              <p className="linvestissement-participatif">{`Les investisseurs sur la plateforme E+C- peuvent consulter une description des risques généraux associés ici. `}</p>
              <p className="linvestissement-participatif">&nbsp;</p>
              <p className="linvestissement-participatif">&nbsp;</p>
              <p className="linvestissement-participatif">
                Des documents détaillés relatifs aux risques spécifiques de
                chaque projet sont disponibles au téléchargement."
              </p>
            </div>
            <div className="documents-wrapper">
              <b className="documents">Documents</b>
            </div>
          </div>
          <div className="quelles-opportunits-le-financ-parent">
            <b className="tandis-que-les">
              <p className="linvestissement-participatif">
                Quelles opportunités le financement participatif offre-t-il ?
              </p>
            </b>
            <div className="linvestissement-participatif-container">
              <p className="linvestissement-participatif">
                Dans le cas D’E+C- , Le financement participatif nous permet de
                financer des projets qui ne seraient pas réalisés autrement :
                malgré leur viabilité technologique et financière, les volumes
                sont trop faibles pour les investisseurs institutionnels. C'est
                souvent le cas du financement participatif en général : il est
                utilisé lorsque les financements traditionnels sont inadaptés ou
                inaccessibles.
              </p>
              <p className="linvestissement-participatif">&nbsp;</p>
              <p className="linvestissement-participatif">&nbsp;</p>
              <p className="linvestissement-participatif">{`Pour les investisseurs, le financement participatif offre bien plus qu'un simple retour sur investissement. `}</p>
              <p className="linvestissement-participatif">&nbsp;</p>
              <p className="linvestissement-participatif">
                La tangibilité et la transparence sont deux avantages majeurs -
                les investisseurs peuvent directement investir dans les
                entreprises, les projets et les personnes qu'ils souhaitent
                soutenir, et peuvent suivre l'impact de leur argent
              </p>
              <p className="linvestissement-participatif">&nbsp;</p>
              <p className="linvestissement-participatif">
                . Dans le secteur de l'énergie, les investisseurs peuvent
                également soutenir l'environnement.
              </p>
              <p className="linvestissement-participatif">&nbsp;</p>
              <p className="linvestissement-participatif">
                {" "}
                Dans le cas d'E+C-, ils ont également la possibilité de soutenir
                le développement durable
              </p>
            </div>
          </div>
          <div className="comment-pouvez-vous-garantir-l-parent">
            <b className="quels-sont-les-container">
              <p className="linvestissement-participatif">{`Comment pouvez-vous garantir la qualité de l'installation et du service ? `}</p>
            </b>
            <div className="tout-investissement-est-container">
              <p className="linvestissement-participatif">
                Nous n'utilisons que du matériel de haute qualité. Celui-ci
                provient à la fois d'entreprises allemandes - comme les
                onduleurs SolaXpower, Huawei ou APSystem - et de fabricants de
                composants de haute qualité à travers le monde.
              </p>
              <p className="linvestissement-participatif">&nbsp;</p>
              <p className="linvestissement-participatif">
                {" "}
                Notre partenaire pour la diligence raisonnable technique,,
                vérifie les composants choisis pour chaque projet et nous
                mettons à disposition toutes les informations concernant
                l'équipement.
              </p>
              <p className="linvestissement-participatif">&nbsp;</p>
            </div>
            <div className="en-savoir-plus-wrapper1">
              <b className="documents">{`En savoir plus `}</b>
            </div>
          </div>
          <div className="quelles-opportunits-le-financ-parent">
            <b className="tandis-que-les">
              Comment E+C- intègre-t-il le financement participatif dans son
              modèle ?
            </b>
            <div className="les-retours-sur-container">
              <p className="linvestissement-participatif">
                E+C- a un modèle d'affaires à double volet : nous proposons des
                projets solaires comme opportunités d'investissement pour le
                public, et nous sommes également un fournisseur d'énergie
                solaire qui propose de l'électricité solaire à faible coût aux
                entreprises dans les marchés émergents.
              </p>
              <p className="linvestissement-participatif">&nbsp;</p>
              <p className="linvestissement-participatif">&nbsp;</p>
              <p className="linvestissement-participatif">
                {" "}
                En finançant des projets solaires par le biais de notre
                plateforme de financement participatif, nous comblons le manque
                de financement qui a jusqu'à présent empêché la réalisation de
                ces projets.
              </p>
            </div>
          </div>
          <div className="quelles-seront-les-premires-e-parent">
            <b className="quelles-seront-les">
              Quelles seront les premières expériences avec le financement
              participatif ?
            </b>
            <div className="tout-investissement-est-container">
              <p className="linvestissement-participatif">{`Nos premières initiatives,  viseront  à financer deux installations solaires: une première, sur une petite solution d’autoconsommation,  dans le centre de formation de l’association CreativeMinds.academy qui est le partenaire éducatif de E+C- et l’autre sur une toiture d’une entreprise basée non loin de Toulon à la Seyne sur Mer pour équiper une solution de 10 000 €. `}</p>
              <p className="linvestissement-participatif">&nbsp;</p>
              <p className="linvestissement-participatif">
                {" "}
                Consultez nos pages de projets et articles de blog pour en
                savoir plus.
              </p>
            </div>
            <div className="pages-projets-wrapper">
              <b className="pages-projets">{`Pages projets `}</b>
            </div>
          </div>
        </div>
        <footer className="footer4">
          <div className="container4">
            <div className="col14">
              <b className="creative-minds-container4">
                <p className="linvestissement-participatif">E+C- :</p>
                <p className="linvestissement-participatif">Investisser</p>
                <p className="linvestissement-participatif">Autrement</p>
              </b>
            </div>
            <div className="col24">
              <b className="stages-vacances4">Investissement solaire</b>
              <div className="chatgpt-mid-container4">
                <p className="linvestissement-participatif">
                  France : Toulon (Association formation )
                </p>
                <p className="linvestissement-participatif"> 900 €</p>
                <p className="linvestissement-participatif">&nbsp;</p>
                <p className="linvestissement-participatif">{`France : Toulon  (association  formation `}</p>
                <p className="linvestissement-participatif">3500 €</p>
                <p className="linvestissement-participatif">&nbsp;</p>
                <p className="linvestissement-participatif">{`France : Seyne sur Mer ( usine . ) `}</p>
                <p className="linvestissement-participatif">10 000 €</p>
                <p className="linvestissement-participatif">&nbsp;</p>
                <p className="linvestissement-participatif">
                  Bali : Ubud ( Coworking CoLiving )
                </p>
                <p className="linvestissement-participatif">45 000 €</p>
                <p className="linvestissement-participatif">&nbsp;</p>
                <p className="linvestissement-participatif">&nbsp;</p>
              </div>
            </div>
            <div className="col34">
              <div className="col-4column4">
                <b className="soutiens-partenaires4">{`Soutiens & Partenaires`}</b>
                <b className="rentre-20239">Rentrée 2023</b>
                <b className="rentre-20239">Nous rejoindre</b>
                <b className="rentre-20239">Notre Approche</b>
                <b className="rentre-20239">Contact</b>
                <b className="rentre-20239">CGV</b>
              </div>
            </div>
          </div>
          <div className="textdroit4">©2023 E+C- | Tout droits reservés</div>
        </footer>
      </div>
      {isDrawerMenuDrawerItemPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top center"
          onOutsideClick={closeDrawerMenuDrawerItemPopup}
        >
          <DrawerMenuDrawerItem onClose={closeDrawerMenuDrawerItemPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default LeCrowdInvesting;
