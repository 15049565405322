import "./PopupinfoNetworkType.css";
const PopupinfoNetworkType = ({ onClose }) => {
  return (
    <div className="popupinfo-network-type">
      <div className="popupinfo-network-type1">
        <div className="divcontrol">
          <div className="les-differents-types">
            LES DIFFERENTS TYPES D’ONDULEURS
          </div>
          <button className="buttonclose-menu" id="btn_close_menu">
            <img className="cancelfilled-icon" alt="" src="/cancelfilled.svg" />
          </button>
        </div>
        <div className="un-onduleur-hors-container">
          <p className="un-onduleur-hors">
            Un onduleur hors réseau est conçu pour fonctionner de manière
            autonome,
          </p>
          <p className="un-onduleur-hors">{`déconnecté du réseau électrique principal. `}</p>
          <p className="un-onduleur-hors">&nbsp;</p>
          <p className="un-onduleur-hors">
            Il convertit l'énergie produite par des sources telles que des
            panneaux solaires
          </p>
          <p className="un-onduleur-hors">{`en électricité utilisable, stockant souvent l'excès dans des batteries. `}</p>
          <p className="un-onduleur-hors">&nbsp;</p>
          <p className="un-onduleur-hors">{`En revanche, un onduleur sur tous réseaux, également appelé onduleur `}</p>
          <p className="un-onduleur-hors">
            connecté au réseau, est conçu pour fonctionner en tandem avec le
            réseau
          </p>
          <p className="un-onduleur-hors">électrique.</p>
          <p className="un-onduleur-hors">&nbsp;</p>
          <p className="un-onduleur-hors">
            Il permet de convertir l'énergie produite par des sources
            renouvelables,
          </p>
          <p className="un-onduleur-hors">
            comme les panneaux solaires, en électricité pouvant être utilisée
            localement
          </p>
          <p className="un-onduleur-hors">
            tout en permettant un éventuel surplus d'énergie à être injecté dans
            le réseau
          </p>
          <p className="un-onduleur-hors">électrique global.</p>
        </div>
      </div>
    </div>
  );
};

export default PopupinfoNetworkType;
