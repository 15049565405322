import { useState, useCallback } from "react";
import DrawerMenuDrawerItem from "../components/DrawerMenuDrawerItem";
import PortalPopup from "../components/PortalPopup";
import "./DcouvrezLesPrincipes.css";
const DcouvrezLesPrincipes = () => {
  const [isDrawerMenuDrawerItemPopupOpen, setDrawerMenuDrawerItemPopupOpen] =
    useState(false);

  const openDrawerMenuDrawerItemPopup = useCallback(() => {
    setDrawerMenuDrawerItemPopupOpen(true);
  }, []);

  const closeDrawerMenuDrawerItemPopup = useCallback(() => {
    setDrawerMenuDrawerItemPopupOpen(false);
  }, []);

  return (
    <>
      <div className="dcouvrez-les-principes">
        <header className="headerheader3">
          <div className="logo4">
            <b className="creative-minds4">E+C-</b>
          </div>
          <div className="menuitems4">
            <div className="links3">
              <div className="dmarrez">PILOTE summer 2023</div>
              <div className="links-child6" />
              <div className="dmarrez">Investir à partir de 10 €</div>
              <div className="links-child6" />
              <div className="dmarrez">Nous rejoindre</div>
              <div className="links-child6" />
              <div className="dmarrez">Se connecter</div>
            </div>
            <div
              className="hamburgeritem3"
              onClick={openDrawerMenuDrawerItemPopup}
            >
              <div className="iconcontainer3">
                <img className="icon3" alt="" src="/icon17.svg" />
              </div>
            </div>
          </div>
        </header>
        <div className="investissez-dans-le-solaire-d-parent">
          <div className="investissez-dans-le-container2">
            <p className="le-crowdfunding-reprsente">
              <b>"Investissez dans le solaire</b>
            </p>
            <p className="le-crowdfunding-reprsente">
              Débutez avec un modeste apport de 10€."
            </p>
          </div>
          <div className="invest-solar-icon-square-icone-parent">
            <img
              className="invest-solar-icon-square-icone2"
              alt=""
              src="/investsolariconsquare-icones-2@2x.png"
            />
            <div className="mesurez-votre-impact-votre-container2">
              <p className="le-crowdfunding-reprsente">
                <b>"Mesurez votre impact</b>
              </p>
              <p className="le-crowdfunding-reprsente">
                Votre investissement contribue à lancer rapidement des projets
                d'énergie verte
              </p>
            </div>
          </div>
          <div className="environmental-impact-icon-squa-parent">
            <img
              className="environmental-impact-icon-squa2"
              alt=""
              src="/environmentalimpacticonsquare-images--2@2x.png"
            />
            <div className="observez-la-croissance-container2">
              <p className="le-crowdfunding-reprsente">
                <b>"Observez la croissance de votre investissement</b>
              </p>
              <p className="le-crowdfunding-reprsente">
                Votre placement génère des intérêts dès le premier jour."
              </p>
            </div>
          </div>
        </div>
        <div className="subfooter-wrapper">
          <div className="subfooter">
            <div className="textcontainer">
              <b className="quest-ce-que">{`Qu’est ce que le crowdinvesting ? `}</b>
            </div>
            <div className="btn">
              <b className="le-crowdfinvesting">{`Le crowdfinvesting `}</b>
            </div>
          </div>
        </div>
        <div className="frame-parent">
          <div className="pourquoi-le-financement-partic-parent">
            <b className="pourquoi-le-financement">
              {" "}
              Pourquoi le financement participatif ?
            </b>
            <div className="le-crowdfunding-reprsente-container">
              <p className="le-crowdfunding-reprsente">
                Le crowdfunding représente une manière innovante et exempte de
                frais de faire fructifier votre argent. À la différence des
                investissements dans les fonds, vous savez précisément dans qui
                et quoi vous investissez, aspect particulièrement important si
                vous aspirez à ne pas soutenir les énergies fossiles.
              </p>
              <p className="le-crowdfunding-reprsente">
                Le crowdfunding est indépendant du secteur industriel et offre
                une capacité illimitée de collecte de capitaux pour des projets
                ou des entreprises qui suscitent votre intérêt personnel — qu'il
                s'agisse de l'immobilier, des start-ups technologiques, ou,
                comme dans notre situation : l'énergie verte et renouvelable."
              </p>
            </div>
          </div>
          <div className="dmarrez-parent">
            <b className="dmarrez">Démarrez</b>
            <div className="lenregistrement-ne-prend">
              L'enregistrement ne prend que cinq minutes et est entièrement
              numérique — vous avez juste besoin d'un compte bancaire dans l'UE.
              Sélectionnez votre projet, renseignez vos informations, et débutez
              votre investissement avec un apport aussi faible que 100€. Nous
              vous adresserons tous vos documents légaux à conserver. Plus
              rapidement vous initiez votre investissement, plus tôt vous
              commencerez à accumuler des intérêts."
            </div>
          </div>
          <div className="choisissez-un-projet-parent">
            <b className="choisissez-un-projet">Choisissez un projet</b>
            <div className="deux-formes-dinvestissement-container">
              <p className="le-crowdfunding-reprsente">
                Deux formes d'investissement existent. Les investisseurs
                participatifs peuvent soit financer des projets de manière
                indépendante, soit en collaboration avec des prêteurs
                institutionnels. Plusieurs facteurs pourraient influencer votre
                choix de financement, tels que le pays, le secteur, le type
                d'entreprise, la durée du prêt, le taux d'intérêt et l'impact
                écologique.
              </p>
              <p className="le-crowdfunding-reprsente">&nbsp;</p>
              <ul className="vous-recherchez-davantage-de-l">
                <li className="vous-recherchez-davantage">{`Vous recherchez davantage de liquidités ? Optez pour un prêt à court terme. `}</li>
                <li className="vous-recherchez-davantage">{`Vous prévoyez pour le futur ? Maximisez vos profits en sélectionnant un projet à haut taux d'intérêt et à long terme. `}</li>
                <li>
                  Vous avez été charmé par tel ou tel projet ? Investissez dans
                  un projet qui favorise le développement durable de ce projet
                  ."
                </li>
              </ul>
            </div>
            <div className="explorer-les-projets-wrapper">
              <b className="dmarrez">Explorer les projets</b>
            </div>
          </div>
          <div className="recevez-des-remboursements-parent">
            <b className="choisissez-un-projet">Recevez des remboursements</b>
            <div className="lenregistrement-ne-prend">
              C'est le moment tant attendu — la rétribution de votre
              investissement. Notre historique est impeccable : tous les projets
              ont honoré leurs engagements envers les investisseurs en temps et
              en heure. Les remboursements sont effectués une fois par an. Vous
              obtenez vos intérêts lors du premier versement et, dans les années
              suivantes, vous recevez le remboursement de votre prêt en plus des
              intérêts."
            </div>
          </div>
        </div>
        <footer className="footer3">
          <div className="container3">
            <div className="col13">
              <b className="creative-minds-container3">
                <p className="le-crowdfunding-reprsente">E+C- :</p>
                <p className="le-crowdfunding-reprsente">Investisser</p>
                <p className="le-crowdfunding-reprsente">Autrement</p>
              </b>
            </div>
            <div className="col23">
              <b className="stages-vacances3">Investissement solaire</b>
              <div className="chatgpt-mid-container3">
                <p className="le-crowdfunding-reprsente">
                  France : Toulon (Association formation )
                </p>
                <p className="le-crowdfunding-reprsente"> 900 €</p>
                <p className="le-crowdfunding-reprsente">&nbsp;</p>
                <p className="le-crowdfunding-reprsente">{`France : Toulon  (association  formation `}</p>
                <p className="le-crowdfunding-reprsente">3500 €</p>
                <p className="le-crowdfunding-reprsente">&nbsp;</p>
                <p className="le-crowdfunding-reprsente">{`France : Seyne sur Mer ( usine . ) `}</p>
                <p className="le-crowdfunding-reprsente">10 000 €</p>
                <p className="le-crowdfunding-reprsente">&nbsp;</p>
                <p className="le-crowdfunding-reprsente">
                  Bali : Ubud ( Coworking CoLiving )
                </p>
                <p className="le-crowdfunding-reprsente">45 000 €</p>
                <p className="le-crowdfunding-reprsente">&nbsp;</p>
                <p className="le-crowdfunding-reprsente">&nbsp;</p>
              </div>
            </div>
            <div className="col33">
              <div className="col-4column3">
                <b className="soutiens-partenaires3">{`Soutiens & Partenaires`}</b>
                <b className="rentre-20237">Rentrée 2023</b>
                <b className="rentre-20237">Nous rejoindre</b>
                <b className="rentre-20237">Notre Approche</b>
                <b className="rentre-20237">Contact</b>
                <b className="rentre-20237">CGV</b>
              </div>
            </div>
          </div>
          <div className="textdroit3">©2023 E+C- | Tout droits reservés</div>
        </footer>
      </div>
      {isDrawerMenuDrawerItemPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top center"
          onOutsideClick={closeDrawerMenuDrawerItemPopup}
        >
          <DrawerMenuDrawerItem onClose={closeDrawerMenuDrawerItemPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default DcouvrezLesPrincipes;
