import React, { useEffect } from "react";
import FooterMobile from "../components/FooterMobile";
import "./Login.css"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { apiNodeService } from "../provider/apiNodeService";
import MuiBackdrop from "../components/Backdrop/MuiBackdrop";
import { useNavigate } from "react-router-dom";
import Divider from '@mui/material/Divider';
import { GoogleLogin } from 'react-google-login';
import { useToast } from "@chakra-ui/react";

const Login = () => {

    const navigate = useNavigate();
    const toast = useToast();

    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')

    const [validate, setValidate] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [nextPage, setNextPage] = React.useState('')

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const page = searchParams.get('page');
        setNextPage(page)
        console.log(page)
    }, []);

    const getUserByEmail = async () => {
        setLoading(true)
        return new Promise(resolve => {
            // apiNodeService.filterTable({ table: "green_user", filter: { email: email }, db: "CREATIVE_MINDS" }).then(userRes => {
            //     console.log(userRes)
            //     if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
            //         console.log(userRes.data[0])
            //         if (userRes.data[0]) {
            //             localStorage.setItem("email", userRes.data[0].email)
            //             localStorage.setItem("first_name", userRes.data[0].first_name)
            //             setLoading(false)
            //             console.log(nextPage)
            //             nextPage ? navigate('/'+nextPage) : navigate("/")
            //         } else {
            //             setValidate(true)
            //             setLoading(false)
            //         }

            //         resolve(userRes.data[0])
            //     } else {
            //         resolve("false")
            //     }
            // }).catch(err => resolve("false"))
            let data = { email: email, password: password ,table:'green_user' }
            apiNodeService.loginGreen(data).then((result) => {
                console.log(result)
                console.log(email)
                  console.log(password)
                if (result.data && result.data.token) {
                  console.log(result.data)
                  
                  localStorage.setItem("token", result.data.token)
                  localStorage.setItem("email", result.data.email)
                    localStorage.setItem("first_name", result.data.first_name)
                    localStorage.setItem("last_name", result.data.last_name)
                    localStorage.setItem("id", result.data.id)
                    navigate('/profile')
                    setLoading(false)
                }
                else {
                    setValidate(true)
                    setLoading(false)
                }
              }).catch(err => {
                setLoading(false)
              })
        })
    }

    const googleLogin = async (response) => {
        console.log(response)
        setLoading(true)
        if (response && response.Ca) {
          const user = await getUserByEmail1(response.profileObj.email)
          console.log(user)
          if (user) {
            localStorage.setItem("token", response.tokenId)
            localStorage.setItem("id", user.id)
            localStorage.setItem("email", response.profileObj.email)
            localStorage.setItem("first_name", response.profileObj.givenName)
            localStorage.setItem("last_name", response.profileObj.familyName)
            navigate("/profile")

          } else {
            console.log("pas de compte")
            let data = {
                first_name: response.profileObj.givenName,
                last_name: response.profileObj.familyName,
                password: "Green2024!",
                email: response.profileObj.email
            }
            console.log(data)
            apiNodeService.registerGreen(data).then(res=>{
                console.log(res)
                if (res && res.status === 200) {
                    navigate("/profile")
                    navigate("/profile")
                    // setTimeout(() => {
                    //     navigate("/profile")
                    //   }, 2000)
                    console.log("go to profil")
                } else {
                    toast({
                        title: 'Erreur !',
                        description: "Une erreur est survenue, Veuillez réessayer ultérieurement",
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            })
        }
        }
      }

      const getUserByEmail1 = async (email) => {
        return new Promise(resolve => {
          apiNodeService.filterTable({table:"green_user",filter:{email:email},db:"CREATIVE_MINDS"}).then(userRes => {
            console.log(userRes)
            if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
              console.log(userRes.data[0])
              resolve(userRes.data[0])
            } else {
              resolve("false")
            }
          }).catch(err => resolve("false"))
        })
      }

    return (
        <>
            <div className="login-page">
                <MuiBackdrop
                    open={loading}
                />
                <header className="headerheader-mobile">
                    <div style={{ width: "100%",gap:20 }} className="d-flex align-items-center">
                        <KeyboardBackspaceIcon style={{ cursor: 'pointer' }} fontSize="large" onClick={(e) => { navigate(-1) }} />
                        <span className="span-list-projet-mobile">Connexion</span>
                        <span></span>
                    </div>
                </header>
                <div className="p-3 mt-5">
                    <div className="card-etape d-flex flex-column align-self-center p-3 mt-5">
                        <span>Adresse e-mail*</span>
                        <div className="form-group mt-1 mb-4">
                            <input onChange={(e) => { setEmail(e.target.value) }} type="email" className="form-control is-valid" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                        <span>Mot de passe*</span>
                        <div className="form-group mt-1">
                            <input onChange={(e) => { setPassword(e.target.value) }} type="password" className="form-control is-valid" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                        {validate === true && <span className="mt-1" style={{ color: "red", marginLeft: 2 }}> Veuillez vérifier votre email et mot de passe</span>}
                        <div className="d-flex flex-column mt-2">
                         <span onClick={(e) => { navigate("/reset-password") }}  className="inscrit-span">Mot de passe oublié ?</span>
                            
                            <button onClick={getUserByEmail} className="login-button mt-3 mb-3">
                                Se connecter
                            </button>
                            <div className="d-flex">
                            <span className="inscrit-span1">Vous n’avez pas de compte ?</span>
                            <span onClick={(e) => { navigate("/signup") }} className="inscrit-span">&nbsp;&nbsp; Inscrivez-vous</span>

                            </div>
                        </div>
                        <Divider className="mt-3">OU</Divider>
                        
                        <GoogleLogin
                            clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}
                            render={renderProps => (
                            <div onClick={renderProps.onClick} className="d-flex align-items-center mt-3 continuer-section px-3 py-2" style={{gap:20}}>
                                <img src="/googlelogin.svg" />
                                <span>Continuer avec Google</span>
                            </div>
                            )}
                            buttonText="Login"
                            onSuccess={googleLogin}
                            onFailure={googleLogin}
                            cookiePolicy={'single_host_origin'}
                        />
                    </div>
                </div>
                <FooterMobile />
            </div>
        </>
    )
}
export default Login;