exports.products = [
    {
        name:"Clim portable 1.5 kW",
        price:999,
        type:"climatisations",
        prop:["Marque Ecoflow","Possibilité d’ajouter une batterie","Gaz réfrigérant R290","Puissance frigorifique de 5100 BTU","Idéal pour une surface de 15 m²","Poids net de 14.5 kg","Niveau sonore de 44 à 56 dB"],
        img:"/imgproduct-view1@2x.png"
    },
    {
        name:"Clim portable 1.5 kW",
        price:279.99,
        type:"climatisations",
        prop:["Marque Avalla","Classe énergétique A","Puissance frigorifique de 5000 BTU","Idéal pour une surface de 15 m²","Poids net de 19 kg","Niveau sonore de 50 dB","Déshumidificateur 12 L"],
        img:"/imgproduct-view2@2x.png"
    },
    {
        name:"Clim portable 2.1 kW",
        price:319.99,
        type:"climatisations",
        prop:["Marque Avalla","Classe énergétique A","Puissance frigorifique de 7000 BTU","Idéal pour une surface de 20 m²","Poids net de 19 kg","Niveau sonore de 52 dB","Déshumidificateur 20 L"],
        img:"/imgproduct-view3@2x.png"
    },
    {
        name:"Clim réversible 2.5 kW",
        price:323.67,
        type:"climatisations",
        prop:["Marque DAIKIN","Prix de la mise en service non compris","Puissance frigorifique de 8000 BTU","Niveau sonore de 20 dB","Idéal pour une surface de 25 m²","Classe énergétique A++","Poids net de 8 kg"],
        img:"/imgproduct-view4@2x.png"
    },
    {
        name:"Clim portable 2.6 kW",
        price:369.99,
        type:"climatisations",
        prop:["Marque Avalla","Classe énergétique A","Puissance frigorifique de 9000 BTU","Idéal pour une surface de 25 m²","Poids net de 23 kg","Niveau sonore de 54 dB","Déshumidificateur 24 L"],
        img:"/imgproduct-view5@2x.png"
    },
    {
        name:"Clim portable 3.5 kW",
        price:379.99,
        type:"climatisations",
        prop:["Marque Shinco","Classe énergétique A","Puissance frigorifique de 12000 BTU","Idéal pour une surface de 35 m²","Poids net de 29 kg","Niveau sonore de 65 dB","Gaz frigorifique R290"],
        img:"/imgproduct-view6@2x.png"
    },
    {
        name:"Clim réversible 3.5 kW",
        price:349.98,
        type:"climatisations",
        prop:["Marque AGN","Prix de la mise en service non compris","Puissance frigorifique de 12000 BTU","Niveau sonore de 55 dB","Idéal pour une surface de 35 m²","Classe énergétique A++","Poids net de 31 kg"],
        img:"/imgproduct-view7@2x.png"
    },
    {
        name:"Clim réversible 5.3 kW",
        price:1399.99,
        type:"climatisations",
        prop:["Marque BOSCH","Prix de la mise en service non compris","Niveau sonore de 50 dB","Idéal pour une surface de 55 m²","Classe énergétique A++"],
        img:"/imgproduct-view8@2x.png"
    },
    {
        name:"Clim réversible 10.6 kW",
        price:2552.97,
        type:"climatisations",
        prop:["Marque Clivet","Prix de la mise en service non compris","Niveau sonore de 63 dB","Puissance frigorifique de 36000 BTU","Idéal pour une surface de 110 m²","Classe énergétique A+","Poids net de 40.5 kg"],
        img:"/imgproduct-view9@2x.png"
    },
    {
        name:"MONO 24 V 365 WC",
        price:109.33,
        type:"panneaux solaires",
        prop:["Marque Longi solar","Rendement pouvant atteindre les 19.5%","Technologie PERC","Poids net de 19.5 kg","Dimensions de 1755 x 1038 x 30 mm","Garantie distributeur 12 ans, magasin 2 ans"],
        img:"/imgproduct-view10@2x.png"
    },
    {
        name:"MONO 24 V 400 WC",
        price:118.32,
        type:"panneaux solaires",
        prop:["Marque Trinasolar","Rendement pouvant atteindre les 21.1%","Connecteur MC4","Poids net de 21 kg","Dimensions de 1754 × 1096 × 30 mm","Garantie distributeur 15 ans, magasin 2 ans"],
        img:"/img_product_view.png"
    },
    {
        name:"IQ7A ENPHASE",
        price:167.79,
        type:"onduleurs solaires, régulateurs et distribution",
        prop:["Marque Enphase","Puissance PV de 460 Wc","Puissance sortie AC de 366 W","Installation facile","Garantie distributeur 25 ans, magasin 2 ans"],
        img:"/img_product_view1.png"
    },
    {
        name:"IQ8M ENPHASE",
        price:158.52,
        type:"onduleurs solaires, régulateurs et distribution",
        prop:["Marque Enphase","Puissance PV de 480 Wc","Puissance sortie AC de 330 W","Installation facile","Garantie distributeur 25 ans, magasin 2 ans"],
        img:"/img_product_view2.png"
    },
    {
        name:"3000TL G3 3KW",
        price:441.76 ,
        type:"onduleurs solaires, régulateurs et distribution",
        prop:["Marque Sofar","Puissance PV max de 4100 W","Indice de Protection IP65","Poids net de 6.3 kg","Garantie distributeur 10 ans, magasin 2 ans"],
        img:"/img_product_view3.png"
    },
    {
        name:"HYBRIDE 3 KW 2 MPPT",
        price:441.76,
        type:"onduleurs solaires, régulateurs et distribution",
        prop:["Marque Sofar","Puissance PV max de 4500 W","Indice de Protection IP65","Poids net de 21.5 kg","Garantie distributeur 5 ans, magasin 2 ans"],
        img:"/img_product_view4.png"
    },
    {
        name:"HYBRIDE 3000 VA 24 V",
        price:578.58,
        type:"onduleurs solaires, régulateurs et distribution",
        prop:["Marque Epever","Hors réseau","MPPT 60 A","Contrôle indépendant de la sortie AC","Garantie distributeur 2 ans, magasin 2 ans"],
        img:"/imgproduct-view11@2x.png"
    },
    {
        name:"HYBRIDE 5000 VA 48 V",
        price:784.78,
        type:"onduleurs solaires, régulateurs et distribution",
        prop:["Marque Epever","Hors réseau","MPPT 100 A","Contrôle indépendant de la sortie AC","Garantie distributeur 2 ans, magasin 2 ans"],
        img:"/imgproduct-view12@2x.png"
    },
    {
        name:"HYBRIDE 5000 VA 48-450 V",
        price:889.53,
        type:"onduleurs solaires, régulateurs et distribution",
        prop:["Marque Epever","Hors réseau","MPPT 80 A","Contrôle indépendant de la sortie AC","Garantie distributeur 2 ans, magasin 2 ans"],
        img:"/imgproduct-view13@2x.png"
    },
    {
        name:"MULTIPLUS 1200 VA 24 V",
        price:813.34,
        type:"onduleurs solaires, régulateurs et distribution",
        prop:["Marque Victron Energy","MPPT 25 A","Garantie distributeur 5 ans, magasin 2 ans"],
        img:"/imgproduct-view14@2x.png"
    },
    {
        name:"EASYSOLAR 3000 VA 48 V",
        price:2092.50,
        type:"onduleurs solaires, régulateurs et distribution",
        prop:["Marque Victron Energy","MPPT 70 A","Garantie distributeur 5 ans, magasin 2 ans"],
        img:"/imgproduct-view15@2x.png"
    },
    {
        name:"QUATTRO 10 000 VA 48 V",
        price:4325.42,
        type:"onduleurs solaires, régulateurs et distribution",
        prop:["Marque Victron Energy","Efficience max de 96%","Poids net 51 kg","Garantie distributeur 5 ans, magasin 2 ans"],
        img:"/imgproduct-view16@2x.png"
    },
    {
        name:"MPPT 250/100 100 A",
        price:1016.45,
        type:"onduleurs solaires, régulateurs et distribution",
        prop:["Marque Victron Energy","MPPT 100 A","Tension de batterie : 12 V, 24 V et 48 V","Champ PV de 150 ou 250 V Voc","Garantie distributeur 5 ans, magasin 2 ans"],
        img:"/imgproduct-view17@2x.png"
    },
    {
        name:"MPPT 100/50 50 A",
        price:302.11,
        type:"onduleurs solaires, régulateurs et distribution",
        prop:["Marque Victron Energy","MPPT 50 A","Tension de batterie : 12 V et 24 V","Champ PV de 100 ou 250 V Voc","Garantie distributeur 5 ans, magasin 2 ans"],
        img:"/imgproduct-view18@2x.png"
    },
    {
        name:"DC LYNX POWER",
        price:168.74,
        type:"onduleurs solaires, régulateurs et distribution",
        prop:["Marque Victron Energy","Jusqu’à une tension de 1000 V","Poids net de 2.1 kg","Garantie distributeur 5 ans, magasin 2 ans"],
        img:"/imgproduct-view19@2x.png"
    },
    {
        name:"DC LYNX DISTRIBUTOR",
        price:240.47,
        type:"onduleurs solaires, régulateurs et distribution",
        prop:["Marque Victron Energy","Jusqu’à une tension de 1000 V","Poids net de 2.27 kg","Garantie distributeur 5 ans, magasin 2 ans"],
        img:"/imgproduct-view20@2x.png"
    },
    {
        name:"GEL 165 AH 12 V",
        price:465.77,
        type:"batteries",
        prop:["Marque Victron Energy","Durée de vie de 12 ans","Tension de batterie 12 V","Poids net de 48.4 kg","Garantie distributeur 2 ans, magasin 1 an"],
        img:"/imgproduct-view21@2x.png"
    },
    {
        name:"GEL 200 AH 12 V",
        price:338.69,
        type:"batteries",
        prop:["Marque Ultracell","Durée de vie de 15 ans","Tension de batterie 12 V","Poids net de 62 kg","Garantie distributeur 2 ans, magasin 1 an"],
        img:"/imgproduct-view22@2x.png"
    },
    {
        name:"LITHIUM 2.4 kWh",
        price:845.89,
        type:"batteries",
        prop:["Marque Pylontech","Durée de vie de 6000 cycles à 90%","Tension de batterie 48 V","Poids net de 24 kg","Garantie distributeur 10 ans, magasin 2 ans"],
        img:"/imgproduct-view23@2x.png"
    },
    {
        name:"LITHIUM 2.4 kWh",
        price:841.75,
        type:"batteries",
        prop:["Marque Dyness","Durée de vie de 6000 cycles à 80%","Tension de batterie 48 V","Poids net de 22 kg","Garantie distributeur 10 ans, magasin 2 ans"],
        img:"/imgproduct-view24@2x.png"
    },
    {
        name:"SECTIONNEUR DC 32 A",
        price:55.87,
        type:"câbles et accessoires",
        prop:["Tension de fonctionnement de 1000 V","IP68 utilisation intérieure / extérieure","Poids net de 0.6 kg","Garantie distributeur 1 an, magasin 1 an"],
        img:"/imgproduct-view25@2x.png"
    },
    {
        name:"JONCTION BATTERIE 20 CM",
        price:6.90,
        type:"câbles et accessoires",
        prop:["Taille de section 16 mm²","Cosses M8","Couleur NOIR","Garantie distributeur 1 an, magasin 1 an"],
        img:"/imgproduct-view26@2x.png"
    },
    {
        name:"JONCTION BATTERIE 20 CM",
        price:7.54,
        type:"câbles et accessoires",
        prop:["Taille de section 25 mm²","Cosses M8","Couleur NOIR","Garantie distributeur 1 an, magasin 1 an"],
        img:"/imgproduct-view27@2x.png"
    },
    {
        name:"JONCTION BATTERIE 20 CM",
        price:10.51,
        type:"câbles et accessoires",
        prop:["Taille de section 35 mm²","Cosses M8","Couleur NOIR","Garantie distributeur 1 an, magasin 1 an"],
        img:"/imgproduct-view28@2x.png"
    },
    {
        name:"CABLE BATTERIE 2x1 M",
        price:23.25,
        type:"câbles et accessoires",
        prop:["Taille de section 16 mm²","Cosses M8","Couleur NOIR - ROUGE","Courant admissible de 62 A maximum","Garantie distributeur 1 an, magasin 2 ans"],
        img:"/imgproduct-view29@2x.png"
    },
    {
        name:"CABLE BATTERIE 2x1 M",
        price:26.40,
        type:"câbles et accessoires",
        prop:["Taille de section 25 mm²","Cosses M8","Couleur NOIR - ROUGE","Courant admissible de 82 A maximum","Garantie distributeur 1 an, magasin 2 ans"],
        img:"/imgproduct-view30@2x.png"
    },
    {
        name:"CABLE BATTERIE 2x1 M",
        price:36.28,
        type:"câbles et accessoires",
        prop:["Taille de section 35 mm²","Cosses M8","Couleur NOIR - ROUGE","Garantie distributeur 1 an, magasin 2 ans"],
        img:"/imgproduct-view31@2x.png"
    },
    {
        name:"CABLE BATTERIE 2x1 M",
        price:44.66,
        type:"câbles et accessoires",
        prop:["Taille de section 50 mm²","Cosses M8","Couleur NOIR - ROUGE","Garantie distributeur 1 an, magasin 1 ans"],
        img:"/imgproduct-view32@2x.png"
    },
    {
        name:"CABLE BATTERIE 2x2 M",
        price:18.90,
        type:"câbles et accessoires",
        prop:["Marque Pylontech","Taille de section 25 mm²","Cosses M8","Couleur NOIR - ROUGE","Garantie distributeur 1 an, magasin 1 an"],
        img:"/imgproduct-view33@2x.png"
    },
    {
        name:"Q CABLE 1.3 M",
        price:19.78,
        type:"câbles et accessoires",
        prop:["Marque ENPHASE","1 câble par micro-onduleur","Pour PV posés en portrait","Pour IQ7 et IQ8","Garantie distributeur 2 ans, magasin 2 ans"],
        img:"/cable1.png"
    },
    {
        name:"Q CABLE 2 M",
        price:36.69,
        type:"câbles et accessoires",
        prop:["Marque ENPHASE","1 câble par micro-onduleur","Pour PV posés en portrait","Pour IQ7 et IQ8","Garantie distributeur 2 ans, magasin 2 ans"],
        img:"/cable2.png"
    },
    {
        name:"EMBOUT TERMINAISON MONO",
        price:19.44,
        type:"câbles et accessoires",
        prop:["Marque ENPHASE","Installation facile","Etanche et résistant aux intempéries","Pour IQ7 et IQ8","Garantie distributeur 1 an, magasin 2 ans"],
        img:"/cable3.png"
    },
    {
        name:"EMBOUT TERMINAISON TRI",
        price:20.28,
        type:"câbles et accessoires",
        prop:["Marque ENPHASE","Installation facile","Etanche et résistant aux intempéries","Pour IQ7","Garantie distributeur 2 ans, magasin 2 ans"],
        img:"/cable4.png"
    },
    {
        name:"CONNECTEUR FEMELLE",
        price:14.33,
        type:"câbles et accessoires",
        prop:["Marque ENPHASE","Installation facile","Etanche et résistant aux intempéries","Pour IQ7 et IQ8","Garantie distributeur 1 an, magasin 2 ans"],
        img:"/cable5.png"
    },
    {
        name:"OUTIL DE DECONNEXION",
        price:3.41,
        type:"câbles et accessoires",
        prop:["Marque ENPHASE","Utilisation simple","Déconnecte facilement","Pour IQ7 et IQ8","Garantie distributeur 1 an, magasin 1 an"],
        img:"/cable6.png"
    },
    {
        name:"CONNECTEUR MALE",
        price:14.33,
        type:"câbles et accessoires",
        prop:["Marque ENPHASE","Installation facile","Etanche et résistant aux intempéries","Pour IQ7 et IQ8","Garantie distributeur 1 an, magasin 2 ans"],
        img:"/cable7.png"
    },
    {
        name:"PASSERELLE ENVOY-S",
        price:419.80,
        type:"câbles et accessoires",
        prop:["Marque ENPHASE","Jusqu’à 600 micro-onduleurs","Fonction zéro injection sur le réseau","Pour IQ7 et IQ8","Garantie distributeur 5 ans, magasin 1 an"],
        img:"/cable8.png"
    },
    {
        name:"CABLE SOLAIRE 2x1 M",
        price:10.73,
        type:"câbles et accessoires",
        prop:["Taille de section 4 mm²","Cosses MC4","Couleur NOIR - ROUGE","Valeurs max.: 26 A - 1200 V - 120 °C","Garantie distributeur 1 an, magasin 1 an"],
        img:"/imgproduct-view34@2x.png"
    },
    {
        name:"ALLONGE CABLE SOLAIRE 2x1 M",
        price:11.64,
        type:"câbles et accessoires",
        prop:["Taille de section 4 mm²","Cosses MC4","Couleur NOIR - ROUGE","Valeurs max.: 26 A - 1200 V - 120 °C","Garantie distributeur 1 an, magasin 1 an"],
        img:"/imgproduct-view35@2x.png"
    },
    {
        name:"CONNECTEURS MC4",
        price:2.87,
        type:"câbles et accessoires",
        prop:["Taille de section 4-10 mm²","Couleur NOIR","Garantie distributeur 1 an, magasin 1 an"],
        img:"/imgproduct-view36@2x.png"
    },
    {
        name:"CONNECTEURS MC4 DOUBLE",
        price:8.45,
        type:"câbles et accessoires",
        prop:["Voltage supporté de 1000 V DC","Taille de section 4 mm²","Capacité maximale supportée de 30 A","Couleur NOIR","Garantie distributeur 1 an, magasin 1 an"],
        img:"/imgproduct-view37@2x.png"
    },
    {
        name:"CONNECTEURS MC4 TRIPLE",
        price:14.77,
        type:"câbles et accessoires",
        prop:["Voltage supporté de 1000 V DC","Taille de section 4 mm²","Capacité maximale supportée de 30 A","Couleur NOIR","Garantie distributeur 1 an, magasin 1 an"],
        img:"/imgproduct-view38@2x.png"
    },
    {
        name:"ALLONGE CABLE SOLAIRE 2x5 M",
        price:23.25,
        type:"câbles et accessoires",
        prop:["Taille de section 4 mm²","Cosses MC4","Couleur NOIR - ROUGE","Valeurs max.: 26 A - 1200 V - 120 °C","Garantie distributeur 1 an, magasin 1 an"],
        img:"/imgproduct-view39@2x.png"
    },
    {
        name:"ALLONGE CABLE SOLAIRE 2x5 M",
        price:28.89,
        type:"câbles et accessoires",
        prop:["Taille de section 6 mm²","Cosses MC4","Couleur NOIR - ROUGE","Valeurs max.: 33 A - 1200 V - 120 °C","Garantie distributeur 1 an, magasin 2 an"],
        img:"/imgproduct-view40@2x.png"
    },
    {
        name:"CABLE VE.CAN > CAN-BUS 1.8 M",
        price:17.17,
        type:"câbles et accessoires",
        prop:["Type B","Couleur BLEU","Garantie distributeur 1 an, magasin 1 an"],
        img:"/imgproduct-view41@2x.png"
    },
    {
        name:"CABLE VE.DIRECT 1.8 M",
        price:16.17,
        type:"câbles et accessoires",
        prop:["Type USB","Couleur NOIR","Garantie distributeur 5 ans, magasin 2 ans"],
        img:"/imgproduct-view42@2x.png"
    },
    {
        name:"CABLE UTP RJ45 0.9 M",
        price:9.08,
        type:"câbles et accessoires",
        prop:["Pour VE.CAN, VE.BUS, VE.NET","Couleur BLEU","Garantie distributeur 5 ans, magasin 2 ans"],
        img:"/imgproduct-view43@2x.png"
    },
    {
        name:"INTERFACE MK3-USB",
        price:78.54,
        type:"câbles et accessoires",
        prop:["Communication entre VE.BUS et PC USB","Couleur NOIR","Garantie distributeur 5 ans, magasin 2 ans"],
        img:"/imgproduct-view44@2x.png"
    },
    {
        name:"COFFRET AC 3 kW 20 A",
        price:121.09,
        type:"câbles et accessoires",
        prop:["Marque Technideal","Différentiel 25 A 30 mA 240 V","Disjoncteur phase neutre 16 A 3 KVA 230 V","Parafoudre monophasé DS215-230/G CITEL","Garantie magasin 2 ans"],
        img:"/imgproduct-view45@2x.png"
    },
    {
        name:"COFFRET AC 3 kW 20 A + BACK UP",
        price:682.55,
        type:"câbles et accessoires",
        prop:["Marque Technideal","Pour Hybrid Sofar-HYD","Disjoncteur DPN 2A, 16A et 20 A","Inter Diff 20A Type Ai et 25 A Type B","Parafoudre type 2","Poids net de 0.8 kg"],
        img:"/cable9.png"
    },
    {
        name:"COFFRET AC 9 kW TRIPHASE",
        price:590.38,
        type:"câbles et accessoires",
        prop:["Marque Technideal","26 modules IP65 - IK08","Disjoncteur 4x20A","Interdifférentiel 4P 25A 30mA","Disjoncteur 1P+N 16A - 30mA","Parafoudre tétra type II - 400V - In 20kA","Garantie magasin de 2 ans"],
        img:"/cable10.png"
    },
    {
        name:"COFFRET DC 3 kW ",
        price:138.52,
        type:"câbles et accessoires",
        prop:["Marque Technideal","Interrupteur 25 A 600 VDC","Parafoudre DC","MPPT","Poids net de 1.1 kg"],
        img:"/cable11.png"
    },
    {
        name:"COFFRET DC 600 V  15 KA",
        price:281.05,
        type:"câbles et accessoires",
        prop:["Marque Technideal","Interrupteur sectionneur 600 VDC 25 A","2 fusible 32 A","2 MPPT","Poids net de 0.8 kg"],
        img:"/cable12.png"
    },
    {
        name:"COFFRET BATTERIE 125 A ",
        price:142.35,
        type:"câbles et accessoires",
        prop:["Marque Technideal","Interrupteur sectionneur Bi DC 125 A","Etanche","Section câble max de 25mm²","Poids net de 0.8 kg"],
        img:"/cable13.png"
    },
    {
        name:"TRANSFO COURANT ECU",
        price:12.46,
        type:"câbles et accessoires",
        prop:["Marque Apsystems","Pour ECU-C - APS","Un Tore de 80 A pour réseau PV","Jusqu’à 18 kWc (mono) ou 54 kWc (triphasé)","Garantie distributeur 2 ans, magasin 1 an"],
        img:"/cable14.png"
    },
    {
        name:"TRANSFO COURANT CT100 SPLIT",
        price:42.83,
        type:"câbles et accessoires",
        prop:["Marque Enphase","Taille de 3 M","Nécessite un Envoy-S Metered","Section du câble de 1.5mm²","Garantie distributeur 1 an, magasin 1 an"],
        img:"/cable15.png"
    },
    {
        name:"MEGA FUSIBLE 125 A/58 V",
        price:31.62,
        type:"câbles et accessoires",
        prop:["Marque Victron Energy","Protection 125 A / 58 V","Pour batterie 48 V","Cosses plates percées M8","Garantie distributeur 2 ans, magasin 2 ans"],
        img:"/imgproduct-view46@2x.png"
    },
    {
        name:"MEGA FUSIBLE 250 A/58 V",
        price:31.62,
        type:"câbles et accessoires",
        prop:["Marque Victron Energy","Protection 250 A / 58 V","Pour batterie 48 V","Cosses plates percées M8","Garantie distributeur 2 ans, magasin 2 ans"],
        img:"/imgproduct-view46@2x.png"
    },
    {
        name:"SUPERVISION PV",
        price:312.20,
        type:"câbles et accessoires",
        prop:["Marque Victron Energy","Supervision totale du système","Rendements maximisés","Poids net 0.49 kg","Garantie distributeur 5 ans, magasin 2 ans"],
        img:"/imgproduct-view47@2x.png"
    }
]