import "./WebMainInvestmentProjectLi.css";
import DrawerMenuDrawerItem from "../components/DrawerMenuDrawerItem";
import { useNavigate } from "react-router-dom";
import React, { useState, useCallback, useEffect } from "react";
import PopupinfoNetworkType from "../components/PopupinfoNetworkType";
import PortalPopup from "../components/PortalPopup";
import { apiNodeService } from "../provider/apiNodeService";
import InfoIcon from '@mui/icons-material/Info';
import FooterMobile from "../components/FooterMobile";
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DrawerMenuMobile from "../components/DrawerMenuMobile";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Alert from '@mui/material/Alert';
import "./Add_Projet.css"
import TextField from '@mui/material/TextField';
import { useRef } from "react";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import utilFunctions from "../tools/functions";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import countries from "../data/countries";
import secteurs from "../data/secteurs";
import MuiBackdrop from "../components/Backdrop/MuiBackdrop";
import HeaderMobile from "../components/HeaderMobile";
import FooterDesktop from "../components/FooterDesktop";
import  impacts  from "../data/impacts";
import  impacts_mesure  from "../data/impacts_mesure";
import HeaderDesktop from "../components/HeaderDesktop";

const Add_Projet = () => {

  const navigate = useNavigate();

  const [isDrawerMenuDrawerItemPopupOpen, setDrawerMenuDrawerItemPopupOpen] = useState(false);
  const [isDrawerMenuMobileOpen, setDrawerMenuMobileOpen] = useState(false);
  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);

  const [selectedFileProjet, setSelectedFileProjet] = useState([]);
  const [fileNameProjet, setFileNameProjet] = useState([]);
  const [selectedFileProjet1, setSelectedFileProjet1] = useState([]);
  const [fileNameProjet1, setFileNameProjet1] = useState([]);

  const [fileNamePorteur, setFileNamePorteur] = useState([]);
  const [selectedFilePorteur, setSelectedFilePorteur] = useState([]);

  const [titleProjet, setTitleProjet] = useState("");
  const [descriptionProjet, setDescriptionProjet] = useState("");
  const [typeProjet, setTypeProjet] = useState("");
  const [localisationProjet, setLocalisationProjet] = useState("");

  const [montantProjet, setMontantProjet] = useState("");
  const [dateFin, setDateFin] = React.useState('')

  const [taux, setTaux] = React.useState('')
  const [typeAction, setTypeAction] = React.useState('')

  const [descriptionDetailleProjet, setDescriptionDetailleProjet] = React.useState('')
  const [titlePorteur, setTitlePorteur] = useState("");
  const [descriptionPorteur, setDescriptionPorteur] = useState("");

  const [anneeExp, setAnneeExp] = useState("");
  const [checked, setChecked] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [paysPorteur, setPaysPorteur] = useState([]);
  const [secteurPorteur, setSecteurPorteur] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [impact, setImpact] = useState([]);
  const [impactsMesure, setImpactsMesure] = useState([]);

  useEffect(() => {

  }, []);

  const addImages = (uid,fileType,b64,fileName) => {
    let data = {
        file_type: fileType,
        name: fileName,
        uid: uid,
        b64: b64,
        created_by: localStorage.getItem('email') ? localStorage.getItem('email') : "not connected"
    }
    apiNodeService.insertTableData({ db: "CREATIVE_MINDS", table: "documents", data: data }).then(res => {
        console.log(res)
        if (res && res.status === 200) {
            console.log(true)
        } else {
            console.log(false)
        }
    })
}

  const addProjet = () => {
    console.log(paysPorteur)
    let uid1 = utilFunctions.getUID()
    let uid2 = utilFunctions.getUID()
    let uid3 = utilFunctions.getUID()
    console.log(uid1)
    console.log(secteurPorteur)
    if (
      selectedFileProjet.length === 0 ||
      titleProjet === "" ||
      descriptionProjet === "" ||
      typeProjet === "" ||
      localisationProjet === "" ||
      montantProjet === "" ||
      dateFin === "" ||
      taux === "" ||
      typeAction === "" ||
      descriptionDetailleProjet === "" ||
      titlePorteur === "" ||
      descriptionPorteur === "" ||
      impact.length === 0 ||
      impactsMesure.length === 0
    ) {
      setIsFilled(true)
      console.log("completez les champs obligatoires*")
    } else {
      console.log("done")
      setIsFilled(false)
      setLoading(true)
      let data = {
        active: true ,
        adress: {
          adr: localisationProjet ,
          commune: localisationProjet,
          departement: "Var" ,
          maps_url: "https://goo.gl/maps/NYj1PYEpg57SRcKv8, " ,
          pays: localisationProjet ,
          region: "PACA"
        } ,
        capacite_watt: parseInt(montantProjet) ,
        emplacement: localisationProjet,
        image: selectedFileProjet.length > 0 ? uid1 : "" ,
        image1: selectedFileProjet1.length > 0 ? uid2 : "" ,
        investors: [] ,
        minimum_invest_ticket: 10 ,
        nb_cellule_sol: parseInt(parseInt(montantProjet) / 4) ,
        nb_panneaux: parseInt(parseInt(montantProjet) / 480) ,
        nb_watt_used: 0 ,
        soc: {
        "id": "08163124-c457-4233-99aa-cd63b70af0a0" ,
        "name": "Treeporteur"
        } ,
        title: titleProjet ,
        utilisation: "Résidentiel" ,
        year_creation: moment().format('YYYY-MM-DD'),
        description:descriptionProjet,
        description1:descriptionDetailleProjet,
        taux:taux,
        annee_exp:anneeExp,
        porteur:titlePorteur,
        description_porteur:descriptionPorteur,
        date_fin:dateFin,
        type_action:typeAction,
        type_projet:typeProjet,
        pays_porteur:paysPorteur,
        secteur_porteur:secteurPorteur,
        logo_porteur:selectedFilePorteur.length > 0 ? uid3 : "",
        impact:impact,
        impact_mesure:impactsMesure
      }
      selectedFileProjet.map((element,key)=>{
        addImages(uid1,"projet",element,fileNameProjet[key])
      })
      selectedFileProjet1.map((element,key)=>{
        addImages(uid2,"projet_detail",element,fileNameProjet1[key])
      })
      selectedFilePorteur.map((element,key)=>{
        addImages(uid3,"porteur",element,fileNamePorteur[key])
      })

      apiNodeService.insertTableData({ db: "CREATIVE_MINDS", table: "investProject", data: data }).then(res => {
        console.log(res)
        if (res && res.status === 200) {
           setLoading(false)
           navigate('/web-invest')
        } else {
            console.log("erreur")
            setLoading(false)
        }
    })
    }
  }

  const handleFileChange = async (event) => {
    const files = Object.values(event.target.files);
    let filenames = []
    let filesproject = []
    files.map(async(file,key)=>{
      if (file && key < 5) {
      let thumbnail = await utilFunctions.toBase64(file)
      filenames.push(file.name)
      filesproject.push(thumbnail)
      setFileNameProjet(filenames)
      setSelectedFileProjet(filesproject);
    } else {
      console.log("No file selected!");
    }
    })
  };

  const handleFileChange1 = async (event) => {
    const files = Object.values(event.target.files);
    console.log(files)
    let filenames = []
    let filesproject = []
    files.map(async(file,key)=>{
      if (file && key < 5) {
      let thumbnail = await utilFunctions.toBase64(file)
      filenames.push(file.name)
      filesproject.push(thumbnail)
      setFileNameProjet1(filenames)
      setSelectedFileProjet1(filesproject);
    } else {
      console.log("No file selected!");
    }
    })
  };

  const handleFileChange2 = async (event) => {
    const files = Object.values(event.target.files);
    console.log(files)
    let filenames = []
    let filesproject = []
    files.map(async(file,key)=>{
      if (file && key < 5) {
      let thumbnail = await utilFunctions.toBase64(file)
      filenames.push(file.name)
      filesproject.push(thumbnail)
      setFileNamePorteur(filenames)
      setSelectedFilePorteur(filesproject);
    } else {
      console.log("No file selected!");
    }
    })
  };

  const handleCheck = (event) => {
    setChecked(event.target.checked);
  };

  const openDrawerMenuDrawerItemPopup = useCallback(() => {
    setDrawerMenuDrawerItemPopupOpen(true);
  }, []);

  const closeDrawerMenuDrawerItemPopup = useCallback(() => {
    setDrawerMenuDrawerItemPopupOpen(false);
  }, []);



  const openDrawerMenuMobile = useCallback(() => {
    setDrawerMenuMobileOpen(true);
  }, []);

  const closeDrawerMenuMobile = useCallback(() => {
    setDrawerMenuMobileOpen(false);
  }, []);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleButtonClick1 = () => {
    fileInputRef1.current.click();
  };

  const handleButtonClick2 = () => {
    fileInputRef2.current.click();
  };

  return (
    <>
      <MuiBackdrop
          open={loading}
        />
      <div className="web-main-investment-project-li">
        <HeaderDesktop />
        <div className="desktop-padding-add">
          <Alert
            iconMapping={{
              info: <InfoOutlinedIcon style={{ color: "#18328F" }} />,
            }}
            style={{ border: "2px solid #18328F", backgroundColor: "#E9F4FF", borderRadius: 10, alignItems: 'center', fontFamily: "Plus Jakarta Sans" }} severity="info">
            Décrivez votre projet dans les moindres détails afin de mettre toutes les chances de votre cotés pour attirer un maximum d’investisseurs.
          </Alert>
          <div className="p-3">
            <div className="d-flex flex-column">
              <span className="span-projet-title">VUE GÉNÉRALE DU PROJET</span>
              <span className="span-projet-input mt-3">Titre du projet*</span>
              <TextField value={titleProjet} error={titleProjet.length > 150} onChange={(e) => { setTitleProjet(e.target.value) }} className="mt-2" id="outlined-basic" size="small" variant="outlined" />
              <span className="span-projet-number">{titleProjet.length} / 150 caractères</span>
              <span className="span-projet-subdesc">Un bon titre c’est quelques mots pour décrire précisément l’objectif de votre projet.</span>
              <span className="span-projet-input mt-3">Image(s) de présentation du projet*</span>

              <div className="mt-2">
                <input
                  type="file"
                  accept='.jpg, .png, .jpeg'
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                <div className="upload-formation align-items-center p-2" onClick={handleButtonClick}>
                  <div className="d-flex flex-column justify-content-center">
                    <ImageOutlinedIcon className="align-self-center" style={{ width: 70, height: 70,color:"#477520" }} />
                    { fileNameProjet.length === 0 ?
                      <span style={{ fontWeight: 500, textAlign: 'center', fontSize: 14 }}>
                      Cliquez ici pour importer une image à
                      partir de votre téléphone</span>
                      : 
                      <div className="d-flex flex-column" style={{gap:5}}>
                        {fileNameProjet.map((item,key)=>(
                          <span key={key} style={{ fontWeight: 500, textAlign: 'center', fontSize: 14 }}>
                          {item}
                          </span>
                        ))
                        }
                      </div>
                        }
                  </div>
                </div>
              </div>

              <span className="span-projet-subdesc">Choisissez des images représentant de façon simple et claire l’idée de votre projet.</span>

              <span className="span-projet-input mt-3">Description courte du projet*</span>
              <TextField multiline minRows={3} value={descriptionProjet} error={descriptionProjet.length > 150} onChange={(e) => { setDescriptionProjet(e.target.value) }} className="mt-2" id="outlined-basic" size="small" variant="outlined" />
              <span className="span-projet-number">{descriptionProjet.length} / 1000 caractères</span>
              <span className="span-projet-subdesc">Cette description sera visible sur la miniature dans la liste des projets.</span>

              <span className="span-projet-input mt-3">Catégorie du projet *</span>
              <Select
                className='mt-1'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={typeProjet}
                style={{ height: 40 }}
                size="small"
                onChange={(e) => {
                  setTypeProjet(e.target.value)
                }}
                fullWidth
              >
                <MenuItem value={"Alimentation"}>Alimentation</MenuItem>
                <MenuItem value={"Sylviculture"}>Sylviculture</MenuItem>
                <MenuItem value={"Tourisme"}>Tourisme</MenuItem>
                <MenuItem value={"Apiculture"}>Apiculture</MenuItem>
                <MenuItem value={"L’économie bleu"}>L’économie bleu</MenuItem>
                <MenuItem value={"Batîments écologiques"}>Batîments écologiques</MenuItem>
                <MenuItem value={"Énergies renouvelables"}>Énergies renouvelables</MenuItem>
                <MenuItem value={"Transport & Logistique"}>Transport & Logistique</MenuItem>
              </Select>

              <span className="span-projet-input mt-3">Impact(s) du projet*</span>

              <Autocomplete
                multiple
                id="size-small-outlined-multi"
                size="small"
                onChange={(event, newValue) => {
                  setImpact(newValue)
              }}
                options={impacts.impacts}
                getOptionLabel={(option) => option.label}
                defaultValue={[]}
                renderInput={(params) => (
                  <TextField {...params} />
                )}
              />

<span className="span-projet-input mt-3">Mesure des impacts*</span>

<Autocomplete
  multiple
  id="size-small-outlined-multi"
  size="small"
  onChange={(event, newValue) => {
    setImpactsMesure(newValue)
}}
  options={impacts_mesure.impacts_mesure}
  getOptionLabel={(option) => option.label}
  defaultValue={[]}
  renderInput={(params) => (
    <TextField {...params} />
  )}
/>

              <span className="span-projet-input mt-3">Localisation du projet*</span>
              {/* <TextField value={localisationProjet} onChange={(e) => { setLocalisationProjet(e.target.value) }} className="mt-2" id="outlined-basic" size="small" variant="outlined" /> */}
              <Autocomplete
                
                id="size-small-outlined-multi"
                size="small"
                options={countries.countries}
                onChange={(event, newValue) => {
                  setLocalisationProjet(newValue.label)
              }}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} />
                )}
              />
              <span className="span-projet-subdesc">Indiquez l’endroit où se situera le projet une fois terminé.</span>

              <span className="span-projet-title mt-4">DÉTAILS DU PROJET</span>

              <span className="span-projet-input mt-3">Montant à collecter souhaité (en €)*</span>
              <TextField value={montantProjet} type="number" onChange={(e) => { setMontantProjet(e.target.value) }} className="mt-2" id="outlined-basic" size="small" variant="outlined" />
              <span className="span-projet-subdesc">Le montant doit être réaliste et devra être justifié en cas de demande par notre équipe.</span>

              <span className="span-projet-input mt-3">Date finale de collecte*</span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker format="YYYY-MM-DD" value={dayjs(dateFin)} onChange={(e) => {
                    if (e) {
                      setDateFin(moment(e.$d).format('YYYY-MM-DD'))
                    }

                  }} />
                </DemoContainer>
              </LocalizationProvider>

              <span className="span-projet-subdesc">La date finale de collecte doit être assez  longue pour pouvoir atteindre le  montant à collecter souhaité.</span>

              <span className="span-projet-input mt-3">Taux d’intérêts annuel (en %)*</span>
              <TextField value={taux} placeholder="4%" type="number" onChange={(e) => { setTaux(e.target.value) }} className="mt-2" id="outlined-basic" size="small" variant="outlined" />
              <span className="span-projet-subdesc">Le taux d’intérêts annuel doit être attirant pour donner envie aux investisseurs de vous soutenir dans votre projet.</span>

              <span className="span-projet-input mt-3">Type d’action*</span>
              <Select
                className='mt-1'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={typeAction}
                style={{ height: 40 }}
                size="small"
                onChange={(e) => {
                  setTypeAction(e.target.value)
                }}
                fullWidth
              >
                <MenuItem value={"Prêt (Crowdlending)"}>Prêt (Crowdlending)</MenuItem>
                
                <MenuItem value={"Obligations convertibles"}>Obligations convertibles</MenuItem>
                <MenuItem value={"Donations sans contrepartie"}>Donations sans contrepartie</MenuItem>
                <MenuItem value={"Financement basé sur l'impact social ou environnemental"}>Financement basé sur l'impact social ou environnemental</MenuItem>
                <MenuItem value={"Récompenses ou contreparties matérielles"}>Récompenses ou contreparties matérielles</MenuItem>
                <MenuItem value={"Equity Crowdfunding"}>Equity Crowdfunding</MenuItem>
                <MenuItem value={"Royalties ou revenus partagés"}>Royalties ou revenus partagés</MenuItem>
                <MenuItem value={"Précommandes"}>Précommandes</MenuItem>

              </Select>
              <span className="span-projet-subdesc">Le type d’action permet aux investisseurs de savoir sous quelle forme légale se fera le procédé. Pour en savoir plus sur les différents types d’action, <b>cliquez ici</b>.</span>

              <span className="span-projet-title mt-4">DESCRIPTION DU PROJET</span>

              <span className="span-projet-input mt-3">Image(s) détaillée(s) du projet</span>

              <div className="mt-2">
                <input
                  type="file"
                  multiple
                  accept='.jpg, .png, .jpeg'
                  ref={fileInputRef1}
                  style={{ display: 'none' }}
                  onChange={handleFileChange1}
                />
                <div className="upload-formation align-items-center p-2" onClick={handleButtonClick1}>
                  <div className="d-flex flex-column justify-content-center">
                    <ImageOutlinedIcon className="align-self-center" style={{ width: 70, height: 70,color:"#477520" }} />
                    { fileNameProjet1.length === 0 ?
                      <span style={{ fontWeight: 500, textAlign: 'center', fontSize: 14 }}>
                      Cliquez ici pour importer une image à
                      partir de votre téléphone</span>
                      : 
                      <div className="d-flex flex-column" style={{gap:5}}>
                        {fileNameProjet1.map((item,key)=>(
                          <span key={key} style={{ fontWeight: 500, textAlign: 'center', fontSize: 14 }}>
                          {item}
                          </span>
                        ))
                        }
                      </div>
                        }
                  </div>
                </div>
              </div>

              <span className="span-projet-subdesc">Il peut être intéressant d’ajouter des images décrivant plus en détail votre projet tel que son fonctionnement, son cycle de vie, ses prototypes etc...</span>

              <span className="span-projet-input mt-3">Description complète du projet*</span>
              <TextField multiline minRows={3} value={descriptionDetailleProjet} error={descriptionDetailleProjet.length > 1000} onChange={(e) => { setDescriptionDetailleProjet(e.target.value) }} className="mt-2" id="outlined-basic" size="small" variant="outlined" />
              <span className="span-projet-number">{descriptionDetailleProjet.length} / 1000 caractères</span>
              <span className="span-projet-subdesc">Cette description doit être la plus précise possible afin qu’un investisseur soit capable, après lecture, de savoir exactement l’objectif du projet, son fonctionnement, sa mise en place, ses freins ou potentiels futurs problèmes et ses avantages.</span>

              <span className="span-projet-title mt-4">PORTEUR DU PROJET</span>

              <span className="span-projet-input mt-3">Nom du porteur de projet*</span>
              <TextField value={titlePorteur} error={titlePorteur.length > 150} onChange={(e) => { setTitlePorteur(e.target.value) }} className="mt-2" id="outlined-basic" size="small" variant="outlined" />
              <span className="span-projet-number">{titlePorteur.length} / 150 caractères</span>
              <span className="span-projet-subdesc">Le porteur du projet peut être un particulier ou une personne morale.</span>
              <span className="span-projet-input mt-3">Avatar ou logo du porteur de projet</span>

              <div className="mt-2">
                <input
                  type="file"
                  accept='.jpg, .png, .jpeg'
                  ref={fileInputRef2}
                  style={{ display: 'none' }}
                  onChange={handleFileChange2}
                />
                <div className="upload-formation align-items-center p-2" onClick={handleButtonClick2}>
                  <div className="d-flex flex-column justify-content-center">
                    <ImageOutlinedIcon className="align-self-center" style={{ width: 70, height: 70,color:"#477520" }} />
                    { fileNamePorteur.length === 0 ?
                      <span style={{ fontWeight: 500, textAlign: 'center', fontSize: 14 }}>
                      Cliquez ici pour importer une image à
                      partir de votre téléphone</span>
                      : 
                      <div className="d-flex flex-column" style={{gap:5}}>
                        {fileNamePorteur.map((item,key)=>(
                          <span key={key} style={{ fontWeight: 500, textAlign: 'center', fontSize: 14 }}>
                          {item}
                          </span>
                        ))
                        }
                      </div>
                        }
                  </div>
                </div>
              </div>

              <span className="span-projet-input mt-3">Description du porteur de projet*</span>
              <TextField multiline minRows={3} value={descriptionPorteur} error={descriptionPorteur.length > 1000} onChange={(e) => { setDescriptionPorteur(e.target.value) }} className="mt-2" id="outlined-basic" size="small" variant="outlined" />
              <span className="span-projet-number">{descriptionPorteur.length} / 1000 caractères</span>
              <span className="span-projet-subdesc">Décrivez le porteur du projet en mettant en avant toutes ces précédentes réalisations ou projets, ces valeurs et tout ce qui pourrait montrer la bonne foie de ce dernier.</span>

              <span className="span-projet-input mt-3">Années depuis la  création de l’entité ou année(s) d’expérience(s) dans ce domaine*</span>
              <TextField value={anneeExp} type="number" onChange={(e) => { setAnneeExp(e.target.value) }} className="mt-2" id="outlined-basic" size="small" variant="outlined" />

              <span className="span-projet-input mt-3">Pour une personne morale, les différent(s) pays d’implantation</span>

              <Autocomplete
                multiple
                id="size-small-outlined-multi"
                size="small"
                options={countries.countries}
                onChange={(event, newValue) => {
                  setPaysPorteur(newValue)
              }}
                getOptionLabel={(option) => option.label}
                defaultValue={[]}
                renderInput={(params) => (
                  <TextField {...params} />
                )}
              />

              <span className="span-projet-input mt-3">Pour une personne morale, les différent(s) secteur(s) d’activité</span>

              <Autocomplete
                multiple
                id="size-small-outlined-multi"
                size="small"
                onChange={(event, newValue) => {
                  setSecteurPorteur(newValue)
              }}
                options={secteurs.secteurs}
                getOptionLabel={(option) => option.label}
                defaultValue={[]}
                renderInput={(params) => (
                  <TextField {...params} />
                )}
              />

              <div className="d-flex align-items-center mt-5">
                <Checkbox
                  checked={checked}
                  color="success"
                  onChange={handleCheck}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <span className="span-projet-input">En cochant cette case, je déclare et confirme que les informations que j'ai fournies sont complètes, véridiques et valides.
                  Je comprends que ces informations sont utilisées publiquement  dans le cadre de la publication d’un projet de financement participatif sur la plateforme E+C-.
                  Je reconnais que la fourniture d'informations incorrectes ou frauduleuses peut entraîner des conséquences, y compris la résiliation de mon compte.</span>
              </div>

              <button onClick={(e) => { addProjet() }} className="btn-add-projet align-self-center mt-5">
                Publier le projet
              </button>

            </div>
          </div>
        </div>
        <FooterDesktop />
        {isDrawerMenuDrawerItemPopupOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Top center"
            onOutsideClick={closeDrawerMenuDrawerItemPopup}
          >
            <DrawerMenuDrawerItem onClose={closeDrawerMenuDrawerItemPopup} />
          </PortalPopup>
        )}
      </div>
      <div className="web-invest-mobile" style={{ marginTop: 80, marginBottom: 100 }}>
        
        <HeaderMobile isHome={false} title={"Création d’un projet"} />

        <div>
          <Alert
            iconMapping={{
              info: <InfoOutlinedIcon style={{ color: "#18328F" }} />,
            }}
            style={{ border: "2px solid #18328F", backgroundColor: "#E9F4FF", borderRadius: 10, alignItems: 'center', fontFamily: "Plus Jakarta Sans" }} severity="info">
            Décrivez votre projet dans les moindres détails afin de mettre toutes les chances de votre cotés pour attirer un maximum d’investisseurs.
          </Alert>
          <div className="p-3">
            <div className="d-flex flex-column">
              <span className="span-projet-title">VUE GÉNÉRALE DU PROJET</span>
              <span className="span-projet-input mt-3">Titre du projet*</span>
              <TextField value={titleProjet} error={titleProjet.length > 150} onChange={(e) => { setTitleProjet(e.target.value) }} className="mt-2" id="outlined-basic" size="small" variant="outlined" />
              <span className="span-projet-number">{titleProjet.length} / 150 caractères</span>
              <span className="span-projet-subdesc">Un bon titre c’est quelques mots pour décrire précisément l’objectif de votre projet.</span>
              <span className="span-projet-input mt-3">Image(s) de présentation du projet*</span>

              <div className="mt-2">
                <input
                  type="file"
                  accept='.jpg, .png, .jpeg'
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                <div className="upload-formation align-items-center p-2" onClick={handleButtonClick}>
                  <div className="d-flex flex-column justify-content-center">
                    <ImageOutlinedIcon className="align-self-center" style={{ width: 70, height: 70,color:"#477520" }} />
                    { fileNameProjet.length === 0 ?
                      <span style={{ fontWeight: 500, textAlign: 'center', fontSize: 14 }}>
                      Cliquez ici pour importer une image à
                      partir de votre téléphone</span>
                      : 
                      <div className="d-flex flex-column" style={{gap:5}}>
                        {fileNameProjet.map((item,key)=>(
                          <span key={key} style={{ fontWeight: 500, textAlign: 'center', fontSize: 14 }}>
                          {item}
                          </span>
                        ))
                        }
                      </div>
                        }
                  </div>
                </div>
              </div>

              <span className="span-projet-subdesc">Choisissez des images représentant de façon simple et claire l’idée de votre projet.</span>

              <span className="span-projet-input mt-3">Description courte du projet*</span>
              <TextField multiline minRows={3} value={descriptionProjet} error={descriptionProjet.length > 1000} onChange={(e) => { setDescriptionProjet(e.target.value) }} className="mt-2" id="outlined-basic" size="small" variant="outlined" />
              <span className="span-projet-number">{descriptionProjet.length} / 1000 caractères</span>
              <span className="span-projet-subdesc">Cette description sera visible sur la miniature dans la liste des projets.</span>

              <span className="span-projet-input mt-3">Catégorie du projet *</span>
              <Select
                className='mt-1'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={typeProjet}
                style={{ height: 40 }}
                size="small"
                onChange={(e) => {
                  setTypeProjet(e.target.value)
                }}
                fullWidth
              >
                <MenuItem value={"Alimentation"}>Alimentation</MenuItem>
                <MenuItem value={"Sylviculture"}>Sylviculture</MenuItem>
                <MenuItem value={"Tourisme"}>Tourisme</MenuItem>
                <MenuItem value={"Apiculture"}>Apiculture</MenuItem>
                <MenuItem value={"L’économie bleu"}>L’économie bleu</MenuItem>
                <MenuItem value={"Batîments écologiques"}>Batîments écologiques</MenuItem>
                <MenuItem value={"Énergies renouvelables"}>Énergies renouvelables</MenuItem>
                <MenuItem value={"Transport & Logistique"}>Transport & Logistique</MenuItem>
              </Select>

              <span className="span-projet-input mt-3">Impact(s) du projet*</span>

              <Autocomplete
                multiple
                id="size-small-outlined-multi"
                size="small"
                onChange={(event, newValue) => {
                  setImpact(newValue)
              }}
                options={impacts.impacts}
                getOptionLabel={(option) => option.label}
                defaultValue={[]}
                renderInput={(params) => (
                  <TextField {...params} />
                )}
              />

<span className="span-projet-input mt-3">Mesure des impacts*</span>

<Autocomplete
  multiple
  id="size-small-outlined-multi"
  size="small"
  onChange={(event, newValue) => {
    setImpactsMesure(newValue)
}}
  options={impacts_mesure.impacts_mesure}
  getOptionLabel={(option) => option.label}
  defaultValue={[]}
  renderInput={(params) => (
    <TextField {...params} />
  )}
/>

              <span className="span-projet-input mt-3">Localisation du projet*</span>
              {/* <TextField value={localisationProjet} onChange={(e) => { setLocalisationProjet(e.target.value) }} className="mt-2" id="outlined-basic" size="small" variant="outlined" /> */}
              <Autocomplete
                
                id="size-small-outlined-multi"
                size="small"
                options={countries.countries}
                onChange={(event, newValue) => {
                  setLocalisationProjet(newValue.label)
              }}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} />
                )}
              />
              <span className="span-projet-subdesc">Indiquez l’endroit où se situera le projet une fois terminé.</span>

              <span className="span-projet-title mt-4">DÉTAILS DU PROJET</span>

              <span className="span-projet-input mt-3">Montant à collecter souhaité (en €)*</span>
              <TextField value={montantProjet} type="number" onChange={(e) => { setMontantProjet(e.target.value) }} className="mt-2" id="outlined-basic" size="small" variant="outlined" />
              <span className="span-projet-subdesc">Le montant doit être réaliste et devra être justifié en cas de demande par notre équipe.</span>

              <span className="span-projet-input mt-3">Date finale de collecte*</span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker format="YYYY-MM-DD" value={dayjs(dateFin)} minDate={dayjs(moment())} onChange={(e) => {
                    if (e) {
                      setDateFin(moment(e.$d).format('YYYY-MM-DD'))
                    }

                  }} />
                </DemoContainer>
              </LocalizationProvider>

              <span className="span-projet-subdesc">La date finale de collecte doit être assez  longue pour pouvoir atteindre le  montant à collecter souhaité.</span>

              <span className="span-projet-input mt-3">Taux d’intérêts annuel (en %)*</span>
              <TextField value={taux} placeholder="4%" type="number" onChange={(e) => { setTaux(e.target.value) }} className="mt-2" id="outlined-basic" size="small" variant="outlined" />
              <span className="span-projet-subdesc">Le taux d’intérêts annuel doit être attirant pour donner envie aux investisseurs de vous soutenir dans votre projet.</span>

              <span className="span-projet-input mt-3">Type d’action*</span>
              <Select
                className='mt-1'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={typeAction}
                style={{ height: 40 }}
                size="small"
                onChange={(e) => {
                  setTypeAction(e.target.value)
                }}
                fullWidth
              >
                <MenuItem value={"Prêt (Crowdlending)"}>Prêt (Crowdlending)</MenuItem>
                <MenuItem value={"Obligations convertibles"}>Obligations convertibles</MenuItem>
                <MenuItem value={"Donations sans contrepartie"}>Donations sans contrepartie</MenuItem>
                <MenuItem value={"Financement basé sur l'impact social ou environnemental"}>Financement basé sur l'impact social ou environnemental</MenuItem>
                <MenuItem value={"Récompenses ou contreparties matérielles"}>Récompenses ou contreparties matérielles</MenuItem>
                <MenuItem value={"Equity Crowdfunding"}>Equity Crowdfunding</MenuItem>
                <MenuItem value={"Royalties ou revenus partagés"}>Royalties ou revenus partagés</MenuItem>
                <MenuItem value={"Précommandes"}>Précommandes</MenuItem>
              </Select>
              <span className="span-projet-subdesc">Le type d’action permet aux investisseurs de savoir sous quelle forme légale se fera le procédé. Pour en savoir plus sur les différents types d’action, <b>cliquez ici</b>.</span>

              <span className="span-projet-title mt-4">DESCRIPTION DU PROJET</span>

              <span className="span-projet-input mt-3">Image(s) détaillée(s) du projet</span>

              <div className="mt-2">
                <input
                  type="file"
                  multiple
                  accept='.jpg, .png, .jpeg'
                  ref={fileInputRef1}
                  style={{ display: 'none' }}
                  onChange={handleFileChange1}
                />
                <div className="upload-formation align-items-center p-2" onClick={handleButtonClick1}>
                  <div className="d-flex flex-column justify-content-center">
                    <ImageOutlinedIcon className="align-self-center" style={{ width: 70, height: 70,color:"#477520" }} />
                    { fileNameProjet1.length === 0 ?
                      <span style={{ fontWeight: 500, textAlign: 'center', fontSize: 14 }}>
                      Cliquez ici pour importer une image à
                      partir de votre téléphone</span>
                      : 
                      <div className="d-flex flex-column" style={{gap:5}}>
                        {fileNameProjet1.map((item,key)=>(
                          <span key={key} style={{ fontWeight: 500, textAlign: 'center', fontSize: 14 }}>
                          {item}
                          </span>
                        ))
                        }
                      </div>
                        }
                  </div>
                </div>
              </div>

              <span className="span-projet-subdesc">Il peut être intéressant d’ajouter des images décrivant plus en détail votre projet tel que son fonctionnement, son cycle de vie, ses prototypes etc...</span>

              <span className="span-projet-input mt-3">Description complète du projet*</span>
              <TextField multiline minRows={3} value={descriptionDetailleProjet} error={descriptionDetailleProjet.length > 1000} onChange={(e) => { setDescriptionDetailleProjet(e.target.value) }} className="mt-2" id="outlined-basic" size="small" variant="outlined" />
              <span className="span-projet-number">{descriptionDetailleProjet.length} / 1000 caractères</span>
              <span className="span-projet-subdesc">Cette description doit être la plus précise possible afin qu’un investisseur soit capable, après lecture, de savoir exactement l’objectif du projet, son fonctionnement, sa mise en place, ses freins ou potentiels futurs problèmes et ses avantages.</span>

              <span className="span-projet-title mt-4">PORTEUR DU PROJET</span>

              <span className="span-projet-input mt-3">Nom du porteur de projet*</span>
              <TextField value={titlePorteur} error={titlePorteur.length > 150} onChange={(e) => { setTitlePorteur(e.target.value) }} className="mt-2" id="outlined-basic" size="small" variant="outlined" />
              <span className="span-projet-number">{titlePorteur.length} / 150 caractères</span>
              <span className="span-projet-subdesc">Le porteur du projet peut être un particulier ou une personne morale.</span>
              <span className="span-projet-input mt-3">Avatar ou logo du porteur de projet</span>

              <div className="mt-2">
                <input
                  type="file"
                  accept='.jpg, .png, .jpeg'
                  ref={fileInputRef2}
                  style={{ display: 'none' }}
                  onChange={handleFileChange2}
                />
                <div className="upload-formation align-items-center p-2" onClick={handleButtonClick2}>
                  <div className="d-flex flex-column justify-content-center">
                    <ImageOutlinedIcon className="align-self-center" style={{ width: 70, height: 70 ,color:"#477520"}} />
                    { fileNamePorteur.length === 0 ?
                      <span style={{ fontWeight: 500, textAlign: 'center', fontSize: 14 }}>
                      Cliquez ici pour importer une image à
                      partir de votre téléphone</span>
                      : 
                      <div className="d-flex flex-column" style={{gap:5}}>
                        {fileNamePorteur.map((item,key)=>(
                          <span key={key} style={{ fontWeight: 500, textAlign: 'center', fontSize: 14 }}>
                          {item}
                          </span>
                        ))
                        }
                      </div>
                        }
                  </div>
                </div>
              </div>
              <span className="span-projet-input mt-3">Description du porteur de projet*</span>
              <TextField multiline minRows={3} value={descriptionPorteur} error={descriptionPorteur.length > 150} onChange={(e) => { setDescriptionPorteur(e.target.value) }} className="mt-2" id="outlined-basic" size="small" variant="outlined" />
              <span className="span-projet-number">{descriptionPorteur.length} / 1000 caractères</span>
              <span className="span-projet-subdesc">Décrivez le porteur du projet en mettant en avant toutes ces précédentes réalisations ou projets, ces valeurs et tout ce qui pourrait montrer la bonne foie de ce dernier.</span>

              <span className="span-projet-input mt-3">Années depuis la  création de l’entité ou année(s) d’expérience(s) dans ce domaine*</span>
              <TextField value={anneeExp} type="number" onChange={(e) => { setAnneeExp(e.target.value) }} className="mt-2" id="outlined-basic" size="small" variant="outlined" />


              <span className="span-projet-input mt-3">Pour une personne morale, les différent(s) pays d’implantation</span>

              <Autocomplete
                multiple
                id="size-small-outlined-multi"
                size="small"
                options={countries.countries}
                onChange={(event, newValue) => {
                  setPaysPorteur(newValue)
              }}
                getOptionLabel={(option) => option.label}
                defaultValue={[]}
                renderInput={(params) => (
                  <TextField {...params} />
                )}
              />

              <span className="span-projet-input mt-3">Pour une personne morale, les différent(s) secteur(s) d’activité</span>

              <Autocomplete
                multiple
                id="size-small-outlined-multi"
                size="small"
                onChange={(event, newValue) => {
                  setSecteurPorteur(newValue)
              }}
                options={secteurs.secteurs}
                getOptionLabel={(option) => option.label}
                defaultValue={[]}
                renderInput={(params) => (
                  <TextField {...params} />
                )}
              />

              <div className="d-flex align-items-center mt-5">
                <Checkbox
                  checked={checked}
                  color="success"
                  onChange={handleCheck}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <span className="span-projet-input">En cochant cette case, je déclare et confirme que les informations que j'ai fournies sont complètes, véridiques et valides.
                  Je comprends que ces informations sont utilisées publiquement  dans le cadre de la publication d’un projet de financement participatif sur la plateforme E+C-.
                  Je reconnais que la fourniture d'informations incorrectes ou frauduleuses peut entraîner des conséquences, y compris la résiliation de mon compte.</span>
              </div>

              <button onClick={(e) => { addProjet() }} className="btn-add-projet align-self-center mt-5">
                Publier le projet
              </button>

            </div>
          </div>
        </div>
        {/* <FooterDesktop /> */}

        <FooterMobile />
      </div>
      {/* {isDrawerMenuMobileOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top center"
          onOutsideClick={closeDrawerMenuMobile}
        >
          <DrawerMenuMobile onClose={closeDrawerMenuMobile} />
        </PortalPopup>
      )} */}
    </>
  );
};

export default Add_Projet;
