import React, { useEffect } from "react";
import FooterMobile from "../components/FooterMobile";
import "./Login.css"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { apiNodeService } from "../provider/apiNodeService";
import MuiBackdrop from "../components/Backdrop/MuiBackdrop";
import { useNavigate } from "react-router-dom";
import Divider from '@mui/material/Divider';
import { useToast } from "@chakra-ui/react";

const Reset_Password = () => {

    const toast = useToast();
    const navigate = useNavigate();

    const [email, setEmail] = React.useState('')

    const [loading, setLoading] = React.useState(false)

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const page = searchParams.get('page');
        console.log(page)
    }, []);

    const resetPassword = () => {
        setLoading(true)
        let data = { email: email, table: 'green_user' }
        apiNodeService.resetPasswordGreen(data).then(res => {
            if (res && res.status === 200) {
                console.log(res)
                setLoading(false)
                toast({
                    title: 'Succès !',
                    description: "Veuillez consulter votre boite email !",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            } else if (res && res.status === 400) {
                toast({
                    title: 'Erreur !',
                    description: "Utilisateur n'existe pas! Veuillez vérifier'",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                setLoading(false)
            } else {
                toast({
                    title: 'Erreur !',
                    description: "Une erreur est survenue, Veuillez réessayer ultérieurement",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }

        })
    }

    return (
        <>
            <div className="login-page">
                <MuiBackdrop
                    open={loading}
                />
                <header className="headerheader-mobile">
                    <div style={{ width: "100%",gap:20 }} className="d-flex align-items-center">
                        <KeyboardBackspaceIcon style={{ cursor: 'pointer' }} fontSize="large" onClick={(e) => { navigate(-1) }} />
                        <span className="span-list-projet-mobile">Mot de passe oublié</span>
                        <span></span>
                    </div>
                </header>
                <div className="p-3 mt-5">
                    <div className="card-etape d-flex flex-column align-self-center p-3 mt-5">
                        <span>Adresse e-mail*</span>
                        <div className="form-group mt-1">
                            <input onChange={(e) => { setEmail(e.target.value) }} type="email" className="form-control is-valid" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                       
                        <div className="d-flex flex-column mt-4">
                            
                            <button onClick={resetPassword} className="login-button mb-3">
                                Valider
                            </button>
                            
                        </div>
                        
                    </div>
                </div>
                <FooterMobile />
            </div>
        </>
    )
}
export default Reset_Password;