import "./HotspotWifiSolaire.css";
import React, { useState, useCallback, useEffect } from "react";
import DrawerMenuDrawerItem from "../components/DrawerMenuDrawerItem";
import PortalPopup from "../components/PortalPopup";
import moment from 'moment';
import { apiNodeService } from "../provider/apiNodeService";
import MuiBackdrop from "../components/Backdrop/MuiBackdrop";
import { useNavigate } from "react-router-dom";
import FooterMobile from "../components/FooterMobile";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DrawerMenuMobile from "../components/DrawerMenuMobile";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ShareIcon from '@mui/icons-material/Share';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import HeaderMobile from "../components/HeaderMobile";
import HeaderDesktop from "../components/HeaderDesktop";
import FooterDesktop from "../components/FooterDesktop";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const HotspotWifiSolaire = () => {

  const navigate = useNavigate();

  const [isDrawerMenuDrawerItemPopupOpen, setDrawerMenuDrawerItemPopupOpen] =
    useState(false);

  const [daysRemaining, setDaysRemaining] = useState(null);
  const [project, setProject] = React.useState({})
  const [loading, setLoading] = React.useState(false);
  const [pourcentage, setPourcentage] = React.useState(0)
  const [isDrawerMenuMobileOpen, setDrawerMenuMobileOpen] = useState(false);
  const [valueTab, setValueTab] = React.useState('one');
  const [emailConcatName, setEmailConcatName] = React.useState('');
  const [b64, setB64] = useState('')
  const [b64logo, setB64logo] = useState('')

  const handleChange = (newValue) => {
    setValueTab(newValue);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const projectId = searchParams.get('project');
    setLoading(true)
    apiNodeService.getProjectById(projectId).then(res => {
      console.log(res)
      if (res) {
        if (res.status === 200) {
          setProject(res.data)
          if (res.data.porteur) {
            getB64(res.data.image)
          }
          if (res.data.logo_porteur) {
            getB64logo(res.data.logo_porteur)
          }
          const concatenatedEmails = res.data.investors.map(obj => obj.email.split('@')[0]).join(', ');
          setEmailConcatName(concatenatedEmails)
          setPourcentage(((parseInt(res.data.nb_watt_used) * 100) / parseInt((res.data.nb_cellule_sol * 4))).toFixed(2))
          const targetDate = res.data.date_fin ? moment(res.data.date_fin) : moment('2024-12-15');
          const currentDate = moment();
          const days = targetDate.diff(currentDate, 'days');
          setDaysRemaining(days);
          setLoading(false)
        } else {
          console.log(res.error)
        }
      }
    })


  }, []);

  const getB64 = async (uid) => {
    setLoading(true)
    return new Promise(resolve => {
      apiNodeService.filterTable({ table: "documents", filter: { uid: uid }, db: "CREATIVE_MINDS" }).then(res => {
        console.log(res)
        if (res && res.data[0] && res !== "false" && Array.isArray(res.data)) {
          setB64(res.data[0].b64)
        }
      }
      )
    }
    )
  }

  const getB64logo = async (uid) => {
    setLoading(true)
    return new Promise(resolve => {
      apiNodeService.filterTable({ table: "documents", filter: { uid: uid }, db: "CREATIVE_MINDS" }).then(res => {
        console.log(res)
        if (res && res.data[0] && res !== "false" && Array.isArray(res.data)) {
          setB64logo(res.data[0].b64 ? res.data[0].b64 : '')
        }
      }
      )
    }
    )
  }

  const openDrawerMenuDrawerItemPopup = useCallback(() => {
    setDrawerMenuDrawerItemPopupOpen(true);
  }, []);

  const closeDrawerMenuDrawerItemPopup = useCallback(() => {
    setDrawerMenuDrawerItemPopupOpen(false);
  }, []);

  const openDrawerMenuMobile = useCallback(() => {
    setDrawerMenuMobileOpen(true);
  }, []);

  const closeDrawerMenuMobile = useCallback(() => {
    setDrawerMenuMobileOpen(false);
  }, []);

  const openNewWindow = (url) => {
    window.open(url, '_blank');
  };

  return (
    <>
      <MuiBackdrop
        open={loading}
      />
      <div className="hotspot-wifi-solaire">

        <HeaderDesktop />


        <div className="divinvestment-item2" style={{ minHeight: 700 }}>
          {project.id && <>
            <div className="d-flex justify-content-start align-items-center align-self-start p-2 mb-1" onClick={(e) => { navigate("/web-invest") }} style={{ gap: 20, cursor: 'pointer', maxWidth: 300 }}>
              <ArrowBackIcon />
              <span className="span-back">Revenir à la liste des projets</span>
            </div>
            <div className="px-4 align-self-start d-flex flex-column" style={{ gap: 20 }}>
              <span className="title-cat-1" style={{ fontSize: 38 }}>{project.title}</span>
              <div className="d-flex align-items-center" style={{ gap: 20 }}>
                <img width={50} src={b64logo !== "" ? b64logo : "/imgproject-hoster2@2x.png"} />
                <span style={{ fontFamily: 'Roboto' }}>Par {project.porteur ? project.porteur : "Treeporteur SAS"}</span>
              </div>
              {project.type_projet === "Transport & Logistique" ? <img width={160} height={30} src="/transport_et_logistique.svg" /> : project.type_projet === "Sylviculture" ? <img width={160} height={30} src="/sylviculture.svg" /> : project.type_projet === "Tourisme" ? <img width={160} height={30} src="/tourisme.svg" /> : project.type_projet === "Apiculture" ? <img width={160} height={30} src="/apiculture.svg" /> : project.type_projet === "L’économie bleu" ? <img width={160} height={30} src="/economie_bleu.svg" /> : project.type_projet === "Batîments écologiques" ? <img width={160} height={30} src="/batiments_ecologiques.svg" /> : project.type_projet === "Énergies renouvelables" ? <img width={160} height={30} src="/energies_renouvelables.svg" /> : <img width={160} height={30} src="/alimentation.svg" />}

            </div>
            <div className="body-detail-projet p-4">
              <div className="row p-2 px-3 align-items-center">
                <div className="col-md-6 mt-5">
                  <div className="d-flex flex-column">
                    <img src={b64 !== "" ? b64 : project.image} />
                    <span style={{ color: "#fff" }} className="p-under-img">Investir présente un risque de perte en capital et de liquidité. N’investissez que l’argent dont vous n’avez pas besoin immédiatement et diversifiez votre épargne.</span>
                  </div>
                </div>
                <div className="col-md-6 mt-4">
                  <div className="d-flex flex-column" style={{ gap: 20 }}>
                    <span className="title-cat-1" style={{ color: "#fff" }}>Investir dans le projet</span>
                    <div className="progress-bar1" style={{ width: "82%" }}>
                      <div className="progress" style={{ border: "1px solid #254952", height: 20, borderRadius: 20 }}>
                        <div className="progress-bar" role="progressbar" style={{ width: `${pourcentage}%`, backgroundColor: "#1EB2A9" }} aria-value={pourcentage} aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end" style={{ width: "82%" }}>
                      <span style={{ color: "#fff" }}>{parseInt(project.nb_cellule_sol * 4) - parseInt(project.nb_watt_used)} € restants</span>
                    </div>
                    <button className="button-invest-new"
                      onClick={(e) => {
                        navigate("/frame-314?project=" + project.id)
                      }} >
                      J’investis !
                    </button>
                    <div className="d-flex flex-column" style={{ gap: 10 }}>
                      <div className="d-flex align-items-center mt-4" style={{ gap: 20 }}>
                        <img src="goal.png" />
                        <span className="span-objectif">Objectif</span>
                        <span className="span-objectif"><b>{project.nb_cellule_sol * 4} €</b></span>
                      </div>

                      <div className="d-flex align-items-center mt-4" style={{ gap: 20 }}>
                        <img src="growth.png" />
                        <span className="span-objectif">Récolté</span>
                        <span className="span-objectif"><b>{project.nb_watt_used} €</b></span>
                      </div>

                      <div className="d-flex align-items-center mt-4" style={{ gap: 20 }}>
                        <img src="personpro.png" />
                        <span className="span-objectif">Investisseurs</span>
                        <span className="span-objectif"><b>{project.investors.length}</b></span>
                      </div>
                      <div className="d-flex align-items-center mt-4" style={{ gap: 20 }}>
                        <img src="timepro.png" />
                        <span className="span-objectif">Jours restants</span>
                        <span className="span-objectif"><b>{daysRemaining}</b></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="row p-3 mt-3 align-items-center" style={{ width: "100%" }}>
              <div className="col-md-7">
                <div className="d-flex flex-column align-self-start" style={{ gap: 10 }}>
                  <span className="cat-title">{project.porteur ? project.porteur : "Treeporteur SAS"}</span>

                  <span className="desc-cat" style={{ fontSize: 20 }}>{project.description_porteur ? project.description_porteur : "Nara, un spin-off de la société Treeporteur SAS, est un développeur de projets agro-écologiques, basée à Glasgow. La société détient actuellement un portefeuille de plus de 100 000 acres de terres dans le monde.Nara entreprend des projets qui combinent la restauration conventionnelle avec l’agriculture, en utilisant des écosystèmes salins pour transformer des régions arides."}</span>
                </div>
              </div>
              <div className="col-md-5 d-flex justify-content-end">
                <img width={320} src="/soclogo.png" />
              </div>

            </div>
            <div className="d-flex justify-content-center p-4" style={{ width: "100%" }}>
              <button style={{ width: "60%" }} className="button-invest-new"
                onClick={(e) => {
                  navigate("/frame-314?project=" + project.id)
                }} >
                Je me lance !
              </button>
            </div>
            <div className="p-4" style={{ fontFamily: "Roboto" }}>
              <Accordion style={{ border: '1px solid #616161' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "#1EB2A9" }} />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  Détails
                </AccordionSummary>
                <AccordionDetails>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="d-flex flex-column px-2 details-text">
                        <div className="d-flex justify-content-between mt-3">
                          <div>
                            <img src="/moneydetail.png" />
                            <span style={{ marginLeft: 12 }}>Collectés</span>
                          </div>
                          <span className="mt-1" style={{ color: "#188F87", fontSize: 18, fontFamily: "Plus Jakarta Sans" }}>
                            {project.nb_watt_used} €
                          </span>
                        </div>

                        <hr style={{ color: "#46A59F", opacity: 1 }} />

                        <div className="d-flex justify-content-between">
                          <div>
                            <img src="/flech.svg" />
                            <span style={{ marginLeft: 12 }}>Objectif du projet</span>
                          </div>
                          <span className="mt-1" style={{ color: "#188F87", fontSize: 18, fontFamily: "Plus Jakarta Sans" }}>
                            {project.nb_cellule_sol * 4} €
                          </span>
                        </div>

                        <hr style={{ color: "#46A59F", opacity: 1 }} />

                        <div className="d-flex justify-content-between">
                          <div>
                            <img src="/watch.svg" />
                            <span style={{ marginLeft: 12 }}>Temps restant</span>
                          </div>
                          <span className="mt-1" style={{ color: "#188F87", fontSize: 18, fontFamily: "Plus Jakarta Sans" }}>
                            {daysRemaining} jours
                          </span>
                        </div>

                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex flex-column px-2 details-text">
                        <div className="d-flex justify-content-between mt-1">
                          <div>
                            <img src="/percent.svg" />
                            <span style={{ marginLeft: 12 }}>Taux d’intérêts annuel</span>
                          </div>
                          <span className="mt-1" style={{ color: "#188F87", fontSize: 18, fontFamily: "Plus Jakarta Sans" }}>
                            {project.taux ? project.taux : 4} %
                          </span>
                        </div>

                        <hr style={{ color: "#46A59F", opacity: 1 }} />

                        <div className="d-flex justify-content-between">
                          <div>
                            <img src="/calendar.svg" />
                            <span style={{ marginLeft: 12 }}>Échéance</span>
                          </div>
                          <span className="mt-1" style={{ color: "#188F87", fontSize: 18, fontFamily: "Plus Jakarta Sans" }}>
                            2 ans
                          </span>
                        </div>

                        <hr style={{ color: "#46A59F", opacity: 1 }} />

                        <div className="d-flex justify-content-between">
                          <div>
                            <img src="/contract.svg" />
                            <span style={{ marginLeft: 12 }}>Type d’action</span>
                          </div>
                          <span style={{ color: "#188F87", fontSize: 18, fontFamily: "Plus Jakarta Sans", maxWidth: 105 }}>
                            {project.type_action ? project.type_action : "Obligations convertibles"}
                          </span>
                        </div>

                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ border: '1px solid #616161' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "#477520" }} />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  Porteur du projet
                </AccordionSummary>
                <AccordionDetails>
                  <div className="row">
                    <div className="col-md-6 p-3">
                      <div className="d-flex flex-column">
                        <img width={100} height={70} src={b64logo !== "" ? b64logo : "/imgproject-hoster2@2x.png"} />
                        <span style={{ color: "#477520", fontSize: 16, fontFamily: "Plus Jakarta Sans" }}>Le projet est porté par</span>
                        <span style={{ color: "#477520", fontSize: 24, fontFamily: "Plus Jakarta Sans" }}>{project.porteur ? project.porteur : "Treeporteur SAS"}</span>
                        <div className="bar-description-projet mt-1 mb-4"></div>
                        <p className="p-description">{project.description_porteur ? project.description_porteur : "Treeporteur SAS à comme mission d'innover dans la conception, le développement, la mise à disposition et l'exploitation d'infrastructures techniques logicielles et d'applications web/mobiles."}  </p>
                      </div>
                    </div>
                    <div className="col-md-6 p-3">
                      <div className="px-2">
                        {/* <p className="p-description">Leur vison cherche à offrir des solutions permettant une personnalisation complète des aménagements, qu'ils soient fixes ou mobiles. En outre, Ils s’engagent dans la réalisation physique de ces espaces. </p>
                         <p className="p-description">Leur expertise s'étend également à la planification de formations, d'événements et à l'élaboration de stratégies de commercialisation dans une diversité de domaines associés.</p> */}

                        <span style={{ color: "#477520", fontSize: 20, fontFamily: "Plus Jakarta Sans" }}>{project.porteur ? project.porteur + " en chiffres" : "Treeporteur SAS en chiffres"}</span>

                        <div className="d-flex flex-column mt-2  px-3" style={{ borderLeft: "3px solid #188F87" }}>
                          <div className="d-flex">
                            <img width={50} height={50} src="/gateau.svg" />
                            <div className="d-flex flex-column">
                              <span style={{ fontSize: 22, color: "#477520", marginLeft: 10 }}>{project.annee_exp ? project.annee_exp : 2} ans</span>
                              <span style={{ marginLeft: 10, color: "#477520", fontSize: 14, fontFamily: "Plus Jakarta Sans" }}>{moment().subtract(project.annee_exp ? project.annee_exp : 2, 'years').format('YYYY')}</span>
                            </div>
                          </div>
                          <div className="d-flex mt-2">
                            <img width={50} height={50} src="/Flag.svg" />
                            <div className="d-flex flex-column">
                              <span style={{ fontSize: 20, color: "#477520", marginLeft: 10 }}>{(project.pays_porteur && project.pays_porteur.length > 0) ? project.pays_porteur.length : 1} pays</span>
                              {(project.pays_porteur && project.pays_porteur.length > 0) ?
                                <div>
                                  {project.pays_porteur.map((item, key) => (
                                    <span key={key} style={{ marginLeft: 10, color: "#477520", fontSize: 14, fontFamily: "Plus Jakarta Sans" }}>{item.label}</span>
                                  ))}
                                </div>
                                :
                                <span style={{ marginLeft: 10, color: "#477520", fontSize: 14, fontFamily: "Plus Jakarta Sans" }}>Présence en France</span>
                              }
                            </div>
                          </div>
                          <div className="d-flex mt-2">
                            <img width={50} height={50} src="/activitydiagram.svg" />
                            <div className="d-flex flex-column">
                              <span style={{ fontSize: 22, color: "#477520", marginLeft: 10 }}>{(project.secteur_porteur && project.secteur_porteur.length > 0) ? project.secteur_porteur.length : 3} secteurs d’activités</span>
                              {(project.secteur_porteur && project.secteur_porteur.length > 0) ?
                                <div>
                                  {project.secteur_porteur.map((item, key) => (
                                    <span key={key} style={{ marginLeft: 10, color: "#477520", fontSize: 14, fontFamily: "Plus Jakarta Sans" }}>{item.label}</span>
                                  ))}
                                </div>
                                :
                                <span style={{ marginLeft: 10, color: "#477520", fontSize: 14, fontFamily: "Plus Jakarta Sans" }}>Logiciels de simulations, électronique, transports</span>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ border: '1px solid #616161' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "#1EB2A9" }} />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  Actualités
                </AccordionSummary>
                <AccordionDetails>
                  <>
                    <div className="d-flex flex-column px-2 actuality-text">
                      <div className="d-flex justify-content-between mt-3">
                        <div className="d-flex">
                          <img src="/actu1.svg" />
                          <span style={{ marginLeft: 12, maxWidth: 180 }}>Lancement de la campagne de financement</span>
                        </div>
                        <span className="mt-1">
                          Le {moment(project.year_creation).format("DD/MM/YYYY")}
                        </span>
                      </div>

                      <hr style={{ color: "#46A59F", opacity: 1 }} />

                      <div className="d-flex justify-content-between">
                        <div className="d-flex">
                          <img src="/actu2.svg" />
                          <span style={{ marginLeft: 12, maxWidth: 180 }}>Seuil des 25 % dépassé !</span>
                        </div>
                        <span className="mt-1">
                          Le XX/XX/XXXX
                        </span>
                      </div>

                      <hr style={{ color: "#46A59F", opacity: 1 }} />

                      <div className="d-flex justify-content-between">
                        <div className="d-flex">
                          <img src="/actu3.svg" />
                          <span style={{ marginLeft: 12, maxWidth: 180 }}>Seuil des 50 % dépassé !</span>
                        </div>
                        <span className="mt-1">
                          Le XX/XX/XXXX
                        </span>
                      </div>





                    </div>
                  </>
                </AccordionDetails>
              </Accordion>

            </div>
            {/* <div className="divcompartive-tab4">
              <div className="divinvestment-render2">
                {project.image ?
                  <img
                    style={{ width: "100%", height: "auto" }}
                    className="imgsolar-panel-support-system-icon2"
                    alt=""
                    src={project.image}
                  /> :
                  <img
                    className="imgsolar-panel-support-system-icon2"
                    alt=""
                    src="/treeporteurproject.png"
                  />}
              </div>
              <div className="divinvestment-details2">
                <div className="divinvestment-state2">
                  <div className="divtitle13">
                    <img
                      className="imgdouble-right-arrow-icon4"
                      alt=""
                      src="/imgdouble-right-arrow1@2x.png"
                    />
                    <b className="h3open-investment4">Investissement ouvert</b>
                  </div>
                  <div className="divcollect-info2">
                    <div className="divcurrent-money-collected2">
                      <div className="h3open-investment4">{project.nb_watt_used} € collectés</div>
                      <div className="pmoney-wanted2">
                        {project.nb_cellule_sol * 4} € seuil de réussite
                      </div>
                    </div>
                    <b className="ppercentage2">{pourcentage}%</b>
                    <div className="divdisplay-bar2">
                      <div className="progress-bar1" style={{ width: "100%" }}>
                        <div className="progress" style={{ border: "2px solid #254952" }}>
                          <div className="progress-bar" role="progressbar" style={{ width: `${pourcentage}%`, backgroundColor: "#254952" }} aria-value={pourcentage} aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>
                    <div className="h1title4">{parseInt(project.nb_cellule_sol * 4) - parseInt(project.nb_watt_used)} € restants</div>
                    <div className="ppercentage2">{`Jusqu'au 15 décembre `}</div>
                  </div>
                  <div className="divinvestor-on-it2">
                    <img
                      className="imginvestor-avatar-icon2"
                      alt=""
                      src="/imginvestor-avatar1@2x.png"
                    />
                    <div className="h3open-investment5" style={{ backgroundColor: "#f9d649" }}><b>{project.investors ? project.investors.length : 0}</b></div>
                    <b className="h3open-investment4">{`Investisseurs `}</b>
                    <div className="h3open-investment66" style={{ backgroundColor: "#f9d649" }}> <b>reste {daysRemaining} jours</b></div>
                  </div>
                </div>
                <div className="divproject-hoster4">
                  <img
                    className="imgproject-hoster-icon2"
                    alt=""
                    src="/imgproject-hoster2@2x.png"
                  />
                  <div className="divproject-hoster-info2">
                    <div className="pproject-hosted-default2">Projet porté par</div>
                    <b className="pproject-hosted-default2">Treeporteur</b>
                  </div>
                  <div className="divproject-hoster-info2">
                    <div className="pproject-hosted-default2">Emplacement du projet</div>
                    <b className="pproject-hosted-default2">{project.adress.commune}</b>
                  </div>
                </div>
                <div className="divinterest-rate-and-time11">
                  <div className="divtitle13">
                    <img
                      className="imgdouble-right-arrow-icon4"
                      alt=""
                      src="/imgdouble-right-arrow1@2x.png"
                    />
                    <b className="h3open-investment4">Taux d’intérêt et durée *</b>
                  </div>
                  <div className="divinterest-rate-and-time12">
                    <img className="imglock-icon2" alt="" src="/imglock1@2x.png" />
                    <b className="h3open-investment4">
                      Consulter le taux et la durée
                    </b>
                  </div>
                </div>
                <div className="divinvest-controls2">
                  <div className="divinvest-button-field1 "
                    style={{ cursor: "pointer" }}>
                    <div className="buttoninvest-in-btn1 invest-button">
                      <div className="pinvest-btn1">Voir les risques</div>
                    </div>
                  </div>
                  <div className="divinvest-button-field1 " onClick={(e) => {
                    navigate("/frame-314?project=" + project.id)
                  }}
                    style={{ cursor: "pointer" }}>
                    <div className="buttoninvest-in-btn1 invest-button">
                      <div className="pinvest-btn1">Investir</div>
                    </div>
                  </div>
                </div>
                <a className="tri-taux-container2">
                  <ul className="tri-taux-de-rentabilit-inte2">
                    <span>{`TRI ( Taux de rentabilité interne ) contractuel ( `}</span>
                    <span className="voir-fiscalit2">voir fiscalité</span>
                    <span className="span3">{` ) `}</span>
                  </ul>
                </a>
              </div>
            </div> */}
            {/* <div className="contentshare2">
              <div className="pproject-hosted-default2">Partager</div>
              <div className="groupshare-icons2">
                <img
                  className="capture-decran-2023-07-27-a6"
                  alt=""
                  src="/capture-decran-20230727-a-19343@2x.png"
                />
                <img
                  className="capture-decran-2023-07-27-a7"
                  alt=""
                  src="/capture-decran-20230727-a-19344@2x.png"
                />
                <img
                  className="capture-decran-2023-07-27-a7"
                  alt=""
                  src="/capture-decran-20230727-a-19345@2x.png"
                />
              </div>
            </div> */}
          </>
          }
        </div>

        {/* <div className="investment-details2">
          <div className="pinvestment-warning2">
            Investir présente un risque de perte en capital et de liquidité.
            N’investissez que l’argent dont vous n’avez pas besoin immédiatement
            et diversifiez votre épargne.
          </div>
          <b className="h1title5">
            <p className="de-hot-spot-wifi">Détails de l'investissement</p>
          </b>
          <div className="divcompartive-tab5">
            <div className="column1caracteristics2">
              <div className="divtitle15">
                <b className="pcolumn-title9">Caractéristiques</b>
              </div>
              <div className="divaccess-field5">
                <div className="paccessible6">Accessible</div>
              </div>
              <div className="divaccess-field5">
                <div className="paccessible6">Taux d’intérêt et durée *</div>
              </div>
              <div className="divaccess-field5">
                <div className="paccessible6">Remaining</div>
              </div>
              <div className="divaccess-field5">
                <div className="paccessible6">Investissement min</div>
              </div>
              <div className="divaccess-field5">
                <div className="paccessible6">Investissement max</div>
              </div>
              <div className="divaccess-field5">
                <div className="paccessible6">Valeur unitaire</div>
              </div>
              <div className="divaccess-field5">
                <div className="paccessible6">Participant</div>
              </div>
            </div>
            <div className="column1caracteristics2">
              <div className="divtitle15">
                <b className="pcolumn-title9">Simulateur</b>
              </div>
              <div className="divaccess-field6">
                <div className="paccessible6">Accessible</div>
              </div>
              <div className="divaccess-field6">
                <div className="paccessible6">Taux d’intérêt et durée *</div>
              </div>
              <div className="divaccess-field6">
                <div className="paccessible6">Remaining</div>
              </div>
              <div className="divaccess-field6">
                <div className="paccessible6">Investissement min</div>
              </div>
              <div className="divaccess-field6">
                <div className="paccessible6">Investissement max</div>
              </div>
              <div className="divaccess-field6">
                <div className="paccessible6">Valeur unitaire</div>
              </div>
              <div className="divaccess-field6">
                <div className="paccessible6">Participant</div>
              </div>
            </div>
            <div className="column3investment-details2">
              <div className="row0who-can-invest2">
                <div className="divtitle15">
                  <b className="pcolumn-title9">Qui peut investir</b>
                </div>
                <div className="divdate-and-place-info2">
                  <div className="pdate-and-place-info4">
                    <p className="de-hot-spot-wifi">
                      À partir du jeudi 16 février 2023 à 12h30
                    </p>
                    <ul className="tri-taux-de-rentabilit-inte2">
                      <li>
                        Investissement ouvert aux habitants et collectivités des
                        départements 16 - 17 - 24 - 33 - 79 - 85
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="divaccess-field6">
                  <div className="paccessible6">Taux d’intérêt et durée *</div>
                </div>
                <div className="divaccess-field6">
                  <div className="paccessible6">Remaining</div>
                </div>
                <div className="divaccess-field6">
                  <div className="paccessible6">Investissement min</div>
                </div>
                <div className="divaccess-field6">
                  <div className="paccessible6">Investissement max</div>
                </div>
                <div className="divaccess-field6">
                  <div className="paccessible6">Valeur unitaire</div>
                </div>
                <div className="divaccess-field6">
                  <div className="paccessible6">Participant</div>
                </div>
              </div>
              <div className="row1end-of-investment2">
                <div className="divtitle15">
                  <b className="pcolumn-title9">Date de fin</b>
                </div>
                <div className="divaccess-field5">
                  <b className="pdate-and-place-info4">31 juillet 2023 à 23h59</b>
                </div>
                <div className="divend-date-info2">
                  <div className="pend-date-info2">
                    La date de fin de collecte pourra être prolongée à la demande
                    du porteur de projet.Le rendement de votre investissement sera
                    calculé à partir de la date de génération des certificats de
                    souscription, c'est-à-dire une fois l'intégralité des fonds
                    reçus et la documentation juridique signée par le porteur de
                    projet.
                  </div>
                </div>
                <div className="divsecondary-house-info2">
                  <div className="pend-date-info2">
                    Si vous avez une résidence secondaire ou principale dans l'une
                    de ces zones, vous pouvez investir en renseignant un
                    justificatif de domicile.
                  </div>
                </div>
                <div className="divaccess-field6">
                  <div className="paccessible6">Investissement min</div>
                </div>
                <div className="divaccess-field6">
                  <div className="paccessible6">Investissement max</div>
                </div>
                <div className="divaccess-field6">
                  <div className="paccessible6">Valeur unitaire</div>
                </div>
                <div className="divaccess-field6">
                  <div className="paccessible6">Participant</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divproject-hoster-details2">
          <div className="navbarproject-nav-bar2">
            <div className="buttonbutton-nav-bar-the-proj2">
              <b className="pproject-hosted-default2">{`Le projet `}</b>
              <div className="divider2" />
            </div>
            <div className="buttonbutton-nav-bar-project-2">
              <b className="pproject-hosted-default2">Le porteur de projet</b>
            </div>
            <div className="buttonbutton-nav-bar-project-2">
              <b className="pproject-hosted-default2">Actualités</b>
            </div>
            <div className="buttonbutton-nav-bar-project-2">
              <b className="pproject-hosted-default2">Commentaires</b>
            </div>
            <button className="buttonbutton-be-call-back2" id="btn_etre_rappele">
              <b className="pbe-call-back2">{`Etre rappelé `}</b>
            </button>
            <button className="buttonbutton-doc2" id="btn_documentation">
              <img
                className="imgicon-download2"
                alt=""
                src="/imgicon-download1@2x.png"
              />
              <b className="pbe-call-back2">Documentation</b>
            </button>
            <button className="buttonbutton-invest2" id="btn_investir"
              onClick={(e) => {
                navigate("/frame-314?project=" + project.id)
              }}>
              <b className="p-invest2">Investir</b>
            </button>
          </div>
          <div className="divessential-info2">
            <div className="divessential-description2">
              <div className="l-essentiel2">L ‘ESSENTIEL</div>
              <div className="un-centre-de2">{`Un centre de formation , basé sur toulon, sur les nouvelles technologie pour les jeunes de 10-14 ans ( Collège et 15-18 ans  (lycée ) souhaite produire l’énergie électrique qu’elle consomme. Pour ce faire, une mini-centrale de production via des panneaux solaires serait mis en place grâce à un financement participatif. Au lieu, d’un classique emprunt, l’idée est de proposer à chacun des apprenants de devenir actionnaire de cette mini-centrale électrique. Chaque action, d’un euro, correspondra à un actif d’une Unité de compte solaire (UCS ).  `}</div>
            </div>
            <div className="divproject-hoster5">
              <div className="divfield-simulateur2">
                <div className="psimulator2">Simulateur</div>
              </div>
              <div className="divfield-simulateur2">
                <div className="psimulator2">{`Consulter le taux et la durée `}</div>
              </div>
              <div className="div-project-hoster-details2">
                <div className="pproject-hoster-name5">
                  <p className="le-porteur2">{`LE PORTEUR `}</p>
                  <p className="de-hot-spot-wifi">DE PROJET</p>
                </div>
                <img
                  className="imgproject-hoster-img-icon2"
                  alt=""
                  src="/imgproject-hoster-img2@2x.png"
                />
              </div>
            </div>
          </div>
        </div> */}
        <FooterDesktop />
        {isDrawerMenuDrawerItemPopupOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Top center"
            onOutsideClick={closeDrawerMenuDrawerItemPopup}
          >
            <DrawerMenuDrawerItem onClose={closeDrawerMenuDrawerItemPopup} />
          </PortalPopup>
        )}
        {isDrawerMenuMobileOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Top center"
            onOutsideClick={closeDrawerMenuMobile}
          >
            <DrawerMenuMobile onClose={closeDrawerMenuMobile} />
          </PortalPopup>
        )}
      </div>

      <div className="treeporteur-project-mobile" style={{ marginTop: 70, marginBottom: 100 }}>

        <HeaderMobile isHome={false} title={project.porteur ? project.porteur : "Treeporteur SAS"} />

        {project &&
          <Carousel
            infiniteLoop={true}
            autoPlay={true}
            showStatus={false}
            showArrows={false}
            showThumbs={false}
            interval={5000}
            swipeable={false}
          >
            <div className="section1-detail-projet d-flex align-items-end flex-column px-2 pb-1">
              <div className="d-flex flex-column mt-auto align-items-end mb-5">
                {project.type_projet === "Transport & Logistique" ? <img width={160} height={30} src="/transport_et_logistique.svg" /> : project.type_projet === "Sylviculture" ? <img width={160} height={30} src="/sylviculture.svg" /> : project.type_projet === "Tourisme" ? <img width={160} height={30} src="/tourisme.svg" /> : project.type_projet === "Apiculture" ? <img width={160} height={30} src="/apiculture.svg" /> : project.type_projet === "L’économie bleu" ? <img width={160} height={30} src="/economie_bleu.svg" /> : project.type_projet === "Batîments écologiques" ? <img width={160} height={30} src="/batiments_ecologiques.svg" /> : project.type_projet === "Énergies renouvelables" ? <img width={160} height={30} src="/energies_renouvelables.svg" /> : <img width={160} height={30} src="/alimentation.svg" />}
                {/* <img className="image-impact" src="/impact_scoring.svg" /> */}
              </div>
            </div>
            <div className="section1-detail-projet d-flex align-items-end flex-column px-2 pb-1">
              <div className="d-flex flex-column mt-auto align-items-end mb-5">
                {project.type_projet === "Transport & Logistique" ? <img width={160} height={30} src="/transport_et_logistique.svg" /> : project.type_projet === "Sylviculture" ? <img width={160} height={30} src="/sylviculture.svg" /> : project.type_projet === "Tourisme" ? <img width={160} height={30} src="/tourisme.svg" /> : project.type_projet === "Apiculture" ? <img width={160} height={30} src="/apiculture.svg" /> : project.type_projet === "L’économie bleu" ? <img width={160} height={30} src="/economie_bleu.svg" /> : project.type_projet === "Batîments écologiques" ? <img width={160} height={30} src="/batiments_ecologiques.svg" /> : project.type_projet === "Énergies renouvelables" ? <img width={160} height={30} src="/energies_renouvelables.svg" /> : <img width={160} height={30} src="/alimentation.svg" />}
                {/* <img className="image-impact" src="/impact_scoring.svg" /> */}
              </div>
            </div>
            <div className="section1-detail-projet d-flex align-items-end flex-column px-2 pb-1">
              <div className="d-flex flex-column mt-auto align-items-end mb-5">
                {project.type_projet === "Transport & Logistique" ? <img width={160} height={30} src="/transport_et_logistique.svg" /> : project.type_projet === "Sylviculture" ? <img width={160} height={30} src="/sylviculture.svg" /> : project.type_projet === "Tourisme" ? <img width={160} height={30} src="/tourisme.svg" /> : project.type_projet === "Apiculture" ? <img width={160} height={30} src="/apiculture.svg" /> : project.type_projet === "L’économie bleu" ? <img width={160} height={30} src="/economie_bleu.svg" /> : project.type_projet === "Batîments écologiques" ? <img width={160} height={30} src="/batiments_ecologiques.svg" /> : project.type_projet === "Énergies renouvelables" ? <img width={160} height={30} src="/energies_renouvelables.svg" /> : <img width={160} height={30} src="/alimentation.svg" />}
                {/* <img className="image-impact" src="/impact_scoring.svg" /> */}
              </div>
            </div>
          </Carousel>
        }
        <div className="section2-detail-projet d-flex flex-column px-2" >
          <div className="mt-auto d-flex flex-column align-items-start px-2">
            {/* <img width={90} height={70} src="/imgproject-hoster2@2x.png" /> */}
            <span className="mt-2" style={{ color: "#fff", fontSize: 30 }}>{project.porteur ? project.porteur : "Treeporteur"}</span>
            {project &&
              <span className="projet-description-section2">
                {project.title}
              </span>
            }
          </div>

          <div className="d-flex justify-content-between mt-2" style={{ width: '100%' }}>
            <div className="d-flex flex-column px-2" style={{ gap: 10 }}>

              <span style={{ color: "#fff", fontSize: 24, fontFamily: "Roboto" }}>Impact(s)</span>
              { (project.impact && project.impact.length > 0) ?
              <ul style={{ color: "#fff", fontFamily: "Roboto", fontSize: 20,height:86,overflowY:'auto' }}>
                {project.impact.map((item,key)=>(
                <li key={key}>{item.label}</li>
                ))}
              </ul>
            :
                <ul style={{ color: "#fff", fontFamily: "Roboto", fontSize: 20,height:86,overflowY:'auto' }}>
                <li>Égalité des genres</li>
                <li>Santé</li>
                <li>Éducation</li>
              </ul>
              }
              <div className="d-flex">
                <PlaceOutlinedIcon style={{ color: "#fff" }} fontSize="large" onClick={(e) => { openNewWindow(project.adress.maps_url) }} />
                {project.adress && <span className="mt-1" style={{ marginLeft: 15, color: "#fff", fontFamily: "Plus Jakarta Sans", fontSize: 16 }} >{project.adress.commune}</span>}
              </div>
            </div>
            <div className="d-flex flex-column mt-auto">
              <FavoriteBorderOutlinedIcon style={{ color: "#fff" }} fontSize="large" />
              <ShareIcon className="mt-3" style={{ color: "#fff" }} fontSize="large" />
            </div>
          </div>
          {/* <div className="bar-detail-projet align-self-center mt-2">i</div> */}
          <div className="section-percent align-self-center">{pourcentage} %</div>
        </div>
        <div className="mt-5 p-2">
          <span className="title-mobile-project">Résumé du projet</span>
        </div>
        <>
          <div className="d-flex flex-column mt-2 px-2 details-text">
            <div className="d-flex justify-content-between mt-3">
              <div>
                <img src="/moneydetail.png" />
                <span style={{ marginLeft: 12 }}>Collectés</span>
              </div>
              <span className="mt-1" style={{ color: "#000", fontSize: 18, fontFamily: "Plus Jakarta Sans" }}>
                {project.nb_watt_used} €
              </span>
            </div>

            <hr style={{ color: "#000", opacity: 1 }} />

            <div className="d-flex justify-content-between">
              <div>
                <img src="/flech.svg" />
                <span style={{ marginLeft: 12 }}>Objectif du projet</span>
              </div>
              <span className="mt-1" style={{ color: "#000", fontSize: 18, fontFamily: "Plus Jakarta Sans" }}>
                {project.nb_cellule_sol * 4} €
              </span>
            </div>

            <hr style={{ color: "#000", opacity: 1 }} />

            <div className="d-flex justify-content-between">
              <div>
                <img src="/watch.svg" />
                <span style={{ marginLeft: 12 }}>Temps restant</span>
              </div>
              <span className="mt-1" style={{ color: "#000", fontSize: 18, fontFamily: "Plus Jakarta Sans" }}>
                {daysRemaining} jours
              </span>
            </div>

            <hr style={{ color: "#000", opacity: 1 }} />

            <div className="d-flex justify-content-between">
              <div>
                <img src="/percent.svg" />
                <span style={{ marginLeft: 12 }}>Taux d’intérêts annuel</span>
              </div>
              <span className="mt-1" style={{ color: "#000", fontSize: 18, fontFamily: "Plus Jakarta Sans" }}>
                {project.taux ? project.taux : 4} %
              </span>
            </div>

            <hr style={{ color: "#000", opacity: 1 }} />

            <div className="d-flex justify-content-between">
              <div>
                <img src="/calendar.svg" />
                <span style={{ marginLeft: 12 }}>Échéance</span>
              </div>
              <span className="mt-1" style={{ color: "#000", fontSize: 18, fontFamily: "Plus Jakarta Sans" }}>
                2 ans
              </span>
            </div>

            <hr style={{ color: "#000", opacity: 1 }} />

            <div className="d-flex justify-content-between">
              <div>
                <img src="/contract.svg" />
                <span style={{ marginLeft: 12 }}>Type d’action</span>
              </div>
              <span style={{ color: "#000", fontSize: 18, fontFamily: "Plus Jakarta Sans", maxWidth: 125 }}>
                {project.type_action ? project.type_action : "Obligations convertibles"}
              </span>
            </div>

            <hr style={{ color: "#000", opacity: 1 }} />

            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <img src="/world.svg" />
                <span style={{ marginLeft: 12 }}>Impacts</span>
              </div>
              <div className="d-flex flex-column align-items-end">
              <span style={{ color: "#000", fontSize: 16, fontFamily: "Plus Jakarta Sans" }}>
               Égalité des genres
              </span>
              <span style={{ color: "#000", fontSize: 16, fontFamily: "Plus Jakarta Sans" }}>
              Accès à l’énergie
              </span>
              <span style={{ color: "#000", fontSize: 16, fontFamily: "Plus Jakarta Sans" }}>
              Suppresion des émissions
              </span>
              </div>
             
            </div>
            <hr style={{ color: "#000", opacity: 1 }} />
          </div>
        </>
        <div className="mt-2 px-4">
          <span className="title-mobile-project">Pour en savoir plus</span>
        </div>

        <div className="p-4" style={{ fontFamily: "Roboto" }}>
              <Accordion style={{ border: '1px solid #616161' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "#477520" }} />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  Description
                </AccordionSummary>
                <AccordionDetails>
                  <div className="d-flex flex-column justify-content-center align-items-center px-4">
                    <img width={240} src={b64 !== "" ? b64 : project.image} />
                    <div className="mt-3 mb-3" style={{height:3,backgroundColor:"#477520",width:150}}/>
                    <p className="p-description">{project.description ? project.description : "Ce projet à pour objectif la mise au point d’un système de réseau WiFi mobile, propulsé par la technologie Starlink.Grâce à son antenne avancée, il capte un signal fiable partout sur terre. Un répéteur WiFi étend sa portée pour une connectivité inégalée, même dans les endroits les plus éloignés. "}  </p>
                    <p className="p-description mt-3">{project.description1 ? project.description1 : "Le tout est alimenté par des panneaux solaires hautement efficaces et avec une batterie de secours intégrée pour une autonomie totale. De plus, le système Bicylift permet de transporter la mallette de manière éco-responsable, en utilisant un vélo."}  </p>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ border: '1px solid #616161' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "#477520" }} />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  Porteur du projet
                </AccordionSummary>
                <AccordionDetails>
                  <div className="row">
                    <div className="col-md-6 p-3">
                      <div className="d-flex flex-column">
                        <img width={100} height={70} src={b64logo !== "" ? b64logo : "/imgproject-hoster2@2x.png"} />
                        <span style={{ color: "#477520", fontSize: 16, fontFamily: "Plus Jakarta Sans" }}>Le projet est porté par</span>
                        <span style={{ color: "#477520", fontSize: 24, fontFamily: "Plus Jakarta Sans" }}>{project.porteur ? project.porteur : "Treeporteur SAS"}</span>
                        <div className="bar-description-projet mt-1 mb-4"></div>
                        <p className="p-description">{project.description_porteur ? project.description_porteur : "Treeporteur SAS à comme mission d'innover dans la conception, le développement, la mise à disposition et l'exploitation d'infrastructures techniques logicielles et d'applications web/mobiles."}  </p>
                      </div>
                    </div>
                    <div className="col-md-6 p-3">
                      <div className="px-2">
                        {/* <p className="p-description">Leur vison cherche à offrir des solutions permettant une personnalisation complète des aménagements, qu'ils soient fixes ou mobiles. En outre, Ils s’engagent dans la réalisation physique de ces espaces. </p>
                         <p className="p-description">Leur expertise s'étend également à la planification de formations, d'événements et à l'élaboration de stratégies de commercialisation dans une diversité de domaines associés.</p> */}

                        <span style={{ color: "#477520", fontSize: 20, fontFamily: "Plus Jakarta Sans" }}>{project.porteur ? project.porteur + " en chiffres" : "Treeporteur SAS en chiffres"}</span>

                        <div className="d-flex flex-column mt-2  px-3" style={{ borderLeft: "3px solid #477520" }}>
                          <div className="d-flex">
                            <img width={50} height={50} src="/gateau.svg" />
                            <div className="d-flex flex-column">
                              <span style={{ fontSize: 22, color: "#477520", marginLeft: 10 }}>{project.annee_exp ? project.annee_exp : 2} ans</span>
                              <span style={{ marginLeft: 10, color: "#477520", fontSize: 14, fontFamily: "Plus Jakarta Sans" }}>{moment().subtract(project.annee_exp ? project.annee_exp : 2, 'years').format('YYYY')}</span>
                            </div>
                          </div>
                          <div className="d-flex mt-2">
                            <img width={50} height={50} src="/Flag.svg" />
                            <div className="d-flex flex-column">
                              <span style={{ fontSize: 20, color: "#477520", marginLeft: 10 }}>{(project.pays_porteur && project.pays_porteur.length > 0) ? project.pays_porteur.length : 1} pays</span>
                              {(project.pays_porteur && project.pays_porteur.length > 0) ?
                                <div>
                                  {project.pays_porteur.map((item, key) => (
                                    <span key={key} style={{ marginLeft: 10, color: "#477520", fontSize: 14, fontFamily: "Plus Jakarta Sans" }}>{item.label}</span>
                                  ))}
                                </div>
                                :
                                <span style={{ marginLeft: 10, color: "#477520", fontSize: 14, fontFamily: "Plus Jakarta Sans" }}>Présence en France</span>
                              }
                            </div>
                          </div>
                          { (project.secteur_porteur && project.secteur_porteur.length > 0) &&
                            <div className="d-flex mt-2">
                            <img width={50} height={50} src="/activitydiagram.svg" />
                            <div className="d-flex flex-column">
                              <span style={{ fontSize: 22, color: "#477520", marginLeft: 10 }}>{(project.secteur_porteur && project.secteur_porteur.length > 0) ? project.secteur_porteur.length : 3} secteurs d’activités</span>
                              {(project.secteur_porteur && project.secteur_porteur.length > 0) ?
                                <div>
                                  {project.secteur_porteur.map((item, key) => (
                                    <span key={key} style={{ marginLeft: 10, color: "#477520", fontSize: 14, fontFamily: "Plus Jakarta Sans" }}>{item.label}</span>
                                  ))}
                                </div>
                                :
                                <span style={{ marginLeft: 10, color: "#477520", fontSize: 14, fontFamily: "Plus Jakarta Sans" }}>Logiciels de simulations, électronique, transports</span>
                              }
                            </div>
                          </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ border: '1px solid #616161' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "#477520" }} />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  Actualités
                </AccordionSummary>
                <AccordionDetails>
                  <>
                    <div className="d-flex flex-column px-2 actuality-text">
                      <div className="d-flex justify-content-between mt-3">
                        <div className="d-flex">
                          <img src="/actu1.svg" />
                          <span style={{ marginLeft: 12, maxWidth: 180 }}>Lancement de la campagne de financement</span>
                        </div>
                        <span className="mt-1">
                          Le {moment(project.year_creation).format("DD/MM/YYYY")}
                        </span>
                      </div>

                      <hr style={{ color: "#477520", opacity: 1 }} />

                      {/* <div className="d-flex justify-content-between">
                        <div className="d-flex">
                          <img src="/actu2.svg" />
                          <span style={{ marginLeft: 12, maxWidth: 180 }}>Seuil des 25 % dépassé !</span>
                        </div>
                        <span className="mt-1">
                          Le XX/XX/XXXX
                        </span>
                      </div>

                      <hr style={{ color: "#477520", opacity: 1 }} />

                      <div className="d-flex justify-content-between">
                        <div className="d-flex">
                          <img src="/actu3.svg" />
                          <span style={{ marginLeft: 12, maxWidth: 180 }}>Seuil des 50 % dépassé !</span>
                        </div>
                        <span className="mt-1">
                          Le XX/XX/XXXX
                        </span>
                      </div>

                      <hr style={{ color: "#477520", opacity: 1 }} />

                      <div className="d-flex justify-content-between">
                        <div className="d-flex">
                          <img src="/actu4.svg" />
                          <span style={{ marginLeft: 12, maxWidth: 180 }}>Seuil des 50 % dépassé !</span>
                        </div>
                        <span className="mt-1">
                          Le XX/XX/XXXX
                        </span>
                      </div>

                      <hr style={{ color: "#477520", opacity: 1 }} />

                      <div className="d-flex justify-content-between">
                        <div className="d-flex">
                          <img src="/actu5.svg" />
                          <span style={{ marginLeft: 12, maxWidth: 180 }}>Seuil des 50 % dépassé !</span>
                        </div>
                        <span className="mt-1">
                          Le XX/XX/XXXX
                        </span>
                      </div>

                      <hr style={{ color: "#477520", opacity: 1 }} /> */}

                    </div>
                  </>
                </AccordionDetails>
              </Accordion>

            </div>

            <div className="d-flex flex-column px-4" style={{gap:10}}>
                <span style={{fontSize:18}} className="title-mobile-investir">Comment fonctionne le <span style={{fontSize:18}} className="title-mobile-platform">financement participatif</span> ?</span>
                <div onClick={(e) => { }} style={{width:200}} className="link-discover-mobile py-1">
                <span>J’accède à la FAQ&nbsp; &nbsp;<b>→</b></span>
              </div>
            </div>

        <div className="detail-final d-flex flex-column mt-5 px-2">
          {project.investors &&
            <div className="d-flex align-items-center">
              <AvatarGroup max={4}>
                {
                  project.investors.map((item, key) => (
                    <Avatar key={key} alt="Remy Sharp" src="/avatar12.png" />
                  ))
                }

              </AvatarGroup>
              <span style={{ marginLeft: 10 }} className="concat-emails">{emailConcatName}</span>
            </div>
          }
          <button onClick={(e) => {
            navigate("/frame-314?project=" + project.id)
          }} className="btn-discover-mobile align-self-center mt-4">
            <b className="default-btn">J'investi !</b>
          </button>
          <p style={{ textAlign: "center" }} className="concat-emails mt-2">Investir présente un risque de perte en capital et de liquidité. N’investissez que l’argent dont vous n’avez pas besoin immédiatement et diversifiez votre épargne.</p>
        </div>
        <FooterMobile />

        {isDrawerMenuMobileOpen && (
          <PortalPopup
            overlayColor="rgba(113, 113, 113, 0.3)"
            placement="Top center"
            onOutsideClick={closeDrawerMenuMobile}
          >
            <DrawerMenuMobile onClose={closeDrawerMenuMobile} />
          </PortalPopup>
        )}
      </div>
    </>
  );
};

export default HotspotWifiSolaire;
