import React, { useState, useCallback, useEffect } from "react";
import DrawerMenuDrawerItem from "../components/DrawerMenuDrawerItem";
import PortalPopup from "../components/PortalPopup";
import "./LandingResp.css";
import { useNavigate } from "react-router-dom";
import FooterMobile from "../components/FooterMobile";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DrawerMenuMobile from "../components/DrawerMenuMobile";
import HeaderMobile from "../components/HeaderMobile";
import HeaderDesktop from "../components/HeaderDesktop";
import FooterDesktop from "../components/FooterDesktop";
import { apiNodeService } from "../provider/apiNodeService";
import moment from "moment";

const LandingResp = () => {

  const navigate = useNavigate();
  const [projects, setProjects] = useState([])
  const [loading, setLoading] = React.useState(false);

  const [isDrawerMenuDrawerItemPopupOpen, setDrawerMenuDrawerItemPopupOpen] =
    useState(false);
  const [isDrawerMenuMobileOpen, setDrawerMenuMobileOpen] =
    useState(false);

    useEffect(() => {
      setLoading(true)
      apiNodeService.getAllProjects().then(res => {
  
        if (res && res.status === 200) {
          console.log(res.data)
          setProjects(res.data)
         
          setLoading(false)
        }
      })
      
    }, []);

  const inscription = () => {
    navigate('/web-invest')
  }

  const openDrawerMenuDrawerItemPopup = useCallback(() => {
    setDrawerMenuDrawerItemPopupOpen(true);
  }, []);

  const openDrawerMenuMobile = useCallback(() => {
    setDrawerMenuMobileOpen(true);
  }, []);

  const closeDrawerMenuMobile = useCallback(() => {
    setDrawerMenuMobileOpen(false);
  }, []);

  const closeDrawerMenuDrawerItemPopup = useCallback(() => {
    setDrawerMenuDrawerItemPopupOpen(false);
  }, []);

  const onBtnGetDocumClick = useCallback(() => {
    //TODO: Get Documentation api
  }, []);

  const onChoixProjetTreeporteur = useCallback((project) => {
    navigate("/hotspot-wifi-solaire?project=" + project);
  }, [navigate]);

  return (
    <>
      <div className="landingresp">
        <HeaderDesktop />
        <div className="row align-items-center mb-4" style={{ marginTop: 140, width: "100%" }}>
          <div className="col-lg-7" style={{ paddingLeft: 100 }}>
            <div style={{ maxWidth: 700 }} className="d-flex flex-column  px-4 mb-4">
              <div className="title-desktop-finance"><span style={{ textAlign: 'left' }}>Un compte, un <span className="title-desktop-solaire">espoir</span></span></div>
              <p className="p-card1" style={{ padding: "20px 50px 20px 2px" }}>Vous pouvez changer le monde en ouvrant un compte finançant des projets verts dès aujourd'hui.
                <br /> <br />
                Vous ne nous croyez pas ? Pas de problèmes, faites rapidement un tour sur notre plateforme pour voir l’impact de vos futures actions !</p>
              <div className="d-flex flex-column mt-3">
                <button onClick={(e) => { inscription() }} className="defaultbtn">
                  <b className="default-btn">Je découvre la plateforme !</b>
                </button>
                {/* <span className="span-under-btn mt-2">À partir de 1 € seulement !</span> */}
              </div>
            </div>
          </div>
          <div className="col-lg-5 justify-content-center">
            <div className="d-flex justify-content-center">
              <img
                style={{ width: "80%" }}
                className="mt-4"
                alt=""
                src="/cartegreenhome.svg"
              />
            </div>

          </div>
        </div>
        <div className="row" style={{ width: "100%" }}>
          <div className="col-lg-5">

          </div>

        </div>

        <div className="mt-4">
          <div className="p-5 mt-3 second-sec-desktop">
            <div className="row card-desktop" style={{ marginTop: -60 }}>
              <div className="col-md-3">
                <div className="d-flex flex-column align-self-center px-3 mt-3">
                  <img className="align-self-center mb-2" width={70} alt="image" src="/buy-icon.svg" />
                  <span className="span-card1 align-self-center mb-2">Investissez dans un projet</span>
                  <p className="p-card1 align-self-center" style={{ textAlign: 'center' }}>Commencez avec seulement 1 €.</p>

                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex flex-column align-self-center px-3 mt-3">
                  <img className="align-self-center mb-2" width={70} alt="image" src="/earth.svg" />
                  <span className="span-card1 align-self-center mb-2">Mesurez votre impact</span>
                  <p className="p-card1 align-self-center" style={{ textAlign: 'center' }}>Votre investissement contribue à lancer rapidement des projets verts.</p>

                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex flex-column align-self-center px-3 mt-3">
                  <img className="align-self-center mb-2" width={70} alt="image" src="/card3mobile.svg" />
                  <span className="span-card1 align-self-center mb-2">Observer la croissance</span>
                  <p className="p-card1 align-self-center" style={{ textAlign: 'center' }}>Votre placement génère des intérêts dès le premier jour.</p>

                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex flex-column align-self-center px-3 mt-3">
                  <img className="align-self-center mb-2" width={70} alt="image" src="/card4mobile.svg" />
                  <span className="span-card1 align-self-center mb-2">Récupérez vos intérêts</span>
                  <p className="p-card1 align-self-center" style={{ textAlign: 'center' }}>Percevez des intérêts tout en soutenant un avenir plus écologique.</p>

                </div>
              </div>
            </div>

          </div>

          <div className="row p-4" style={{ backgroundColor: "#e3e3df" }}>
            <div className="col-md-7 d-flex flex-column justify-content-start px-4">
              <img style={{ width: "95%", textAlign: 'left' }} alt="pannaux" src="/pannauxlanding.png" />
              <p style={{ textAlign: 'left', fontSize: 13 }} className="paragraphe-landing">Image non contractuelle servant d’exemple d’un système solaire au sol par Sebastian Ganso de Pixabay</p>

            </div>
            <div className="col-md-5" style={{ textAlign: 'left' }}>
              <div className="title-mobile-investir left-bar px-4 mt-2"><span className="title-mobile-platform"><span style={{ color: "#000", fontWeight: 700 }}>GreenCard</span> <br /> Investissez autrement</span></div>
              <p className="mt-4 paragraphe-landing">GreenCard est une plateforme de financement participatif basée en France qui se concentre sur les projets verts. </p>
              <p className="mt-4 paragraphe-landing">Notre mission est de permettre à tous d’investir dans un avenir plus propre et sans se ruiner.</p>
              <button className="button-inscri-desktop mt-4" onClick={(e) => { inscription() }}>Voir les projets que nous proposons&nbsp; &nbsp;  →</button>
            </div>
          </div>

          <div className="row" style={{ backgroundColor: "#e3e3df", textAlign: 'left', paddingLeft: 60 }}>
            <div className="title-mobile-investir left-bar px-2 mt-5"><span>Comment investir <br /> sur notre <span className="title-mobile-platform">plateforme </span>?</span></div>

            <p className="mt-4 paragraphe-landing">Nous identifions des projets verts  prometteurs mais rencontrant des difficultés pour obtenir un financement. Ces projets sont présentés sur la plateforme, vous permettant de contribuer à partir d'un faible montant (1€). En retour, vous recevrez des intérêts provenant des porteurs de projets, tout en soutenant un avenir meilleur.</p>
            {/* <p className="mt-2 paragraphe-landing">Ces projets sont présentés sur la plateforme, vous permettant de contribuer à partir d'un faible montant (1€). </p>
            <p className="mt-2 paragraphe-landing">En retour, vous recevrez des intérêts provenant des porteurs de projets, tout en soutenant les énergies renouvelables et en facilitant l'accès à une énergie propre et abordable pour les communautés et entreprises. </p> */}
          </div>

          <div className="row p-4" style={{ backgroundColor: "#e3e3df", paddingLeft: 60, textAlign: 'left' }}>
            <div className="col-md-4 p-3">
              <div className="card-etape d-flex flex-column align-self-center p-3 mt-5" style={{ minHeight: 240 }}>
                <span className="title-etape">1 - Choisir un projet</span>
                <p className="p-etape mt-3">Afin de sélectionner un projet, vous pouvez vous reférez aux détails de ce dernier, tels que : le taux d’intérêt annuel, la date de cloture, la somme restante, la localisation, etc...</p>
              </div>
            </div>

            <div className="col-md-4 p-3">
              <div className="card-etape d-flex flex-column align-self-center p-3 mt-5" style={{ minHeight: 240 }}>
                <span className="title-etape">2 - S’identifier</span>
                <p className="p-etape mt-3">Un compte est nécessaire pour que nous puissions associer votre achat à ce dernier.</p>
              </div>
            </div>

            <div className="col-md-4 p-3">
              <div className="card-etape d-flex flex-column align-self-center p-3 mt-5" style={{ minHeight: 240 }}>
                <span className="title-etape">3 - Choisir les parts</span>
                <p className="p-etape mt-3">Une fois le projet sélectionné, vous pouvez entrer le nombre de parts que vous souhaitez acheter.</p>
              </div>
            </div>

            <div className="col-md-4 p-4">
              <div className="card-etape d-flex flex-column align-self-center p-3 mt-5" style={{ minHeight: 230 }}>
                <span className="title-etape">4 - Paiement</span>
                <p className="p-etape mt-3">Vous pouvez choisir différents moyens de paiement sécurisés.</p>
              </div>
            </div>

            <div className="col-md-4 p-4">
              <div className="card-etape d-flex flex-column align-self-center p-3 mt-5" style={{ minHeight: 230 }}>
                <span className="title-etape">5 - Compléter le profil</span>
                <p className="p-etape mt-3">Nous vous demanderons plus d'informations personnelles pour garantir la légitimité des fonds et se conformer aux normes de sécurité financière.</p>
              </div>
            </div>

            <div className="col-md-4 p-4">
              <div className="card-etape d-flex flex-column align-self-center p-3 mt-5" style={{ minHeight: 230 }}>
                <span className="title-etape">6 - Consulter ses mails</span>
                <p className="p-etape mt-3">Une fois l’achat validé, vous recevrez les documents légaux correspondants. Conservez les précieusement !</p>
              </div>
            </div>
          </div>

          <div className="row p-4" style={{ backgroundColor: "#e3e3df", textAlign: 'left', paddingLeft: 60 }}>
            <div className="d-flex justify-content-center align-items-center" style={{ gap: 20, paddingBottom: 80 }}>
              <div className="je-lance-section d-flex align-items-center">
                <span className="span-lance">1 € et vous êtes investisseur dans un avenir meilleur</span>
                <button style={{ height: 50 }} onClick={(e) => { inscription() }} className="btn-lance-mobile align-self-center p-2">
                  <b>Je me lance!</b>
                </button>
              </div>
            </div>
          </div>
        </div>
        <FooterDesktop />

      </div>

      <div className="landingresp-mobile" style={{ marginTop: 80, marginBottom: 100 }}>

        <HeaderMobile isHome={true} title={""} />

        <div className="d-flex flex-column p-3">

          <div className="title-mobile-finance"><span>{`Un `}<span className="title-mobile-solaire">compte,</span> un <br /><span className="title-mobile-solaire">espoir</span></span></div>
          <p className="mt-4 paragraphe-landing">Vous pouvez changer le monde en ouvrant un compte finançant des projets verts dès aujourd'hui.</p>
          <p className="mt-2 paragraphe-landing">Vous ne nous croyez pas ? Pas de problèmes, faites rapidement un tour sur notre plateforme pour voir l’impact de vos futures actions !</p>

          <div onClick={(e) => { inscription() }} className="link-discover-mobile py-1">
            <span>Je découvre la plateforme&nbsp; &nbsp;<b>→</b></span>
          </div>
          {/* <span className="span-under-btn align-self-center mt-2">À partir de 1 € seulement !</span> */}
         
        </div>
        <div className="d-flex flex-column" style={{marginTop:130}}>
          <div className="section-show-green p-4">
          <img
            style={{marginTop:-130}}
            className="capture-decran-2023-07-07-a mb-4 align-self-center"
            alt=""
            src="/cartegreenhome1.svg"
          />
            <div className="d-flex justify-content-between align-items-start">
              <div className="d-flex flex-column">
                <img className="align-self-center mb-2" width={60} height={50} alt="image" src="/show.svg" />
                <span className="span-show-green align-self-center mb-2">Consultez vos émissions C02</span>
              </div>
              <div className="d-flex flex-column">
                <img className="align-self-center mb-2" width={60} height={50} alt="image" src="/Sun.svg" />
                <span className="span-show-green align-self-center mb-2">Financez des projets verts</span>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-start">
              <div className="d-flex flex-column">
                <img className="align-self-center mb-2" width={60} height={50} alt="image" src="/Chart.svg" />
                <span className="span-show-green align-self-center mb-2">Récuperez des bénéfices</span>
              </div>
              <div className="d-flex flex-column">
                <img className="align-self-center mb-2" width={60} height={50} alt="image" src="/coffee.svg" />
                <span className="span-show-green align-self-center mb-2">Réalisez vos projets</span>
              </div>
            </div>
          </div>

        </div>
        <div className="d-flex flex-column p-3">

          <span className="title-mobile-investir">Les projets financés grâce à la <span className="title-mobile-platform">greencard</span></span>

          <div className="cards-list-project pb-3">
            {projects.map((item, key) => (
              <div key={key} onClick={(e) => { onChoixProjetTreeporteur(item.id) }} className="card-projet-mobile mt-3" style={{ marginLeft: key > 0 ? 25 : 5 }}>
                <div className="projet-description-top d-flex align-items-start flex-column">
                  <span className="projet-name-mobile mt-auto mb-4 ml-2">{item.porteur ? item.porteur : "Treeporteur"} <br /> <span className="projet-title-mobile">{item.type_action ? item.type_action : "Obligations convertibles"}</span> </span>

                </div>
                <div className="middle-section-card d-flex justify-content-between">
                  <div className="date-project pt-1 px-3">
                    <span className="span-date-project">Se termine le {item.date_fin ? moment(item.date_fin).format("DD/MM/YYYY") : "30/11/2024"}</span>
                  </div>
                </div>
                <div className="projet-description-bottom d-flex align-items-start flex-column py-2">
                  {item.type_projet === "Transport & Logistique" ? <img src="/transport_et_logistique.svg" /> : item.type_projet === "Sylviculture" ? <img src="/sylviculture.svg" /> : item.type_projet === "Tourisme" ? <img src="/tourisme.svg" /> : item.type_projet === "Apiculture" ? <img src="/apiculture.svg" /> : item.type_projet === "L’économie bleu" ? <img src="/economie_bleu.svg" /> : item.type_projet === "Batîments écologiques" ? <img src="/batiments_ecologiques.svg" /> : item.type_projet === "Énergies renouvelables" ? <img src="/energies_renouvelables.svg" /> : <img src="/alimentation.svg" />}
                  <span className="span-card-project-2 mt-3 px-3">{item.title ? item.title : "Hot spot mobile alimenté au solaire"}</span>
                  <span className="span-card-project-3 mt-3 px-3">Intérêts : {item.taux ? item.taux : "5"} %</span>
                  <div className="d-flex justify-content-between mt-2 px-3" style={{ width: "100%" }}>
                    <span style={{ color: "#477520", fontSize: 20, fontWeight: 500 }}>{((parseInt(item.nb_watt_used) / parseInt(item.nb_cellule_sol * 4)) * 100).toFixed(2)} %</span>
                    <span style={{ fontSize: 20, fontWeight: 500 }}>{parseInt(item.nb_cellule_sol * 4)} €</span>
                  </div>
                  <div className="progress-bar1 mt-1 px-3" style={{ width: "100%" }}>
                    <div className="progress" style={{ border: "1px solid #254952", height: 7 }}>
                      <div className="progress-bar" role="progressbar" style={{ width: `${(parseInt(item.nb_watt_used) / parseInt(item.nb_cellule_sol * 4)) * 100}%`, backgroundColor: "#477520" }} aria-value={(parseInt(item.nb_watt_used) / parseInt(item.nb_cellule_sol * 4)) * 100} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div className="d-flex mt-3 px-3 mb-3">
                    <img src="/clock.svg" />
                    <span style={{ fontSize: 16, color: '#477520', marginLeft: 10 }}>{item.date_fin ? moment(item.date_fin).diff(moment(), 'days') : 213}  Jour(s) restant(s)</span>
                  </div>
                </div>
                <div className="d-flex justify-content-center mb-4">
                  <button onClick={(e) => { onChoixProjetTreeporteur(item.id) }} className="btn-choix-projet-mobile">
                    Voir le projet
                  </button>
                </div>
              </div>
            ))
            }
          </div>
        </div>
        <div className="d-flex flex-column p-3">
        <span style={{fontSize:24}} className="title-mobile-investir">Mais la <span style={{fontSize:24}} className="title-mobile-platform">greencard</span> c’est aussi</span>
        <div className="d-flex align-items-center mt-3" style={{gap:10}}>
            <img width={35} height={27} src="list1.svg"/>
            <span className="span-mais">Des virements sans frais et instantanés</span>
        </div>
        <hr />
        <div className="d-flex align-items-center" style={{gap:10}}>
            <img width={35} height={27} src="list2.svg"/>
            <span className="span-mais">Un suivi de vos dépenses en temps réel</span>
        </div>
        <hr />
        <div className="d-flex align-items-center" style={{gap:10}}>
            <img width={35} height={27} src="list3.svg"/>
            <span className="span-mais">Du financement participatif vert</span>
        </div>
        <hr />
        <div className="d-flex align-items-center" style={{gap:10}}>
            <img width={35} height={27} src="list4.svg"/>
            <span className="span-mais">Des intérêts passifs</span>
        </div>
        <hr />
        <div className="d-flex align-items-center" style={{gap:10}}>
            <img width={35} height={27} src="list5.svg"/>
            <span className="span-mais">Une gestion avancée de votre carte</span>
        </div>
        <hr />
        <div className="d-flex align-items-center" style={{gap:10}}>
            <img width={35} height={27} src="list7.svg"/>
            <span className="span-mais">Un chat en temps réel pour mieux investir</span>
        </div>
        <hr />
        <div className="d-flex align-items-center mb-4" style={{gap:10}}>
            <img width={35} height={27} src="list8.svg"/>
            <span className="span-mais">Une plateforme de mise en relation</span>
        </div>
        <span style={{fontSize:20}} className="span-mais">Et surtout, votre compagnon de vie pour un <span style={{color:"#477520",fontSize:20}}>avenir plus soutenable !</span></span>
        <button className="button-inscri-desktop mt-4" onClick={(e) => { inscription() }}>Je fais changer les choses dès maintenant !</button>

        
        </div>
        <div className="section-show-green p-4" style={{marginTop:80}}>
          <div className="d-flex flex-column">
            <img src="/lesaviezvous.png" className="mb-2" style={{marginTop:-45}}/>
          {/* <span style={{textAlign:'center'}}>Le saviez-vous ?</span> */}
          <span style={{color:"#fff",fontSize:32}}>42 000 km</span>
          <hr />
          <span style={{color:"#fff"}}>de trajet en voiture essence par an et par personne.</span>
          <br/>
          <span style={{color:"#fff"}}>C’est l’équivalent moyen de ce que pollue votre argent sur un compte épargne lambda...</span>
          </div>
        </div>
        <div className="d-flex flex-column p-3">
        <span style={{fontSize:24}} className="title-mobile-investir">Notre <span style={{fontSize:24}} className="title-mobile-platform">communauté</span> en parle mieux que nous !</span>
          <div className="card-invest-home p-3 mt-3">
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center" style={{gap:20}}>
                <img src="/jhlauret.svg" />
                <span className="span-username">Jean Hugues-Lauret</span>
              </div>
              <hr />
              <span className="span-mais">Simple et efficace, je peux enfin investir sans me prendre la tête et recevoir des bénéfices passifs !</span>

            </div>
          </div>
          <div className="card-invest-home p-3 mt-3">
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center" style={{gap:20}}>
                <img src="/gabrielle.svg" />
                <span className="span-username">Smaniotto Gabriel</span>
              </div>
              <hr />
              <span className="span-mais">Très intuitif et la création de projet est super simple</span>

            </div>
          </div>
          <div className="card-invest-home p-3 mt-3 mb-4">
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center" style={{gap:20}}>
                <img src="/claud.svg" />
                <span className="span-username">Khaled Kaabi</span>
              </div>
              <hr />
              <span className="span-mais">Les transactions sont rapides et la plateforme est assez transparente</span>

            </div>
          </div>
        <span style={{fontSize:24}} className="title-mobile-investir">Toujours pas convaincu ? n’hésitez pas à en <span style={{fontSize:24}} className="title-mobile-platform">discuter</span> directement avec nous !</span>

        </div>
        <FooterDesktop />
        <FooterMobile />
      </div>
      {isDrawerMenuDrawerItemPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top center"
          onOutsideClick={closeDrawerMenuDrawerItemPopup}
        >
          <DrawerMenuDrawerItem onClose={closeDrawerMenuDrawerItemPopup} />
        </PortalPopup>
      )}
      {isDrawerMenuMobileOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top center"
          onOutsideClick={closeDrawerMenuMobile}
        >
          <DrawerMenuMobile onClose={closeDrawerMenuMobile} />
        </PortalPopup>
      )}
    </>
  );
};

export default LandingResp;
