exports.impacts_mesure = [
    {label:"Tonnes de C02"},
    {label:"MW produits"},
    {label:"MW économisés"},
    {label:"Nombre de ménages"},
    {label:"Nombre d’hectare protégé/sauvé"},
    {label:"Nombre d’hectare planté"},
    {label:"Nombre d’espèces plantées ou des opérations/gestes"},
    {label:"Résultat, certification ou bénéfice généré"},
    {label:"Augmentation de compétence, de titre ou de rang"},
    {label:"Pourcentage/nombre de spécification requise"},
    {label:"Pourcentage/nombre de réutilisation"},
    {label:"Tonnes de déchêts"},
    {label:"Nombre d’approvisionnement de matières premières"},
    {label:"Nombre d’approvisionnement en eau"},
    {label:"Chaine de valeur alimentaire et de nutrition"},
    {label:"Certificat médical"},
    {label:"Tonnes de plastiques collectés"},
    {label:"Nombre d’apprenants, niveau d’apprentissage"},
    {label:"Nombre de repas, nourritures"}
]